import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  GET_COMPETENCES_BY_USER_ID,
  GET_RECOGNITIONS_RECEIVED_BY_USER_ID,
} from "data/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import {
  Grid,
  LinearProgress,
  Card,
  Button,
  Dialog,
  List,
  ListItem,
  Tooltip,
  Chip,
  useMediaQuery,
} from "@material-ui/core";

export default function SentRecognitions() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const {
    loading: loadingCompetences,
    error: errorCompetences,
    data: dataCompetences,
    networkStatus: networkStatusCompetences,
    refetch: refetchCompetences,
  } = useQuery(GET_COMPETENCES_BY_USER_ID, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: loadingRecognitions,
    error: errorRecognitions,
    data: dataRecognitions,
    networkStatus: networkStatusRecognitions,
    refetch: refetchRecognitions,
  } = useQuery(GET_RECOGNITIONS_RECEIVED_BY_USER_ID, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  console.log(dataRecognitions);

  const variables = [
    {
      variable: "Todas las competencias",
      color: "grey",
    },
    {
      variable: "Emocionales",
      color: "#FF3146",
    },
    {
      variable: "Transversales",
      color: "#00029E",
    },
    {
      variable: "Sociales",
      color: "#E6D600",
    },
    {
      variable: "Digitales",
      color: "#00ACDF",
    },
  ];

  const [variablesList, setVariablesList] = useState([]);
  const [variablesListFilter, setVariablesListFilter] = useState([]);

  useEffect(() => {
    if (dataCompetences != null) {
      setVariablesList([...dataCompetences.getCompetencesByUserId]);
      setVariablesListFilter([...dataCompetences.getCompetencesByUserId]);
    }
  }, [dataCompetences]);

  const handleClickFilter = (element) => () => {
    if (element.color != "grey") {
      const filteredVariables = variablesList.filter((o) =>
        Object.keys(o).some(
          (k) =>
            k === "color" &&
            String(o[k]).toLowerCase().includes(element.color.toLowerCase())
        )
      );
      setVariablesListFilter(filteredVariables);
    } else {
      setVariablesListFilter(variablesList);
    }
  };

  if (dataCompetences) {
    return (
      <>
        <Grid item xl={12}>
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            Filtrar por competencias:
          </h2>
          <div
            className="mb-spacing-6 text-center"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
            }}
          >
            {variables.map((element, index) => {
              return (
                <Chip
                  variant="outlined"
                  style={{
                    backgroundColor: element.color,
                    color: "white",
                    borderColor: element.color,
                    width: isMobile ? "250px" : "",
                    fontSize: 18,
                  }}
                  label={element.variable}
                  key={element.color}
                  onClick={handleClickFilter(element)}
                />
              );
            })}
          </div>

          <div className="mb-spacing-12">
            <Grid container spacing={4}>
              {variablesListFilter.map((element, index) => {
                return (
                  <Grid item xl={2} xs={6}>
                    <Card
                      className="shadow-xxl"
                      style={{
                        borderRadius: "12px",
                        border: `solid 4px ${element.color}`,
                      }}
                    >
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="card-img-wrapper rounded"
                      >
                        <div className="img-wrapper-overlay">
                          <div className="overlay-btn-wrapper">
                            <div className="font-size-lg font-weight-bold mb-2">
                              {element.name}
                            </div>

                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-secondary",
                              }}
                              arrow
                              placement="top"
                              title={<h6>{element.description}</h6>}
                            >
                              <Button
                                size="small"
                                className="btn-info btn-pill px-4 hover-scale-sm"
                              >
                                <span className="btn-wrapper--icon">
                                  <FontAwesomeIcon icon={["fas", "info"]} />
                                </span>
                                <span className="btn-wrapper--label">
                                  Información
                                </span>
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                        <img
                          src={element.urlImage}
                          className="card-img-top rounded"
                          alt="..."
                        />
                      </a>
                    </Card>
                    <h5
                      className="text-center font-weight-bold mt-4"
                      style={{ fontFamily: "Inter", color: "#291c33" }}
                    >
                      {element.name}
                    </h5>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
