import React, { useState, useCallback, useEffect, useContext } from "react";

import {
  Grid,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { UserContext } from "../../../context/UserContext";
import { GET_GRATITUDES_SENDED_BY_USER_ID_2 } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
var moment = require("moment-timezone");
export default function SentGratitudes() {
  const [dataSelected, setDataSelected] = useState(false);
  const [modalViewEmployee, setModalViewEmployee] = useState(false);
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const limit = 10; // Cantidad de usuarios por página
  const [offset, setOffset] = React.useState(0); // Offset para la paginación
  const { loading, error, data, fetchMore } = useQuery(
    GET_GRATITUDES_SENDED_BY_USER_ID_2,
    {
      variables: {
        id: dbuser.getUserByUid.id,
        limit: 10, // Especifica el límite de usuarios por página
        offset: offset, // Especifica el desplazamiento inicial
      },
    }
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const handlePageChange = (_, newPage) => {
    // Calcular el nuevo offset al cambiar de página
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    console.log(newOffset);
  };
  if (data) {
    console.log(data);
    return (
      <>
        <Dialog
          open={modalViewEmployee}
          onClose={toggleViewEmployee}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <Grid item>
            <Card className="card-box p-4">
              {dataSelected.receiver && (
                <>
                  <div className="d-flex align-items-center mb-3 mt-1">
                    <div className="avatar-icon-wrapper rounded-circle mr-3">
                      <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                        <div className="rounded-circle overflow-hidden">
                          <img
                            alt="..."
                            className="img-fluid"
                            src={
                              dataSelected && dataSelected.receiver.urlImage
                                ? dataSelected.receiver.urlImage
                                : require("../../../assets/images/climind/default_avatar.png")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold"
                        title="..."
                        style={{
                          fontFamily: "Inter",
                          color: "#291c33",
                          fontSize: isMobile ? "14px" : "",
                        }}
                      >
                        {dataSelected ? dataSelected.receiver.name : null}&nbsp;
                        {dataSelected ? dataSelected.receiver.surname : null}
                        {/* {dataSelected.getRecognitionsSendedByUserIdLast30.receiver.name} */}
                      </a>
                      <span className=" d-block">
                        {dataSelected ? dataSelected.receiver.email : null}
                      </span>
                    </div>
                  </div>
                  <h5 className="text-center mt-5 mb-3">
                    Has enviado el siguiente comentario
                  </h5>
                  <h4 className="mb-20 text-center">
                    {dataSelected && dataSelected.commentary
                      ? dataSelected.commentary
                      : null}
                  </h4>

                  <Button
                    fullWidth
                    className="btn-neutral-success text-uppercase"
                    onClick={() => {
                      toggleViewEmployee();
                    }}
                  >
                    <span className="font-weight-bold">Ok</span>
                  </Button>
                </>
              )}
              {/* {dataSelected.receivers && dataSelected.receivers.length > 0 && <>
                <div className="d-flex align-items-center mb-3 mt-1"> 
                </div>
                <h5 className="text-center mt-5 mb-3">
                  Has enviado el siguiente comentario
                </h5>
                <h4 className="mb-20 text-center">
                    {dataSelected && dataSelected.commentary ? dataSelected.commentary : null}
                </h4>
                <h5 className="text-center mt-5 mb-3">
                  Personas que lo han recibido:
                </h5>
                {dataSelected.receivers.map((element)=>{
                  return(
                  <div className="d-flex align-items-center">
                  <div className="avatar-icon-wrapper mr-2">
                    <div className="avatar-icon">
                      <img alt="..." src={element.urlImage ? element.urlImage : require("../../../assets/images/climind/default_avatar.png")} />
                    </div>
                  </div>
                  <div>
                    <a
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="font-weight-bold text-black"
                      title="...">
                      {element.name ? element.name : "sin nombre"} {element.surname ? element.surname : "sin apellido"} 
                    </a>
                    <span className="text-black-50 d-block">
                    {element.email ? element.email : "sin email"}
                    </span>
                  </div>
                </div>
                  )
                })}
                <Button fullWidth className="btn-neutral-success text-uppercase" onClick={()=>{
                  toggleViewEmployee()
                }}>
                  <span className="font-weight-bold">Ok</span>
                </Button>
                </>
              }
              {((dataSelected.datagroups && dataSelected.datagroups.length > 0) || (dataSelected.datadepartments && dataSelected.datadepartments.length > 0)) && <>
                <div className="d-flex align-items-center mb-3 mt-1"> 
                </div>
                <p className="font-weight-bold font-size-md mb-0 text-center mt-5 mb-3">
                  Has enviado el siguiente comentario
                </p>
                <p className="font-size-lg mb-20 text-center">
                    {dataSelected && dataSelected.commentary ? dataSelected.commentary : null}
                </p>
                {dataSelected.datagroups.length > 0 && <p className="font-weight-bold font-size-md mb-0 text-center mt-5 mb-3">
                  Grupos que lo han recibido:
                </p>}
                {dataSelected.datagroups.length > 0 && dataSelected.datagroups.map((element)=>{
                  return(
                  <div className="d-flex align-items-center justify-content-center">
                 

                    <p>
                      {element.name ? element.name : "sin nombre"}
                    </p>

                </div>
                  )
                })}
                 {dataSelected.datadepartments.length > 0 && <p className="font-weight-bold font-size-md mb-0 text-center mt-5 mb-3">
                  Departamentos que lo han recibido:
                </p>}
                {dataSelected.datadepartments.length > 0 && dataSelected.datadepartments.map((element)=>{
                  return(
                    <div className="d-flex align-items-center justify-content-center">
                 

                    <p>
                      {element.name ? element.name : "sin nombre"}
                    </p>

                </div>
                  )
                })}
                <Button fullWidth className="btn-neutral-success text-uppercase" onClick={()=>{
                  toggleViewEmployee()
                }}>
                  <span className="font-weight-bold">Ok</span>
                </Button>
                </>
              } */}
            </Card>
          </Grid>
        </Dialog>
        <Grid container spacing={6}>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header bg-light">
                <div className="card-header--title">
                  <big className="d-block text-uppercase mt-1 font-weight-bold">
                    Agradecimientos entregados
                  </big>
                </div>
              </div>
              <List component="div" className="list-group-flush">
                {data.getGratitudesSendedByUserId2.gratitudesObtained.length !=
                0 ? (
                  data.getGratitudesSendedByUserId2.gratitudesObtained.map(
                    (element, index) => {
                      console.log(element);
                      return (
                        <ListItem className="py-3">
                          {element.receiver && (
                            <Grid container spacing={0}>
                              <div className="align-box-row flex-grow-1 text-right d-block d-md-none">
                                <div className="d-flex flex-column flex-grow-1">
                                  <p
                                    className="mb-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {moment(element.date).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </div>
                              <Grid
                                item
                                xl={6}
                                md={6}
                                sm={12}
                                className="d-flex align-items-center"
                              >
                                <Button
                                  size="small"
                                  className="btn-neutral-success mr-50"
                                  onClick={() => {
                                    setDataSelected(element);
                                    toggleViewEmployee();
                                    // console.log(element.datasender.name)
                                  }}
                                >
                                  Ver
                                </Button>
                                <div className="d-flex align-items-center">
                                  <div className="avatar-icon-wrapper mr-2">
                                    <div className="avatar-icon">
                                      <img
                                        alt="..."
                                        src={
                                          element.receiver.urlImage
                                            ? element.receiver.urlImage
                                            : require("../../../assets/images/climind/default_avatar.png")
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="font-weight-bold text-black"
                                      title="..."
                                      style={{
                                        fontFamily: "Inter",
                                        color: "#291c33",
                                        fontSize: isMobile ? "14px" : "",
                                      }}
                                    >
                                      {element.receiver.name
                                        ? element.receiver.name
                                        : "sin nombre"}{" "}
                                      {element.receiver.surname
                                        ? element.receiver.surname
                                        : "sin nombre"}
                                    </a>
                                    <span className="text-black-50 d-none d-md-block">
                                      {element.receiver.email
                                        ? element.receiver.email
                                        : "sin puesto"}
                                    </span>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={6}
                                md={6}
                                sm={12}
                                className="pt-3 pt-xl-0 d-flex align-items-center"
                              >
                                <div className="align-box-row flex-grow-1 d-none d-md-block">
                                  <div className="d-flex flex-column flex-grow-1 text-center">
                                    <p className="mb-0">
                                      {moment(element.date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </ListItem>
                      );
                    }
                  )
                ) : (
                  <h5 className="my-5 ml-4">
                    Aún no has entregado agradecimientos
                  </h5>
                )}
              </List>
              <div className="divider" />
              <div className="divider" />
              <div className="p-3 d-flex justify-content-center">
                {/* <Pagination className="pagination-primary" count={10} onClick={handleLoadMore}/> */}
                <Pagination
                  count={Math.ceil(
                    data.getGratitudesSendedByUserId2.totalGratitudes / limit
                  )}
                  page={offset / limit + 1}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      component="a"
                      // href="#"
                      onClick={(e) => e.preventDefault()}
                      {...item}
                    />
                  )}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
