import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  Button,
  Tooltip,
  CardContent,
  List,
  ListItem,
  Dialog,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import GaugeChart from "react-gauge-chart";
import { UserContext } from "../../context/UserContext";
import {
  GET_ACHIEVED_AWARDS_BY_COMPANYID,
  GET_USERS_BY_COMPANY_ID,
  GET_USER_BY_UID,
  GET_USERS_WITH_CIE_BY_COMPANY_ID,
} from "data/queries";
import { CHANGE_STATE_AWARD } from "data/mutations";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
var moment = require("moment-timezone");
export default function Gamification() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [dataSelected, setDataSelected] = useState(false);
  const [modalViewPrice, setModalViewPrice] = useState(false);
  const toggleViewPrice = () => setModalViewPrice(!modalViewPrice);
  const [modalDeliver, setModalDeliver] = useState(false);
  const toggleDeliver = () => setModalDeliver(!modalDeliver);
  const [countPoints, setCountPoints] = useState(0);

  const [
    changeStateAward,
    { data: dataChange, error: errorChange, loading: loadingChange },
  ] = useMutation(CHANGE_STATE_AWARD);

  const { loading, error, data, networkStatus } = useQuery(
    GET_ACHIEVED_AWARDS_BY_COMPANYID,
    {
      variables: { id: dbuser.getUserByUid.companyId },
      skip: !dbuser.getUserByUid.companyId,
      notifyOnNetworkStatusChange: true,
    }
  );
  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
    networkStatus: networkStatusUsers,
    refetch: refetchUsers,
  } = useQuery(GET_USERS_BY_COMPANY_ID, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: loadingUsersCie,
    error: errorUsersCie,
    data: dataUsersCie,
    networkStatus: networkStatusUsersCie,
    refetch: refetchUsersCie,
  } = useQuery(GET_USERS_WITH_CIE_BY_COMPANY_ID, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  if (data && dataUsers && dataUsersCie) {
    // const dateFrom = moment().subtract(7,'d').format('YYYY-MM-DD');
    let coins = 0;
    let points = 0;
    let cie = 0;
    let cieHistory = 0;
    let activeCie = 0;
    let activeCieHistory = 0;
    let activeCoins = 0;
    let activePoints = 0;
    let activeCount = 0;
    let totalChanged = 0;
    let mediaCoins = 0;
    let mediaPoints = 0;
    let mediaCie = 0;
    let mediaCieHistory = 0;
    let mediaActiveCie = 0;
    let mediaActiveCieHistory = 0;
    let mediaActiveCoins = 0;
    let mediaActivePoints = 0;

    for (const element of dataUsers.getUsersByCompanyId) {
      points = points + element.score;
      coins = coins + element.points;
    }
    for (const element of data.getAchievedAwardsByCompanyId.achievedAwards) {
      // if(element.date > dateFrom){
      totalChanged = totalChanged + element.price;
      // }
    }
    for (const element of dataUsersCie.getCompanyUsersCie) {
      cie = cie + element.cie;
      cieHistory = cieHistory + element.cieHistory;

      if (element.username != null) {
        activePoints = activePoints + element.score;
        activeCoins = activeCoins + element.points;
        activeCie = activeCie + element.cie;
        activeCieHistory = activeCieHistory + element.cieHistory;
        activeCount = activeCount + 1;
      }
    }

    mediaCoins = Math.round(coins / dataUsers.getUsersByCompanyId.length);
    mediaPoints = Math.round(points / dataUsers.getUsersByCompanyId.length);
    mediaActiveCoins = Math.round(activeCoins / activeCount);
    mediaActivePoints = Math.round(activePoints / activeCount);
    mediaCie = Math.round(cie / dataUsersCie.getCompanyUsersCie.length);
    mediaCieHistory = Math.round(
      cieHistory / dataUsersCie.getCompanyUsersCie.length
    );
    mediaActiveCie = Math.round(activeCie / activeCount);
    mediaActiveCieHistory = Math.round(activeCieHistory / activeCount);
    return (
      <>
        <div className="mb-spacing-6">
          <Grid container spacing={6}>
            <Grid item xl={4} md={4}>
              <Card
                className="card-box shadow-first-sm p-4 card-box-border-bottom border-primary"
                style={{ height: 164 }}
              >
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Puntos totales generados</div>
                </div>

                <div
                  className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center"
                  style={{ transform: "translateY(24px)" }}
                >
                  <div>{points}</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={4} md={4}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-primary">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Puntos medios generados por usuario</div>
                </div>

                <div className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaPoints}</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={4} md={4}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-primary">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">
                    Puntos medios generados por usuario activo
                  </div>
                </div>

                <div className="display-3  line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaActivePoints}</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card
                className="card-box shadow-first-sm p-4 card-box-border-bottom border-warning"
                style={{ height: 164 }}
              >
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Coins totales generados</div>
                </div>

                <div
                  className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center"
                  style={{ transform: "translateY(24px)" }}
                >
                  <div>{coins + totalChanged}</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-warning">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Coins medios generados por usuario</div>
                </div>

                <div className="display-3  line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaCoins}</div>
                </div>
              </Card>
            </Grid>

            <Grid item xl={3} md={3} borderColor="green">
              <Card
                className="card-box shadow-first-sm p-4 card-box-border-bottom border-warning"
                style={{ borderColor: "green" }}
              >
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">
                    Coins medios generados por usuario activo
                  </div>
                </div>

                <div className="display-3  line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaActiveCoins}</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card
                className="card-box shadow-success-sm p-4 card-box-border-bottom border-warning"
                style={{ height: 164 }}
              >
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Coins totales canjeados</div>
                </div>

                <div
                  className="display-3  line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center"
                  style={{ transform: "translateY(24px)" }}
                >
                  <div>{totalChanged}</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-success">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Cie mensual medio por usuario</div>
                </div>

                <div className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaCie} %</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-success">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Cie mensual medio por usuario activo</div>
                </div>

                <div className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaActiveCie} %</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-success">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Cie histórico medio por usuario</div>
                </div>

                <div className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaCieHistory} %</div>
                </div>
              </Card>
            </Grid>
            <Grid item xl={3} md={3}>
              <Card className="card-box shadow-first-sm p-4 card-box-border-bottom border-success">
                <div className="d-flex align-items-center justify-content-center text-center">
                  <div className="">Cie histórico medio por usuario activo</div>
                </div>

                <div className="display-3 line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                  <div>{mediaActiveCieHistory} %</div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return null;
  }
}
