import React, { useState, useCallback, useContext, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
//import Slider from "@material-ui/core/Slider";
import Slider from "react-slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { FlagIcon } from "react-flag-kit";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";
import {
  POSSIBLE_ANSWER_CLIMA_TODAY,
  GET_QUESTION,
  GET_USER_BY_UID,
  GETS_FOR_GENERAL,
} from "data/queries";
import { SAVE_ANSWER } from "data/mutations";
import departamentos from "../../../assets/images/climind/departamentos4.svg";
import superiores from "../../../assets/images/climind/superiores5.svg";
import clientes from "../../../assets/images/climind/cliente2.svg";
import compañeros from "../../../assets/images/climind/equipos2.svg";
import feliz from "../../../assets/images/climind/feliz.svg";
import pregunta from "../../../assets/images/climind/faq.svg";
import Picker from "emoji-picker-react";
import InfoCargayritmo from "./DataInfo";
import MuiAlert from "@material-ui/lab/Alert";
import InputEmoji from "react-input-emoji";
import { set } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalQuestion({
  modalQuestion,
  toggleQuestion,
  refetch,
  refetchClima,
}) {
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [buttonValue, setButtonValue] = useState(null);
  const [tempValue, setTempValue] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [compañerosValue, setCompañerosValue] = useState(false);
  const [superioresValue, setSuperioresValue] = useState(false);
  const [departamentosValue, setDepartamentosValue] = useState(false);
  const [clientesValue, setclientesValue] = useState(false);
  const [anchorElMenu3, setAnchorElMenu3] = useState(null);
  const [commentary, setCommentary] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarDanger, setOpenSnackbarDanger] = useState(false);
  const [checkAdmin, setCheckAdmin] = useState(
    dbuser.getUserByUid.commentaryQuestionForAdmins
  );
  const [checkTimeline, setCheckTimeline] = useState(
    dbuser.getUserByUid.commentaryQuestionInTimeline
  );
  const [
    saveAnswer,
    { data: dataAnswer, error: errorAnswer, loading: loadingAnswer },
  ] = useMutation(SAVE_ANSWER);
  const [showEmojis, setShowEmojis] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const classes = useStyles();
  const handleChangeCheckTimeline = useCallback((e) => {
    setCheckTimeline(!checkTimeline);
  });
  const handleChangeCheckAdmin = useCallback((e) => {
    setCheckAdmin(!checkAdmin);
  });
  const handleShowEmojis = useCallback((e) => {
    setShowEmojis(!showEmojis);
  });

  const handleBack = async (e) => {
    setButtonValue(null);
    setCommentary("");
    setCompañerosValue(false);
    setSuperioresValue(false);
    setDepartamentosValue(false);
    setclientesValue(false);
    setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins);
    setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline);
    toggleQuestion();
  };

  const handleOk = async (e) => {
    if (buttonClicked) return;
    setButtonClicked(true);

    let dataInput = {
      companyId: dbuser.getUserByUid.companyId,
      userId: dbuser.getUserByUid.id,
      questionId: dataQuestion.getQuestion.id,
      answer: parseInt(buttonValue),
      commentary: commentary,
      commentaryInTimeline: checkTimeline,
      commentaryForAdmins: checkAdmin,
      badWithPartners: compañerosValue,
      badWithBosses: superioresValue,
      badWithDepartments: departamentosValue,
      badWithClients: clientesValue,
    };
    console.log(dataInput);
    if (buttonValue) {
      let uid = dbuser.getUserByUid.uid;
      let id = dbuser.getUserByUid.id;
      let response = await saveAnswer({
        variables: { input: dataInput },
        refetchQueries: [
          { query: GET_USER_BY_UID, variables: { uid, uid } },
          { query: GETS_FOR_GENERAL, variables: { id, id } },
          { query: POSSIBLE_ANSWER_CLIMA_TODAY, variables: { id, id } },
          { query: GET_QUESTION, variables: { id, id } },
        ],
      });
      getUserWithFirebase();
      console.log("mutation response:" + response);
      setButtonValue(null);
      setCommentary("");
      setCompañerosValue(false);
      setSuperioresValue(false);
      setDepartamentosValue(false);
      setclientesValue(false);
      setCheckAdmin(dbuser.getUserByUid.commentaryEmotionForAdmins);
      setCheckTimeline(dbuser.getUserByUid.commentaryEmotionInTimeline);
      if (response.data.saveAnswer === null) {
        setOpenSnackbarDanger(true, () => {
          setTimeout(function () {
            setOpenSnackbarDanger(false);
          }, 5000); //5 Second delay
        });
      } else {
        setOpenSnackbar(true, () => {
          setTimeout(function () {
            setOpenSnackbar(false);
          }, 5000); //5 Second delay
        });
      }
      refetch();
      refetchClima();
      toggleQuestion();
      setButtonClicked(false);
    } else {
      // return( message.warning('Debe de contestar a la pregunta marcando un número') );
      return null;
    }
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  const applyValue = () => {
    setButtonValue(tempValue);
  };

  useEffect(() => {
    if (isMobile) {
      setTempValue(buttonValue);
    } else {
      setButtonValue(tempValue);
    }
  }, [isMobile]);

  const {
    loading: loadingQuestion,
    error: errorQuestion,
    data: dataQuestion,
    refetch: refetchQuestion,
    networkStatus: networkStatusQuestion,
  } = useQuery(GET_QUESTION, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  if (loadingQuestion)
    return (
      <Dialog
        open={modalQuestion}
        onClose={handleBack}
        maxWidth="lg"
        width="80%"
        classes={{ paper: "modal-content rounded-lg" }}
      >
        <h3 className="m-5">Se está cargando tu pregunta...</h3>
      </Dialog>
    );
  if (dataQuestion) {
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Entregado correctamente
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,right"}
          open={openSnackbarDanger}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbarDanger(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbarDanger(false)}
            severity="warning"
            style={{ color: "white" }}
          >
            No se ha permitido contestar la pregunta
          </Alert>
        </Snackbar>
        <Dialog
          open={modalQuestion}
          onClose={handleBack}
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <PerfectScrollbar
            className=" mb-2 mt-2"
            style={{
              scrollbarColor: "#e9e4fe",
              overflow: "auto",
            }}
          >
            <div className="text-center p-5">
              <div className="avatar-icon-wrapper rounded-circle mb-4">
                {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={pregunta}
                  style={{ width: 70 }}
                />
                {/* </div> */}
              </div>
              <h5 className="text-center mb-4">
                ¿Estás de acuerdo con la siguiente afirmación?
              </h5>
              <div style={{ marginBottom: 30 }}>
                <h3 className="text-center font-weight-bold">
                  {dataQuestion.getQuestion.name}
                </h3>
              </div>
              {isMobile ? (
                <div className="d-flex flex-row mt-2 justify-content-center align-content-center">
                  <img
                    alt="..."
                    src={require("../../../assets/images/climind/thumb-down.png")}
                    width={40}
                    height={40}
                    className="mr-2"
                  />
                  <Card
                    className="p-4 text-center position-relative"
                    style={{ minWidth: 210 }}
                  >
                    <Slider
                      className="custom-slider align-items-center justify-content-center "
                      thumbClassName="custom-thumb"
                      trackClassName="custom-track"
                      min={1}
                      max={10}
                      value={tempValue}
                      onChange={(newValue) => {
                        setTempValue(newValue);
                      }}
                      renderTrack={(props) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            background: "blue",
                            height: "6px",
                            borderRadius: "3px",
                          }}
                        />
                      )}
                      renderThumb={(props) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            background: "#FFF",
                            border: "2px solid blue",
                            width: "26px",
                            height: "26px",
                            borderRadius: "50%",
                            cursor: "grab",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                            color: "blue",
                            transform: "translateY(-9px)",
                          }}
                        >
                          {tempValue}
                        </div>
                      )}
                    />
                    <div className="pt-3 d-flex justify-content-center align-items-center">
                      <Button onClick={applyValue}>Aceptar</Button>
                    </div>
                  </Card>
                  <img
                    alt="..."
                    src={require("../../../assets/images/climind/thumb-up.png")}
                    width={40}
                    height={40}
                    className="ml-2"
                  />
                </div>
              ) : (
                <div className="d-flex flex-row mt-2 justify-content-center">
                  <img
                    alt="..."
                    src={require("../../../assets/images/climind/thumb-down.png")}
                    width={40}
                    height={40}
                    className="mr-2"
                  />

                  <Button
                    className={
                      buttonValue === 1
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 1 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(1)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">1</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 2
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 2 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(2)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">2</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 3
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 3 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(3)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">3</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 4
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 4 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(4)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">4</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 5
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 5 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(5)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">5</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 6
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 6 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(6)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">6</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 7
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 7 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(7)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">7</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 8
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 8 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(8)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">8</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 9
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 9 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(9)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">9</h5>
                  </Button>
                  <Button
                    className={
                      buttonValue === 10
                        ? "btn-primary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                        : "btn-secondary btn-icon btn-animated-icon btn-transition-none btn-pill d-50 p-0 ml-2"
                    }
                    style={{
                      borderRadius: "50%",
                      border: buttonValue === 10 ? "" : "solid 3px blue",
                    }}
                    onClick={() => setButtonValue(10)}
                  >
                    <h5 className="text-center font-weight-bold mb-0">10</h5>
                  </Button>
                  <img
                    alt="..."
                    src={require("../../../assets/images/climind/thumb-up.png")}
                    width={40}
                    height={40}
                    className="ml-3"
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-column text-center px-2">
              {buttonValue && buttonValue < 6 && (
                <>
                  <h5 className="font-weight-bold" style={{ color: "#5a318e" }}>
                    ¿Dónde crees que está el problema?
                  </h5>
                  <h6>Puedes seleccionar más de una opción</h6>
                  <div className="mb-spacing-6 mt-4">
                    <Grid
                      container
                      spacing={4}
                      className=" d-flex justify-content-center align-items-start"
                    >
                      <Grid
                        item
                        xl={3}
                        md={3}
                        sm={3}
                        xs={6}
                        style={{ maxWidth: 170 }}
                        className="d-flex flex-column justify-content-center"
                      >
                        <Card
                          className="shadow-xxl"
                          width={140}
                          height={140}
                          style={
                            compañerosValue
                              ? {
                                  borderRadius: "30px",
                                  border: `solid 7px green`,
                                  height: 150,
                                  width: 150,
                                }
                              : {}
                          }
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="card-img-wrapper rounded"
                          >
                            <div className="img-wrapper-overlay p-10">
                              <div className="overlay-btn-wrapper ">
                                <Tooltip
                                  classes={{
                                    tooltip:
                                      "text-center p-3 tooltip-secondary",
                                  }}
                                  arrow
                                  placement="top"
                                  title="Cuando consideras que la causa del problema se debe a la relación/trabajo con las personas de tu departamento, con las que interactúas en tu día a día"
                                >
                                  <Button
                                    size="small"
                                    className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon icon={["fas", "info"]} />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Información
                                    </span>
                                  </Button>
                                </Tooltip>
                                {!compañerosValue ? (
                                  <Button
                                    size="small"
                                    className="btn-success btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setCompañerosValue(!compañerosValue);
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Seleccionar
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    className="btn-danger btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setCompañerosValue(!compañerosValue);
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Quitar
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </div>
                            <img
                              src={compañeros}
                              className="card-img-top rounded"
                              alt="..."
                              style={{ width: 140, height: 140 }}
                            />
                          </a>
                        </Card>
                        <h5 className="text-center font-weight-bold mt-4">
                          Entre compañeros
                        </h5>
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        md={3}
                        sm={3}
                        style={{ maxWidth: 170 }}
                        className="d-flex flex-column justify-content-center align-items-start"
                      >
                        <Card
                          className="shadow-xxl"
                          width={140}
                          height={140}
                          style={
                            superioresValue
                              ? {
                                  borderRadius: "30px",
                                  border: `solid 7px green`,
                                  height: 150,
                                  width: 150,
                                }
                              : {}
                          }
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="card-img-wrapper rounded"
                          >
                            <div className="img-wrapper-overlay">
                              <div className="overlay-btn-wrapper">
                                <Tooltip
                                  classes={{
                                    tooltip:
                                      "text-center p-3 tooltip-secondary",
                                  }}
                                  arrow
                                  placement="top"
                                  title="Cuando consideras que la causa del problema se debe a la relación/trabajo con superiores."
                                >
                                  <Button
                                    size="small"
                                    className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon icon={["fas", "info"]} />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Información
                                    </span>
                                  </Button>
                                </Tooltip>
                                {!superioresValue ? (
                                  <Button
                                    size="small"
                                    className="btn-success btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setSuperioresValue(!superioresValue);
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Seleccionar
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    className="btn-danger btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setSuperioresValue(!superioresValue);
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Quitar
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </div>
                            <img
                              src={superiores}
                              className="card-img-top rounded"
                              alt="..."
                              style={{ width: 140, height: 140 }}
                            />
                          </a>
                        </Card>
                        <h5 className="text-center font-weight-bold mt-4">
                          Con superiores
                        </h5>
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        md={3}
                        sm={3}
                        style={{ maxWidth: 170 }}
                        className="d-flex flex-column justify-content-center"
                      >
                        <Card
                          className="shadow-xxl"
                          width={140}
                          height={140}
                          style={
                            departamentosValue
                              ? {
                                  borderRadius: "30px",
                                  border: `solid 7px green`,
                                  height: 150,
                                  width: 150,
                                }
                              : {}
                          }
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="card-img-wrapper rounded"
                          >
                            <div className="img-wrapper-overlay">
                              <div className="overlay-btn-wrapper">
                                <Tooltip
                                  classes={{
                                    tooltip:
                                      "text-center p-3 tooltip-secondary",
                                  }}
                                  arrow
                                  placement="top"
                                  title="Cuando consideras que la causa del problema se debe a la interacción con otro departamento, en las relaciones con las personas o con los procesos de trabajo."
                                >
                                  <Button
                                    size="small"
                                    className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon icon={["fas", "info"]} />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Información
                                    </span>
                                  </Button>
                                </Tooltip>
                                {!departamentosValue ? (
                                  <Button
                                    size="small"
                                    className="btn-success btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setDepartamentosValue(
                                        !departamentosValue
                                      );
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Seleccionar
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    className="btn-danger btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setDepartamentosValue(
                                        !departamentosValue
                                      );
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Quitar
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </div>
                            <img
                              src={departamentos}
                              className="card-img-top rounded"
                              alt="..."
                              style={{ width: 140, height: 140 }}
                            />
                          </a>
                        </Card>
                        <h5 className="text-center font-weight-bold mt-4">
                          Entre departamentos
                        </h5>
                      </Grid>
                      <Grid
                        item
                        xl={3}
                        md={3}
                        sm={3}
                        style={{ maxWidth: 170 }}
                        className="d-flex flex-column justify-content-center"
                      >
                        <Card
                          className="shadow-xxl"
                          width={140}
                          height={140}
                          style={
                            clientesValue
                              ? {
                                  borderRadius: "30px",
                                  border: `solid 7px green`,
                                  height: 150,
                                  width: 150,
                                }
                              : {}
                          }
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="card-img-wrapper rounded"
                          >
                            <div className="img-wrapper-overlay">
                              <div className="overlay-btn-wrapper">
                                <Tooltip
                                  classes={{
                                    tooltip:
                                      "text-center p-3 tooltip-secondary",
                                  }}
                                  arrow
                                  placement="top"
                                  title="Cuando consideras que la causa del problema se debe a la relación/trabajo con clientes y proveedores."
                                >
                                  <Button
                                    size="small"
                                    className="btn-info btn-pill px-6 hover-scale-sm mb-2"
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon icon={["fas", "info"]} />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Información
                                    </span>
                                  </Button>
                                </Tooltip>
                                {!clientesValue ? (
                                  <Button
                                    size="small"
                                    className="btn-success btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setclientesValue(!clientesValue);
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Seleccionar
                                    </span>
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    className="btn-danger btn-pill px-8 hover-scale-sm"
                                    onClick={() => {
                                      setclientesValue(!clientesValue);
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={["fas", "check"]}
                                      />
                                    </span>
                                    <span className="btn-wrapper--label">
                                      Quitar
                                    </span>
                                  </Button>
                                )}
                              </div>
                            </div>
                            <img
                              src={clientes}
                              className="card-img-top rounded"
                              alt="..."
                              style={{ width: 140, height: 140 }}
                            />
                          </a>
                        </Card>
                        <h5 className="text-center font-weight-bold mt-4">
                          Con clientes y proveedores
                        </h5>
                      </Grid>
                    </Grid>
                  </div>
                  <h5
                    className="font-weight-bold mt-2"
                    style={{ color: "#5a318e" }}
                  >
                    Puedes escribir un comentario
                  </h5>
                  <h6>Opcional</h6>
                  <div className="d-flex justify-content-center">
                    <div style={{ width: "90%" }}>
                      <InputEmoji
                        value={commentary}
                        onChange={setCommentary}
                        cleanOnEnter
                        borderColor="purple"
                        placeholder="Escribe aquí"
                        keepOpenend
                      />
                    </div>
                  </div>
                  {/* <TextField
            variant="outlined"
            className="m-3"
            fullWidth
            id="standard-multiline-static"
            label={!commentary ? "Escribe aquí" : ""}
            multiline
            value={commentary}
            rows="4"
            defaultValue=""
            style={{ width: "80%" }}
            onChange={(e) => setCommentary(e.target.value)}
          />
          <div>
            <Button onClick={handleShowEmojis} size="small" className="btn-success m-1">
              {showEmojis ? "ocultar emoticonos" : "mostrar emoticonos"}
            </Button>
            </div> */}
                  {/* <a
                href=""
                onClick={handleShowEmojis}
                className="font-weight-bold"
                title="..."
              >
                {showEmojis ? "ocultar emoticonos" : "mostrar emoticonos"}
              </a> */}

                  {/* <div className="d-flex justify-content-center">
          {showEmojis && <Picker disableSearchBar pickerStyle={{ width: '80%', height: 250, marginBottom:20, marginTop:20}} onEmojiClick={onEmojiClick} />}
          </div> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="text-left"
                  >
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ width: "80%" }}
                    >
                      <div className="m-2">
                        <Switch
                          onChange={handleChangeCheckTimeline}
                          checked={checkTimeline}
                          color="primary"
                          name="checkedA"
                          className="switch-small"
                        />
                      </div>
                      <h6 className="mb-0">
                        El comentario podrá ser visto siempre por los
                        administradores. Si se selecciona el check, el
                        comentario también será público y se mostrará en el
                        Timeline
                      </h6>
                    </div>
                    <h6
                      className="text-black-50 mb-0 mt-4"
                      style={{ paddingLeft: 20 }}
                    >
                      * por defecto se usa la configuración de privacidad de
                      cada usuario, puedes consultarla en tu perfil
                    </h6>
                  </div>
                </>
              )}
              <div className="pt-4" style={{ marginBottom: 20 }}>
                <Button
                  onClick={handleBack}
                  className="btn-neutral-dark btn-pill mx-1"
                >
                  <span className="btn-wrapper--label">Cancelar</span>
                </Button>
                <Button
                  onClick={handleOk}
                  className="btn-success btn-pill mx-1"
                  disabled={
                    !buttonValue ||
                    (buttonValue < 6 &&
                      compañerosValue === false &&
                      superioresValue === false &&
                      clientesValue === false &&
                      departamentosValue === false)
                  }
                >
                  <span className="btn-wrapper--label">Enviar</span>
                </Button>
              </div>
            </div>
          </PerfectScrollbar>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}

{
  /*const marksRed = [
  {
    value: 1,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Baja</strong>
      </h5>
    ),
  },
  {
    value: 10,
    label: (
      <h5 className="pt-2" color="red">
        <strong>Alta</strong>
      </h5>
    ),
  },
];

const PrettoSliderRed = withStyles({
  root: {
    color: "red",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);*/
}

const useStyles = makeStyles((theme) => ({
  divCircularProgress: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circularProgress: {
    color: "#5a318e",
  },
}));
