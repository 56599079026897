import React, { useEffect, useState, useContext } from "react";
import HeatMap from "@uiw/react-heat-map";
import { Grid, Card, LinearProgress, Tooltip } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";

import CountUp from "react-countup";
import {
  GET_ANSWER_EMOTIONS_SENDED_BY_USER_ID_LAST_90,
  GET_ACHIEVED_AWARDS_BY_USERID,
  GET_CHALLENGES_BY_COMPANYID,
  GETS_FOR_GENERAL_TOTAL,
} from "data/queries";

import regalo from "../../../assets/images/climind/regalo.png";
import felicitacion from "../../../assets/images/climind/thumbs-up.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import grafico from "../../../assets/images/climind/planta-de-cultivo.svg";
import pregunta from "../../../assets/images/climind/faq.svg";
import feliz from "../../../assets/images/climind/feliz.svg";
import emotion from "../../../assets/images/climind/reaction.png";
import premios from "../../../assets/images/climind/caja-de-regalo.png";
import target from "../../../assets/images/climind/target.png";
var moment = require("moment-timezone");
export default function Compensations({ data }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const {
    loading: loadingGeneral,
    error: errorGeneral,
    data: dataGeneral,
    networkStatus: networkStatusGeneral,
  } = useQuery(GET_ANSWER_EMOTIONS_SENDED_BY_USER_ID_LAST_90, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingAwards,
    error: errorAwards,
    data: dataAwards,
    networkStatus: networkStatusAwards,
    refetch: refetchAwards,
  } = useQuery(GET_ACHIEVED_AWARDS_BY_USERID, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingChallenges,
    error: errorChallenges,
    data: dataChallenges,
    networkStatus: networkStatusChallenges,
    refetch: refetchChallenges,
  } = useQuery(GET_CHALLENGES_BY_COMPANYID, {
    variables: { companyId: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: loadingTotal,
    error: errorTotal,
    data: dataTotal,
    networkStatus: networkStatusTotal,
  } = useQuery(GETS_FOR_GENERAL_TOTAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  // let arrayPositiveRatio = [];
  // let arrayNegativeRatio = [];
  // let arrayNeutroRatio = [];
  // arrayNegativeRatio = arrayNegativeRatio.concat(data.getPulsoMetrics.dataNegative)
  //       arrayPositiveRatio = arrayPositiveRatio.concat(data.getPulsoMetrics.dataPositive)
  //       arrayNeutroRatio = arrayNeutroRatio.concat(data.getPulsoMetrics.dataNeutro)
  if (data && dataGeneral && dataAwards && dataChallenges && dataTotal) {
    let sum = 0;
    dataAwards.getAchievedAwardsById.forEach((element) => {
      sum = sum + element.price;
    });

    let sumChallenges = 0;
    dataChallenges.getChallengesByCompanyId.forEach((element) => {
      if (element.awardedUsers.includes(dbuser.getUserByUid.id)) {
        sumChallenges = sumChallenges + element.reward;
      }
    });

    let d = new Date();

    let dataHeat = [];
    dataGeneral.getAnswerEmotionsSendedByUserIdLast90.forEach((element) => {
      dataHeat.push({
        value: element.value,
        date: moment(element.date).format("YYYY/MM/DD"),
        count: element.value < 0 ? 30 : element.value === 0 ? 20 : 10,
      });
    });
    console.log(dataHeat);
    console.log(dataGeneral.getAnswerEmotionsSendedByUserIdLast90);
    let totalNegativeDays = 0;
    let totalPositiveDays = 0;
    let totalNeutroDays = 0;
    dataGeneral.getAnswerEmotionsSendedByUserIdLast90.forEach((element) => {
      if (element.value < 0) {
        totalNegativeDays = totalNegativeDays + 1;
      }
      if (element.value === 0) {
        totalNeutroDays = totalNeutroDays + 1;
      }
      if (element.value > 0) {
        totalPositiveDays = totalPositiveDays + 1;
      }
    });
    let representivityPositive =
      totalPositiveDays /
      dataGeneral.getAnswerEmotionsSendedByUserIdLast90.length;
    let representivityNegative =
      totalNegativeDays /
      dataGeneral.getAnswerEmotionsSendedByUserIdLast90.length;
    let representivityNeutro =
      totalNeutroDays /
      dataGeneral.getAnswerEmotionsSendedByUserIdLast90.length;

    return (
      <>
        <h2 className="mb-3 mt-0 text-center font-weight-bold">
          SALARIO EMOCIONAL
        </h2>
        <h3 className="mb-3 mt-0 text-center">MAPA DE CALOR</h3>
        <div className="mb-spacing-6">
          <Grid container spacing={4}>
            <Grid item xl={12} className="">
              <Card className="card-box mb-spacing-6-x2">
                <div className="card-header">
                  <div className="card-header--title">
                    <small className="d-block text-uppercase mt-1">Pulso</small>
                    <b>Evolución de tu pulso en los últimos 90 días</b>
                  </div>
                  <div className="card-tr-actions">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Esta tabla resume todos los días del año colocando el color del pulso predominante del día"
                    >
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="text-first font-weight-bold"
                      >
                        <div
                          className=" avatar-icon rounded-circle mr-2"
                          style={{ width: 26, height: 26 }}
                        >
                          <img
                            alt="..."
                            src={require("../../../assets/images/climind/info.png")}
                          />
                        </div>
                      </a>
                    </Tooltip>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-2">
                  <h2 className=" font-weight-bold mb-4">
                    <span className="pl-1">
                      <CountUp
                        start={0}
                        end={
                          dataGeneral.getAnswerEmotionsSendedByUserIdLast90
                            .length
                        }
                        duration={4}
                        separator=""
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix=""
                      />{" "}
                      Pulsos medidos
                    </span>
                  </h2>
                  <Grid container spacing={6}>
                    <Grid item sm={4}>
                      <div>
                        <span className="font-size-xl font-weight-bold">
                          {isFinite((representivityPositive * 100).toFixed(2))
                            ? (representivityPositive * 100).toFixed(2) + "%"
                            : 0 + "%"}
                        </span>
                      </div>
                      <LinearProgress
                        variant="determinate"
                        value={
                          isFinite((representivityPositive * 100).toFixed(2))
                            ? (representivityPositive * 100).toFixed(2)
                            : 0
                        }
                        className="progress-animated-alt progress-bar-rounded progress-sm mb-2 progress-bar-success progress-bar-climind-green"
                      />
                      <div className="text-dark">
                        Pulso positivo ({totalPositiveDays} pulsos)
                      </div>
                    </Grid>
                    <Grid item sm={4}>
                      <div>
                        <span className="font-size-xl font-weight-bold">
                          {isFinite((representivityNegative * 100).toFixed(2))
                            ? (representivityNegative * 100).toFixed(2) + "%"
                            : 0 + "%"}
                        </span>
                      </div>
                      <LinearProgress
                        variant="determinate"
                        value={
                          isFinite((representivityNegative * 100).toFixed(2))
                            ? (representivityNegative * 100).toFixed(2)
                            : 0
                        }
                        className="progress-bar-danger progress-bar-rounded progress-sm mb-2 progress-bar-climind-red"
                      />
                      <div className="text-dark">
                        Pulso negativo ({totalNegativeDays} pulsos)
                      </div>
                    </Grid>
                    <Grid item sm={4}>
                      <div>
                        <span className="font-size-xl font-weight-bold">
                          {isFinite((representivityNeutro * 100).toFixed(2))
                            ? (representivityNeutro * 100).toFixed(2) + "%"
                            : 0 + "%"}
                        </span>
                      </div>
                      <LinearProgress
                        variant="determinate"
                        value={
                          isFinite((representivityNeutro * 100).toFixed(2))
                            ? (representivityNeutro * 100).toFixed(2)
                            : 0
                        }
                        className="progress-bar-warning progress-bar-rounded progress-sm mb-2 progress-bar-climind-yellow"
                      />
                      <div className="text-dark">
                        Pulso neutro ({totalNeutroDays} pulsos)
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div style={{ height: "380px" }}>
                  <HeatMap
                    value={dataHeat}
                    width="100%"
                    height="380px"
                    legendCellSize={0}
                    startDate={new Date(d.setMonth(d.getMonth() - 3))}
                    endDate={new Date(d.setMonth(d.getMonth() + 8) + 3)}
                    rectSize={40}
                    weekLables={["Do", "Lu", "Mar", "Mi", "Ju", "Vi", "Sá"]}
                    monthLables={[
                      "Enero",
                      "Febrero",
                      "Marzo",
                      "Abril",
                      "Mayo",
                      "Junio",
                      "Julio",
                      "Agosto",
                      "Septiembre",
                      "Octubre",
                      "Noviembre",
                      "Diciembre",
                    ]}
                    legendRender={(props) => (
                      <rect {...props} y={props.y + 10} rx={5} />
                    )}
                    rectProps={{
                      rx: 10,
                    }}
                    rectRender={(props, data) => {
                      // if (!data.count) return <rect {...props} />;
                      if (data.date)
                        return (
                          <Tooltip
                            classes={{
                              tooltip: "text-center p-3 tooltip-secondary",
                            }}
                            arrow
                            placement="top"
                            title={`Fecha:${data.date}`}
                            key={data.date}
                          >
                            <rect {...props} />
                          </Tooltip>
                        );
                    }}
                    panelColors={{
                      0: "#ebedf0",
                      5: "#1bc943",
                      10: "#1bc943",
                      13: "#1bc943",
                      15: "#efcc1f",
                      20: "#efcc1f",
                      23: "#efcc1f",
                      25: "#f83245",
                      30: "#f83245",
                      33: "#f83245",
                    }}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <h3 className="mb-3 mt-0 text-center">FEEDBACK TOTAL OBTENIDO</h3>
        <div className="mb-spacing-6">
          <Grid container spacing={4}>
            <Grid item md={3} xl={3}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has recibido un reconocimiento"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={felicitacion}
                        // style={{ width: 70 }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={
                            dataTotal.getRecognitionsReceivedByUserIdTotal
                              .length
                          }
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>

            <Grid item md={3} xl={3}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center ">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has recibido una felicitación"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={regalo}
                        // style={{ width: 70 }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={
                            dataTotal.getCongratulationsReceivedByUserIdTotal
                              .length
                          }
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={3} xl={3}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center ">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has recibido un agradecimiento"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={agradecimiento}
                        // style={{ width: 70 }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={
                            dataTotal.getGratitudesReceivedByUserIdTotal.length
                          }
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={3} xl={3}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center ">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de veces que has recibido una propuesta de mejora"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={grafico}
                        // style={{ width: 70 }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={
                            dataTotal
                              .getImprovementProposalsReceivedByUserIdTotal
                              .length
                          }
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <div className="mt-10" style={{ marginTop: 80 }}>
          <h2 className="mb-3 text-center font-weight-bold">
            BENEFICIOS OBTENIDOS
          </h2>
        </div>
        <div className="mb-spacing-6">
          <Grid container spacing={4}>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center ">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de coins canjeadas para compensaciones en el centro de bienestar"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={premios}
                        // style={{ width: 70 }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={sum}
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                      <div className="font-size-lg opacity-8">
                        Coins canjeados
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item md={6} xl={6}>
              <Card className="card-box">
                <div className="card-content-overlay text-center py-4">
                  <div className="d-70 rounded-circle  text-white btn-icon mx-auto text-center ">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="top"
                      title="Número de puntos/coins obtenidos por participar en retos"
                    >
                      <img
                        alt="..."
                        className="card-img-top rounded-sm mb-2"
                        src={target}
                        // style={{ width: 70 }}
                      />
                    </Tooltip>
                  </div>
                  <div className="d-flex flex-row justify-content-around">
                    <div>
                      <div className="font-weight-bold text-black display-3 mt-4 mb-1">
                        <CountUp
                          start={0}
                          end={sumChallenges}
                          duration={2}
                          delay={2}
                          separator=""
                        />
                      </div>
                      <div className="font-size-lg">
                        Coins obtenidos en retos
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return null;
  }
}
