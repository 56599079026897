import React, { useState, useCallback, useContext } from "react";
import ModalSelectUser from "./ModalSelectUser";
import ModalSelectUsers from "./ModalSelectUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Chip,
  Collapse,
  useMediaQuery,
} from "@material-ui/core";
import InputEmoji from "react-input-emoji";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";
import {
  GET_USER_BY_UID,
  GETS_FOR_GENERAL,
  GET_GROUPS,
  GET_DEPARTMENTS,
} from "data/queries";
import { NEW_CONGRATULATION } from "data/mutations";

import regalo from "../../../assets/images/climind/regalo.png";

import InfoCargayritmo from "./DataInfo";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalCongratulation({
  modalCongratulation,
  toggleCongratulation,
  refetch,
}) {
  const { currentUser, dbuser, getUserWithFirebase } = useContext(UserContext);
  const [userSelected, setUserSelected] = useState(null);
  const [usersSelected, setUsersSelected] = useState([]);
  const toggleUser = () => setModalUser(!modalUser);
  const [modalUser, setModalUser] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const toggleUsers = () => setModalUsers(!modalUsers);
  const [modalUsers, setModalUsers] = useState(false);
  const [commentary, setCommentary] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [checkUser, setCheckUser] = useState(true);
  const [checkUsers, setCheckUsers] = useState(false);
  const [checkGroups, setCheckGroups] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [actionChecked, setActionChecked] = useState("USER");

  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    loading: loadingGroups,
    error: errorGroups,
    data: dataGroups,
    networkStatus: netGroups,
  } = useQuery(GET_GROUPS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: loadingDepartments,
    error: errorDepartments,
    data: dataDepartments,
    networkStatus: netDepartments,
  } = useQuery(GET_DEPARTMENTS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });
  const [
    saveCongratulation,
    { data: dataAnswer, error: errorAnswer, loading: loadingAnswer },
  ] = useMutation(NEW_CONGRATULATION);

  const handleBack = async (e) => {
    setCommentary("");
    setDepartments([]);
    setGroups([]);
    setUserSelected(null);
    setUsersSelected([]);
    setCheckUser(true);
    setCheckUsers(false);
    setCheckGroups(false);
    setActionChecked("USER");
    toggleCongratulation();
  };

  const handleOk = async (e) => {
    if (buttonClicked) return;
    setButtonClicked(true);
    console.log("handleOk");
    let usersArray = [];
    usersSelected.forEach((element) => {
      usersArray.push(element.id);
    });

    let dataInput = {
      companyId: dbuser.getUserByUid.companyId,
      commentary: commentary,
      sender: dbuser.getUserByUid.id,
      receiver: checkUser ? userSelected.id : null,
      receivers: checkUsers ? usersArray : null,
      groups: checkGroups ? groups : null,
      departments: checkGroups ? departments : null,
    };

    let uid = dbuser.getUserByUid.uid;
    let id = dbuser.getUserByUid.id;
    let response = await saveCongratulation({
      variables: { input: dataInput },
      refetchQueries: [
        { query: GET_USER_BY_UID, variables: { uid, uid } },
        { query: GETS_FOR_GENERAL, variables: { id, id } },
      ],
    });
    getUserWithFirebase();
    console.log("mutation response:" + response);
    setCommentary("");
    setDepartments([]);
    setGroups([]);
    setUserSelected(null);
    setUsersSelected([]);
    setCheckUser(true);
    setCheckUsers(false);
    setCheckGroups(false);
    setActionChecked("USER");
    setOpenSnackbar(true, () => {
      setTimeout(function () {
        setOpenSnackbar(false);
      }, 5000); //5 Second delay
    });
    refetch();
    toggleCongratulation();
    setButtonClicked(false);
  };
  if (dataDepartments && dataGroups) {
    let optionsDepartment = dataDepartments.getDepartmentsByCompanyId;
    let optionsGroup = dataGroups.getGroupsByCompanyId;
    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          key={"top,center"}
          open={openSnackbar}
          // classes={{ root: "toastr-primary" }}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          message={"entregado correctamente"}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            style={{ color: "white" }}
          >
            Entregado correctamente
          </Alert>
        </Snackbar>
        <Dialog
          open={modalCongratulation}
          onClose={handleBack}
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center pt-5">
            <div className="avatar-icon-wrapper rounded-circle mb-4">
              {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130"> */}
              <img
                alt="..."
                className="card-img-top rounded-sm"
                src={regalo}
                style={{ width: 70 }}
              />
              {/* </div> */}
            </div>
            <h5 className="text-center">Envía una Felicitación</h5>
          </div>
          <div className="p-4 text-center">
            <h5 className="font-size-xl mb-1 font-weight-bold mx-3">
              Paso 1. Selecciona a quién vas a entregar la felicitación
            </h5>
            <p className="mb-4">Obligatorio</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="d-flex flex-row flex-wrap justify-content-start mb-spacing-6-x2"
                style={{ transform: isMobile ? "translateX(40px)" : "" }}
              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCheckUser(!checkUser);
                    if (checkUser === false) {
                      setCheckUsers(false);
                      setCheckGroups(false);
                      setActionChecked("USER");
                    }
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={checkUser} className="mr-2" />}
                  label="Un usuario"
                />

                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCheckUsers(!checkUsers);
                    if (checkUsers === false) {
                      setCheckUser(false);
                      setCheckGroups(false);
                      setActionChecked("USERS");
                    }
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={checkUsers} className="mr-2" />}
                  label="Varios usuarios"
                />

                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCheckGroups(!checkGroups);
                    if (checkGroups === false) {
                      setCheckUser(false);
                      setCheckUsers(false);
                      setActionChecked("GROUPS");
                    }
                  }}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={checkGroups} className="mr-2" />}
                  label="Grupos y departamentos"
                />
              </div>
            </div>
            <Grid container spacing={12}>
              <Grid item md={12}>
                {checkUser && (
                  <Button
                    className="btn-success text-uppercase font-weight-bold font-size-xs mb-2"
                    style={
                      userSelected
                        ? { backgroundColor: "orange", color: "black" }
                        : { backgroundColor: "#5a318e", color: "white" }
                    }
                    onClick={toggleUser}
                  >
                    {userSelected ? "Cambiar" : "Seleccionar usuario"}
                  </Button>
                )}
                {checkUsers && (
                  <Button
                    className="btn-success text-uppercase font-weight-bold font-size-xs mb-2"
                    style={
                      usersSelected.length > 0 && usersSelected
                        ? { backgroundColor: "orange" }
                        : { backgroundColor: "#5a318e" }
                    }
                    onClick={toggleUsers}
                  >
                    {usersSelected.length > 0 && usersSelected
                      ? "Cambiar"
                      : "Seleccionar usuarios"}
                  </Button>
                )}
                {usersSelected &&
                  checkUsers &&
                  usersSelected.map((element) => {
                    console.log(element);
                    return (
                      <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                        <div className="avatar-icon-wrapper mr-3">
                          <div className="avatar-icon">
                            {element.urlImage ? (
                              <img alt="..." src={element.urlImage} />
                            ) : (
                              <img alt="..." src={defaultAvatar} />
                            )}
                          </div>
                        </div>
                        <div>
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="font-weight-bold text-black"
                            title="..."
                          >
                            {element.name} {element.surname}
                          </a>
                          <span className=" d-block">
                            {element.email ? `${element.email}` : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                {checkGroups && (
                  <>
                    <div
                      className="d-flex flex-row text-center justify-content-center mb-spacing-6-x2"
                      style={{ width: "100%" }}
                    >
                      <FormControl
                        variant="outlined"
                        className="w-100"
                        style={{ maxWidth: "60%" }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Departamentos
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox-departments"
                          multiple
                          value={departments}
                          disabled={
                            optionsDepartment.length === 0 ||
                            optionsDepartment.length === null ||
                            checkUser ||
                            checkUsers
                          }
                          onChange={(e) => setDepartments(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "Wrap" }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={
                                    optionsDepartment.find(
                                      (el) => el.id === value
                                    ).name
                                  }
                                  style={{ margin: 2 }}
                                  color="primary"
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {optionsDepartment.map((department) => (
                            <MenuItem key={department.id} value={department.id}>
                              {department.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div
                      className="d-flex flex-row text-center justify-content-center mb-spacing-6-x2"
                      style={{ width: "100%" }}
                    >
                      <FormControl
                        variant="outlined"
                        className="w-100"
                        style={{ maxWidth: "60%" }}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Grupos
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox-departments"
                          multiple
                          value={groups}
                          disabled={
                            optionsGroup.length === 0 ||
                            optionsGroup.length === null ||
                            checkUser ||
                            checkUsers
                          }
                          onChange={(e) => setGroups(e.target.value)}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div style={{ display: "flex", flexWrap: "Wrap" }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={
                                    optionsGroup.find((el) => el.id === value)
                                      .name
                                  }
                                  style={{ margin: 2 }}
                                  color="primary"
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {optionsGroup.map((group) => (
                            <MenuItem key={group.id} value={group.id}>
                              {group.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                )}
                {userSelected && checkUser ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center mb-2 mt-3">
                      <div className="avatar-icon-wrapper mr-3">
                        <div className="avatar-icon">
                          {userSelected.urlImage ? (
                            <img alt="..." src={userSelected.urlImage} />
                          ) : (
                            <img alt="..." src={defaultAvatar} />
                          )}
                        </div>
                      </div>
                      <div>
                        <a
                          href="#/"
                          onClick={(e) => e.preventDefault()}
                          className="font-weight-bold text-black"
                          title="..."
                        >
                          {userSelected.name} {userSelected.surname}
                        </a>
                        <span className=" d-block">
                          {userSelected.email ? `${userSelected.email}` : ""}
                        </span>
                      </div>
                    </div>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </div>
          <div className="text-center px-2">
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              Paso 2. Puedes escribir un comentario
            </h5>
            <h6>Obligatorio (min 10 caracteres)</h6>
            <div className="d-flex justify-content-center">
              <div style={{ width: "90%" }}>
                <InputEmoji
                  value={commentary}
                  onChange={setCommentary}
                  cleanOnEnter
                  borderColor="purple"
                  placeholder="Escribe aquí"
                  keepOpenend
                />
              </div>
            </div>

            <h6 className="text-black-50 mb-0 mt-4">
              * por defecto el comentario será enviado directamente al Timeline
              de tu empresa
            </h6>

            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={handleOk}
                className="btn-success btn-pill mx-1"
                // disabled={((!checkGroups && (groups.length<=0 || departments.length<=0) || (!checkUser && !userSelected) || (!checkUsers && usersSelected.length<=0)))}
                disabled={
                  ((!checkUser || !userSelected) &&
                    (!checkUsers || usersSelected.length <= 0) &&
                    (!checkGroups ||
                      (groups.length <= 0 && departments.length <= 0))) ||
                  !commentary ||
                  commentary.length < 10
                }

                // disabled={!userSelected || !commentary || commentary.length < 10}
              >
                <span className="btn-wrapper--label">Enviar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <ModalSelectUser
          modalUser={modalUser}
          toggleUser={toggleUser}
          userSelected={userSelected}
          setUserSelected={setUserSelected}
        />
        <ModalSelectUsers
          modalUsers={modalUsers}
          toggleUsers={toggleUsers}
          usersSelected={usersSelected}
          setUsersSelected={setUsersSelected}
        />
      </>
    );
  } else {
    return null;
  }
}
