import React, { useEffect, useState, useContext } from "react";
import { PageTitle } from "layout-components";
import { UserContext } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Table,
  InputLabel,
  Card,
  MenuItem,
  Button,
  Tooltip,
  FormControl,
  Select,
  Dialog,
  Menu,
  Grid,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ModalNewChallenge from "./ModalNewChallenge";
import ModalViewChallenge from "./ModalViewChallenge";
import ModalEditChallenge from "./ModalEditChallenge";
import { GET_CHALLENGES_BY_COMPANYID } from "data/queries";
import { DELETE_CHALLENGE } from "data/mutations";
import defaultAvatar from "../../assets/images/climind/default_avatar.png";
var moment = require("moment-timezone");

export default function Challenges() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [challengeSelected, setChallengeSelected] = useState(null);
  const [modalNewChallenge, setModalNewChallenge] = useState(false);
  const toggleNewChallenge = () => setModalNewChallenge(!modalNewChallenge);
  const [modalViewChallenge, setModalViewChallenge] = useState(false);
  const toggleViewChallenge = () => setModalViewChallenge(!modalViewChallenge);
  const [modalEditChallenge, setModalEditChallenge] = useState(false);
  const toggleEditChallenge = () => setModalEditChallenge(!modalEditChallenge);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [
    deleteChallenge,
    {
      data: dataDeleteChallenge,
      error: errorDeleteChallenge,
      loading: loadingDeleteChallenge,
    },
  ] = useMutation(DELETE_CHALLENGE);

  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    loading: loadingChallenges,
    error: errorChallenges,
    data: dataChallenges,
    networkStatus: networkStatusChallenges,
    refetch: refetchChallenges,
  } = useQuery(GET_CHALLENGES_BY_COMPANYID, {
    variables: { companyId: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });

  const onClickViewChallenge = (challenge) => {
    setChallengeSelected(challenge);
    toggleViewChallenge();
  };

  const onClickEditChallenge = (challenge) => {
    setChallengeSelected(challenge);
    toggleEditChallenge();
  };

  if (dataChallenges) {
    console.log(dataChallenges);
    return (
      <>
        <PageTitle
          titleHeading="Retos"
          titleDescription="Información de retos."
        />
        <Card className="card-box mb-spacing-6-x2">
          <div className="card-header py-3">
            <div className="card-header--title font-size-lg">
              Lista de retos
            </div>
          </div>
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="d-flex align-items-center px-2">
              <Button
                size="small"
                className="btn-neutral-primary"
                onClick={() => setModalNewChallenge(true)}
              >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "plus-circle"]} />
                </span>
                <span className="btn-wrapper--label">Crear nuevo reto</span>
              </Button>
            </div>
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            <Table className="table table-hover text-nowrap mb-0">
              <thead>
                <tr>
                  <th
                    className="bg-white"
                    style={{ fontFamily: "Poppins", color: "#291c33" }}
                  >
                    Nombre de reto
                  </th>
                  <th
                    className="bg-white text-center"
                    style={{ fontFamily: "Poppins", color: "#291c33" }}
                  >
                    Inscritos
                  </th>
                  <th
                    className="bg-white text-center"
                    style={{ fontFamily: "Poppins", color: "#291c33" }}
                  >
                    Fecha inicio-fin
                  </th>
                  <th
                    className="bg-white text-center"
                    style={{ fontFamily: "Poppins", color: "#291c33" }}
                  >
                    Creador
                  </th>
                  <th
                    className="bg-white text-center"
                    style={{ fontFamily: "Poppins", color: "#291c33" }}
                  >
                    Estado
                  </th>
                  <th
                    className="bg-white text-center"
                    style={{ fontFamily: "Poppins", color: "#291c33" }}
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataChallenges.getChallengesByCompanyId.length > 0 &&
                  dataChallenges.getChallengesByCompanyId.map(
                    (challenge, index) => {
                      console.log(challenge);
                      return (
                        <tr key={index}>
                          {/* Nombre y Apellido */}
                          <td>
                            <div className="d-flex">
                              <div className="d-flex align-items-center">
                                <div className="avatar-icon-wrapper mr-2">
                                  <div className="avatar-icon">
                                    <img
                                      alt="..."
                                      src={
                                        challenge.urlImage
                                          ? challenge.urlImage
                                          : require("../../assets/images/climind/target.png")
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{ fontSize: isMobile ? "16px" : "" }}
                                >
                                  {challenge.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {challenge.users.length}
                          </td>
                          <td className="text-center">
                            {moment(challenge.initDate).format("DD/MM/YYYY")} -{" "}
                            {moment(challenge.endDate).format("DD/MM/YYYY")}
                          </td>
                          <td className="text-left">
                            {
                              <div
                                className="d-flex align-items-center justify-content-start text-left"
                                style={{ transform: "translateX(20px)" }}
                              >
                                <div className="avatar-icon-wrapper mr-3">
                                  <div className="avatar-icon">
                                    {challenge.userId.urlImage ? (
                                      <img
                                        alt="..."
                                        src={challenge.userId.urlImage}
                                      />
                                    ) : (
                                      <img alt="..." src={defaultAvatar} />
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <a
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className=" text-black"
                                    title="..."
                                  >
                                    {`${challenge.userId.name}` +
                                      " " +
                                      `${challenge.userId.surname}`}
                                  </a>
                                </div>
                              </div>
                            }
                          </td>
                          <td className="text-center">
                            {moment(challenge.initDate).isSameOrBefore(
                              moment()
                            ) &&
                            moment(challenge.endDate).isSameOrAfter(moment())
                              ? "Iniciado"
                              : moment(challenge.endDate).isSameOrBefore(
                                  moment()
                                )
                              ? "Finalizado"
                              : "Sin iniciar"}
                          </td>

                          {(dbuser.getUserByUid.role === "admin" ||
                            challenge.userId._id ===
                              dbuser.getUserByUid.id) && (
                            <td className="text-center">
                              {moment().isAfter(challenge.endDate) ? (
                                <Tooltip
                                  title="Ver reto / Entregar premios"
                                  arrow
                                  placement="top"
                                >
                                  <Button
                                    size="small"
                                    className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                                    onClick={() =>
                                      onClickViewChallenge(challenge)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["fas", "trophy"]}
                                      className="font-size-lg"
                                    />
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Ver reto" arrow placement="top">
                                  <Button
                                    size="small"
                                    className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                                    onClick={() =>
                                      onClickViewChallenge(challenge)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["far", "eye"]}
                                      className="font-size-lg"
                                    />
                                  </Button>
                                </Tooltip>
                              )}
                              {moment().isBefore(challenge.initDate) ? (
                                <Tooltip
                                  title="Editar reto"
                                  arrow
                                  placement="top"
                                >
                                  <Button
                                    size="small"
                                    className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                                    onClick={() =>
                                      onClickEditChallenge(challenge)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={["far", "edit"]}
                                      className="font-size-lg"
                                    />
                                  </Button>
                                </Tooltip>
                              ) : null}

                              {/* <Tooltip title="Incribirme" arrow placement="top">
                      <Button
                        size="small"
                        className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "check-circle"]}
                          className="font-size-lg"
                        />
                      </Button>
                    </Tooltip> */}
                              {/* <Tooltip
                            title="Ver usuarios inscritos"
                            arrow
                            placement="top"
                          >
                            <Button
                              size="small"
                              className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                            >
                              <FontAwesomeIcon
                                icon={["fas", "users"]}
                                className="font-size-lg"
                              />
                            </Button>
                          </Tooltip> */}
                              {/* <Tooltip title="Abandonar el reto" arrow placement="top">
                      <Button
                        size="small"
                        className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "user-slash"]}
                          className="font-size-lg"
                        />
                      </Button>
                    </Tooltip> */}
                              {/* <Tooltip title="Resultados" arrow placement="top">
                      <Button
                        size="small"
                        className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                      >
                        <FontAwesomeIcon
                          icon={["fas", "trophy"]}
                          className="font-size-lg"
                        />
                      </Button>
                    </Tooltip> */}
                              <Tooltip
                                title="Eliminar reto"
                                arrow
                                placement="top"
                              >
                                <Button
                                  size="small"
                                  className="btn-link d-30 p-0 btn-icon hover-scale-sm"
                                  onClick={() => {
                                    setChallengeSelected(challenge);
                                    toggleDelete();
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "trash"]}
                                    className="font-size-lg"
                                  />
                                </Button>
                              </Tooltip>
                            </td>
                          )}
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
          </div>
        </Card>
        <ModalNewChallenge
          toggleNewChallenge={toggleNewChallenge}
          modalNewChallenge={modalNewChallenge}
          refetchChallenges={refetchChallenges}
        />

        {challengeSelected && (
          <ModalViewChallenge
            toggleViewChallenge={toggleViewChallenge}
            modalViewChallenge={modalViewChallenge}
            challengeSelected={challengeSelected}
            setChallengeSelected={setChallengeSelected}
            refetchChallenges={refetchChallenges}
          />
        )}
        {challengeSelected && (
          <ModalEditChallenge
            toggleEditChallenge={toggleEditChallenge}
            modalEditChallenge={modalEditChallenge}
            challengeSelected={challengeSelected}
            setChallengeSelected={setChallengeSelected}
            refetchChallenges={refetchChallenges}
          />
        )}
        <Dialog
          open={modalDelete}
          onClose={toggleDelete}
          classes={{ paper: "shadow-lg rounded" }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              ¿Estás seguro de que desas borrar este reto?
            </h4>
            <p className="mb-0 font-size-lg">
              ATENCIÓN: NO PODRÁS DESHACER ESTA OPERACIÓN y SE PERDERÁN LAS
              COMPETENCIAS ASOCIADAS AL RETO DE TODOS LOS USUARIOS.
            </p>
            <div className="pt-4">
              <Button
                onClick={toggleDelete}
                className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={() => {
                  toggleDelete();
                  deleteChallenge({
                    variables: {
                      userId: dbuser.getUserByUid.id,
                      challengeId: challengeSelected.id,
                    },
                    refetchQueries: [
                      {
                        query: GET_CHALLENGES_BY_COMPANYID,
                        variables: { companyId: dbuser.getUserByUid.companyId },
                      },
                    ],
                  });
                }}
                className="btn-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Borrar</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
