import React, { Fragment, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Tooltip, useMediaQuery } from "@material-ui/core";
import { POSSIBLE_ANSWER_CLIMA_TODAY } from "data/queries";
import { UserContext } from "../../../context/UserContext";
import { useQuery } from "@apollo/react-hooks";
import pregunta from "../../../assets/images/climind/faq.svg";
import thanks from "../../../assets/images/climind/thank-you.svg";
export default function ActionQuestion({ toggleQuestion, dataClima }) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  // const headerStyle = {
  //   paddingTop: isMobile ? 60 : 22,
  //   paddingBottom: 10,
  // };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  };
  const coinStyle = {
    width: isMobile ? 25 : 29,
    height: isMobile ? 25 : 29,
    cursor: "pointer",
  };

  const iconStyle = {
    width: isMobile ? 22 : 25,
    height: isMobile ? 22 : 25,
  };
  if (dataClima) {
    // if (true) {
    if (dataClima.possibleToAnswerClima === true) {
      return (
        <Card className="text-center" style={{ height: 175, padding: 16 }}>
          <div style={headerStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="bottom"
                title="Gana 30 puntos por contestar"
              >
                <div className=" avatar-icon rounded-circle " style={coinStyle}>
                  <img
                    alt="..."
                    src={require("../../../assets/images/climind/coin-final.png")}
                  />
                </div>
              </Tooltip>
              <p
                style={{
                  color: "#937af8",
                  fontSize: "13px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                }}
              >
                30
              </p>
            </div>

            <h3
              className="text-center"
              style={{
                transform: isMobile ? "translate(4px, 3px)" : "translateY(3px)",
              }}
            >
              Pregunta diaria de clima laboral
            </h3>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "3px" : "8px",
              }}
            >
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div>
                    <img
                      style={iconStyle}
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Contesta la pregunta de Clima de forma diaria y ayuda a tu empresa a saber como se encuentra el clima laboral y poder tomar acción"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle"
                    style={{
                      ...iconStyle,
                      transform: isMobile
                        ? "translateY(2px)"
                        : "translateY(1px)",
                    }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>

          <div
            className="py-2 d-flex flex-column align-items-center justify-content-center"
            style={{
              transform: isMobile ? "translateY(-10px)" : "translateY(-30px)",
            }}
          >
            <img
              alt="..."
              className="card-img-top rounded-sm mb-2"
              src={pregunta}
              style={
                isMobile ? { width: 40, height: 40 } : { width: 50, height: 50 }
              }
            />

            <div className="d-flex justify-content-between">
              <Button
                onClick={toggleQuestion}
                variant="text"
                style={{ background: "#291C33", borderRadius: 50 }}
              >
                <span className="btn-inicio-style">Contestar</span>
              </Button>
            </div>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="text-center" style={{ height: 220 }}>
          <div className="card-tr-actions">
            <div className="d-flex flex-row align-items-center">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Contesta la pregunta de Clima de forma diaria y ayuda a tu empresa a saber como se encuentra el clima laboral y poder tomar acción"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          <div
            className="py-2 d-flex align-items-center justify-content-center"
            style={{ marginTop: 50 }}
          >
            <h5
              className="font-weight-bold font-size-lg"
              style={{ color: "#291c33" }}
            >
              ¡Ya has contestado hoy!
            </h5>
          </div>
          <img
            alt="..."
            className="card-img-top rounded-sm"
            src={thanks}
            style={{ width: 70 }}
          />
        </Card>
      );
    }
  } else {
    return null;
  }
}
