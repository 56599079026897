import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Button, Tooltip, useMediaQuery } from "@material-ui/core";

import regalo from "../../../assets/images/climind/regalo.png";
import felicitacion from "../../../assets/images/climind/thumbs-up.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import grafico from "../../../assets/images/climind/planta-de-cultivo.svg";
import { LineWeight } from "@material-ui/icons";

export default function ActionRecognition({
  toggleCongratulation,
  toggleGratitude,
  toggleRecognition,
  toggleProposal,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const cardStyle = {
    height: "auto",
    Height: 150,
    padding: "16px",
  };
  const coinStyle = {
    width: isMobile ? 25 : 29,
    height: isMobile ? 25 : 29,
    cursor: "pointer",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  };

  const iconStyle = {
    width: isMobile ? 22 : 25,
    height: isMobile ? 22 : 25,
  };

  const actionsContainerStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "space-around",
  };
  const actionRowStyle = {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginBottom: isMobile ? "16px" : 0,
  };

  const actionItemStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    flex: isMobile ? "1 1 calc(50% - 16px)" : "1 1 25%",
    maxWidth: isMobile ? "200px" : "150px",
    marginBottom: "5px",
  };

  const imageStyle = {
    width: 45,
    height: 45,
  };

  return (
    <Card style={cardStyle}>
      <div style={headerStyle}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Tooltip
            classes={{
              tooltip: "text-center p-3 tooltip-secondary",
            }}
            arrow
            placement="bottom"
            title="Gana 15 puntos por enviar Reconocimientos y Propuestas de mejora y 5 puntos por enviar Felicitaciones y Agradecimientos"
          >
            <img
              alt="..."
              src={require("../../../assets/images/climind/coin-final.png")}
              style={coinStyle}
              className="avatar-icon rounded-circle"
            />
          </Tooltip>
          <p
            style={{
              color: "#937af8",
              fontSize: "13px",
              fontFamily: "Inter",
              fontWeight: "700",
            }}
          >
            5-15
          </p>
        </div>
        <h3
          className=" text-center"
          style={{ transform: "translate(7px, 5px)" }}
        >
          Entregar Feedback
        </h3>
        <div style={{ display: "flex", gap: isMobile ? "3px" : "8px" }}>
          <Tooltip
            classes={{
              tooltip: "text-center p-3 tooltip-secondary",
            }}
            arrow
            placement="bottom"
            title="las respuestas de reconocimientos y propuestas de mejora son SIEMPRE anónimas (puedes cambiar la privacidad en tu perfil). Los agradecimientos y felicitaciones serán SIEMPRE publicadas en el timeline."
          >
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="text-first font-weight-bold"
            >
              <img
                alt="..."
                src={require("../../../assets/images/climind/candado.svg")}
                style={iconStyle}
              />
            </a>
          </Tooltip>
          <Tooltip
            classes={{
              tooltip: "text-center p-3 tooltip-secondary",
            }}
            arrow
            placement="bottom"
            title="Aquí podrás entregar Reconocimientos, Felicitaciones, Agradecimientos y Propuestas de mejora al resto de miembros de tu empresa"
          >
            <a
              href="#/"
              onClick={(e) => e.preventDefault()}
              className="text-first font-weight-bold"
            >
              <img
                alt="..."
                src={require("../../../assets/images/climind/info.png")}
                style={{
                  ...iconStyle,
                  transform: isMobile ? "translateY(2px)" : "translateY(1px)",
                }}
                className="avatar-icon rounded-circle"
              />
            </a>
          </Tooltip>
        </div>
      </div>

      <div style={actionsContainerStyle}>
        <div style={actionRowStyle}>
          <div style={actionItemStyle}>
            <img
              alt="..."
              className="card-img-top rounded-sm mb-2"
              src={felicitacion}
              style={imageStyle}
            />
            <Button
              onClick={toggleRecognition}
              variant="text"
              style={{ background: "#291C33", borderRadius: 50, height: 40 }}
            >
              <span className="btn-inicio-style">Reconocimiento</span>
            </Button>
          </div>
          <div style={actionItemStyle}>
            <img
              alt="..."
              className="card-img-top rounded-sm mb-2"
              src={regalo}
              style={imageStyle}
            />
            <Button
              onClick={toggleCongratulation}
              variant="text"
              style={{ background: "#291C33", borderRadius: 50, height: 40 }}
            >
              <span className="btn-inicio-style">Felicitación</span>
            </Button>
          </div>
        </div>
        <div style={actionRowStyle}>
          <div style={actionItemStyle}>
            <img
              alt="..."
              className="card-img-top rounded-sm mb-2"
              src={agradecimiento}
              style={imageStyle}
            />
            <Button
              onClick={toggleGratitude}
              variant="text"
              style={{ background: "#291C33", borderRadius: 50, height: 40 }}
            >
              <span className="btn-inicio-style">Agradecimiento</span>
            </Button>
          </div>
          <div style={actionItemStyle}>
            <img
              alt="..."
              className="card-img-top rounded-sm mb-2"
              src={grafico}
              style={imageStyle}
            />
            <Button
              onClick={toggleProposal}
              variant="text"
              style={{ background: "#291C33", borderRadius: 50, height: 40 }}
            >
              <span
                className="btn-inicio-style"
                style={isMobile ? { width: "90px" } : { width: "158px" }}
              >
                Propuesta de mejora
              </span>
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}
