import React, { useState } from "react";
import TrafficLight from "react-trafficlight";
import Traffic from "../../../../../../climind-components/TrafficLight/TrafficLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  Button,
  Tooltip,
  CardContent,
  useMediaQuery,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import GaugeChart from "react-gauge-chart";
export default function DashboardUsage1({
  TotalUsers,
  TotalActiveUsers,
  TotalActiveUsersPercent,
  TotalRecurringUsers,
  TotalActivePreviousUsers,
  TotalActivePreviousUsersPercent,
  TotalRecurringUsersPrevious,
  loading,
  loadingPrevious,
}) {
  let difActiveUsers =
    TotalActiveUsers -
    (isFinite(TotalActivePreviousUsers) ? TotalActivePreviousUsers : 0);
  let difPercentActiv =
    TotalActiveUsersPercent - TotalActivePreviousUsersPercent;
  let difRecurringUs = TotalRecurringUsers - TotalRecurringUsersPrevious;

  console.log("====");
  console.log(TotalRecurringUsers);
  console.log(TotalRecurringUsersPrevious);
  console.log(difRecurringUs);

  const isMobile = useMediaQuery("(max-width:600px)");

  if (loading || loadingPrevious) return "Esperando";

  return (
    <>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item xl={3} md={6}>
            <Card
              className="card-box border-0 shadow-first-sm p-4"
              style={{ minHeight: 197 }}
            >
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-first text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["fas", "users-cog"]} />
                </div>
                <h4 style={{ fontSize: "16px" }}>Usuarios registrados</h4>
              </div>
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="Número de usuarios registrados."
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold"
                  >
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={
                        isMobile
                          ? { width: 22, height: 22 }
                          : { width: 26, height: 26 }
                      }
                    >
                      <img
                        alt="..."
                        src={require("../../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="display-3 text-center line-height-sm text-second d-flex align-items-center pt-3 justify-content-center">
                {/* <FontAwesomeIcon
                  icon={['fas', 'arrow-down']}
                  className="font-size-sm text-danger mr-2"
                /> */}
                <div>{TotalUsers}</div>
              </div>
              <h4 style={{ fontSize: "16px" }} className="text-center pt-3">
                Usuarios de la compañia
              </h4>
            </Card>
          </Grid>
          <Grid item xl={3} md={6}>
            <Card
              className="card-box border-0 shadow-success-sm p-4"
              style={{ minHeight: 197 }}
            >
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-success text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["fas", "users"]} />
                </div>
                <h4 style={{ fontSize: "16px" }}>Usuarios Activos</h4>
              </div>
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="Número de usuarios activos en la plataforma en el periodo seleccionado."
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold"
                  >
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={
                        isMobile
                          ? { width: 22, height: 22 }
                          : { width: 26, height: 26 }
                      }
                    >
                      <img
                        alt="..."
                        src={require("../../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="display-3 text-center line-height-sm text-second d-flex align-items-center pt-3 justify-content-center">
                {difActiveUsers > 0 ? (
                  <FontAwesomeIcon
                    icon={["fas", "arrow-up"]}
                    className="font-size-sm text-success mr-2"
                  />
                ) : difActiveUsers < 0 ? (
                  <FontAwesomeIcon
                    icon={["fas", "arrow-down"]}
                    className="font-size-sm text-warning mr-2"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={["far", "dot-circle"]}
                    className="font-size-sm text-first mr-2"
                  />
                )}
                <div>{TotalActiveUsers}</div>
              </div>
              <div className="text-center pt-3">
                {isFinite(difActiveUsers) ? (
                  <h4 style={{ fontSize: "16px" }}>
                    <b>{difActiveUsers.toFixed(0)}</b> usuarios desde el periodo
                    pasado
                  </h4>
                ) : (
                  <></>
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xl={3} md={6}>
            <Card
              className="card-box border-0 shadow-danger-sm p-4"
              style={{ minHeight: 197 }}
            >
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-danger text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["fas", "user-check"]} />
                </div>
                <h4 style={{ fontSize: "16px" }}>Nivel de Activos</h4>
              </div>
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="Nivel de actividad. 
                  Usuarios activos en relación al número total de usuarios registrados. 
                  VERDE (+ de 30% de usuarios activos), AMARILLO (entre 30% y 15%) y ROJO (por debajo de 15%)."
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold"
                  >
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={
                        isMobile
                          ? { width: 22, height: 22 }
                          : { width: 26, height: 26 }
                      }
                    >
                      <img
                        alt="..."
                        src={require("../../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="display-3 text-center line-height-sm text-second d-flex align-items-center pt-3 justify-content-center">
                {isFinite(difPercentActiv) && difPercentActiv > 0 ? (
                  <FontAwesomeIcon
                    icon={["fas", "arrow-up"]}
                    className="font-size-sm text-success mr-2"
                  />
                ) : isFinite(difPercentActiv) && difPercentActiv < 0 ? (
                  <FontAwesomeIcon
                    icon={["fas", "arrow-down"]}
                    className="font-size-sm text-warning mr-2"
                  />
                ) : isFinite(difPercentActiv) ? (
                  <FontAwesomeIcon
                    icon={["far", "dot-circle"]}
                    className="font-size-sm text-first mr-2"
                  />
                ) : (
                  <div></div>
                )}
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title={
                    isFinite((TotalActiveUsersPercent * 100).toFixed(2))
                      ? (TotalActiveUsersPercent * 100).toFixed(2) + "%"
                      : 0 + "%"
                  }
                >
                  <div>
                    {TotalActiveUsersPercent * 100 > 30 ? (
                      <Traffic color="green" />
                    ) : TotalActiveUsersPercent * 100 < 15 ? (
                      <Traffic color="red" />
                    ) : isFinite(TotalActiveUsersPercent) ? (
                      <Traffic color="yellow" />
                    ) : (
                      <Traffic color="red" />
                    )}
                  </div>
                </Tooltip>
              </div>
              <div className="text-center pt-3">
                {isFinite(difPercentActiv) ? (
                  <h4 style={{ fontSize: "16px" }}>
                    <b>{(difPercentActiv * 100).toFixed(2) + "%"}</b> desde el
                    periodo pasado
                  </h4>
                ) : (
                  <></>
                )}
              </div>
            </Card>
          </Grid>
          <Grid item xl={3} md={6}>
            <Card
              className="card-box border-0 shadow-primary-sm p-4"
              style={{ minHeight: 197 }}
            >
              <div className="d-flex align-items-center">
                <div className="d-40 btn-icon rounded-circle bg-primary text-white text-center font-size-lg mr-3">
                  <FontAwesomeIcon icon={["fas", "child"]} />
                </div>
                <h4 style={{ fontSize: "16px" }}>Nivel de recurrencia</h4>
              </div>
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="Nivel de recurrencia. 
                  Porcentaje de usuarios activos recurrentes promedio en la plataforma, en el período seleccionado. 
                  VERDE (+ de 30% de usuarios recurrentes en el periodo), AMARILLO (entre 30 y 15 % de usuarios recurrentes en el perirodo) y ROJO (Menos de 15%)."
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold"
                  >
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={
                        isMobile
                          ? { width: 22, height: 22 }
                          : { width: 26, height: 26 }
                      }
                    >
                      <img
                        alt="..."
                        src={require("../../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="display-3 text-center line-height-sm text-second d-flex align-items-center pt-3 justify-content-center">
                {isFinite(difRecurringUs) && difRecurringUs > 0 ? (
                  <FontAwesomeIcon
                    icon={["fas", "arrow-up"]}
                    className="font-size-sm text-success mr-2"
                  />
                ) : isFinite(difRecurringUs) && difRecurringUs < 0 ? (
                  <FontAwesomeIcon
                    icon={["fas", "arrow-down"]}
                    className="font-size-sm text-warning mr-2"
                  />
                ) : isFinite(difRecurringUs) ? (
                  <FontAwesomeIcon
                    icon={["far", "dot-circle"]}
                    className="font-size-sm text-first mr-2"
                  />
                ) : (
                  <div></div>
                )}
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="bottom"
                  title={
                    isFinite((TotalRecurringUsers * 100).toFixed(2))
                      ? (TotalRecurringUsers * 100).toFixed(2) + "%"
                      : 0 + "%"
                  }
                >
                  <div>
                    {TotalRecurringUsers * 100 > 30 ? (
                      <Traffic color="green" />
                    ) : TotalRecurringUsers * 100 < 15 ? (
                      <Traffic color="red" />
                    ) : isFinite(TotalRecurringUsers) ? (
                      <Traffic color="yellow" />
                    ) : (
                      <Traffic color="red" />
                    )}
                  </div>
                </Tooltip>
              </div>
              <div className="text-black-50 text-center pt-3">
                {isFinite(difRecurringUs) ? (
                  <h4 style={{ fontSize: "16px" }}>
                    <b>{(difRecurringUs * 100).toFixed(2) + "%"}</b> desde el
                    periodo pasado
                  </h4>
                ) : (
                  <div></div>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
