import React, { useState, useContext } from "react";

import clsx from "clsx";

import { Collapse } from "@material-ui/core";

import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";

import SidebarUserbox from "../SidebarUserbox";

import ChevronRightTwoToneIcon from "@material-ui/icons/ChevronRightTwoTone";
import VerifiedUserTwoToneIcon from "@material-ui/icons/VerifiedUserTwoTone";
import RoomTwoToneIcon from "@material-ui/icons/RoomTwoTone";
import CameraAltTwoToneIcon from "@material-ui/icons/CameraAltTwoTone";
import CollectionsTwoToneIcon from "@material-ui/icons/CollectionsTwoTone";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";
import BusinessCenterTwoToneIcon from "@material-ui/icons/BusinessCenterTwoTone";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import MoveToInboxTwoToneIcon from "@material-ui/icons/MoveToInboxTwoTone";
import BallotTwoToneIcon from "@material-ui/icons/BallotTwoTone";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AdjustIcon from "@material-ui/icons/Adjust";
import PeopleIcon from "@material-ui/icons/People";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import DepartureBoardTwoToneIcon from "@material-ui/icons/DepartureBoardTwoTone";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import DevicesOtherTwoToneIcon from "@material-ui/icons/DevicesOtherTwoTone";
import LinkTwoToneIcon from "@material-ui/icons/LinkTwoTone";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SecurityTwoToneIcon from "@material-ui/icons/SecurityTwoTone";
import CameraTwoToneIcon from "@material-ui/icons/CameraTwoTone";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ExtensionIcon from "@material-ui/icons/Extension";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import { UserContext } from "../../context/UserContext";
import { color } from "d3";

const SidebarMenu = (props) => {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { setSidebarToggleMobile, sidebarUserbox } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [dashboardOpen, setDashboardOpen] = useState(false);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    event.preventDefault();
  };

  const [elementsOpen, setElementsOpen] = useState(false);
  const toggleElements = (event) => {
    setElementsOpen(!elementsOpen);
    event.preventDefault();
  };

  const [pagesOpen, setPagesOpen] = useState(false);
  const togglePages = (event) => {
    setPagesOpen(!pagesOpen);
    event.preventDefault();
  };

  const [otherPagesOpen, setOtherPagesOpen] = useState(false);
  const toggleOtherPages = (event) => {
    setOtherPagesOpen(!otherPagesOpen);
    event.preventDefault();
  };

  const [applicationOpen, setApplicationOpen] = useState(false);
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen);
    event.preventDefault();
  };

  const [designSystemOpen, setDesignSystemOpen] = useState(false);
  const toggleDesignSystem = (event) => {
    setDesignSystemOpen(!designSystemOpen);
    event.preventDefault();
  };

  const [blocksOpen, setBlocksOpen] = useState(false);
  const toggleBlocks = (event) => {
    setBlocksOpen(!blocksOpen);
    event.preventDefault();
  };

  const [levelsOpen, setLevelsOpen] = useState(false);
  const toggleLevels = (event) => {
    setLevelsOpen(!levelsOpen);
    event.preventDefault();
  };

  const [widgetsOpen, setWidgetsOpen] = useState(false);
  const toggleWidgets = (event) => {
    setWidgetsOpen(!widgetsOpen);
    event.preventDefault();
  };

  const [chartsOpen, setChartsOpen] = useState(false);
  const toggleCharts = (event) => {
    setChartsOpen(!chartsOpen);
    event.preventDefault();
  };

  const [uiKitComponentsOpen, setUiKitComponents] = useState(false);
  const toggleUiKitComponents = (event) => {
    setUiKitComponents(!uiKitComponentsOpen);
    event.preventDefault();
  };

  const [formsComponentsOpen, setFormsComponents] = useState(false);
  const toggleFormsComponents = (event) => {
    setFormsComponents(!formsComponentsOpen);
    event.preventDefault();
  };

  const [collapsedLayoutOpen, setCollapsedLayoutOpen] = useState(false);
  const toggleCollapsedLayout = (event) => {
    setCollapsedLayoutOpen(!collapsedLayoutOpen);
    event.preventDefault();
  };

  const [pagesLoginOpen, setPagesLoginOpen] = useState(false);
  const togglePagesLogin = (event) => {
    setPagesLoginOpen(!pagesLoginOpen);
    event.preventDefault();
  };

  const [pagesRegisterOpen, setPagesRegisterOpen] = useState(false);
  const togglePagesRegister = (event) => {
    setPagesRegisterOpen(!pagesRegisterOpen);
    event.preventDefault();
  };

  const [pagesRecoverOpen, setPagesRecoverOpen] = useState(false);
  const togglePagesRecover = (event) => {
    setPagesRecoverOpen(!pagesRecoverOpen);
    event.preventDefault();
  };
  if (dbuser) {
    console.log(dbuser.getIfIsManager);
    return (
      <>
        <PerfectScrollbar>
          {sidebarUserbox && <SidebarUserbox />}
          <div className="sidebar-navigation">
            <div
              className="sidebar-header"
              style={{ paddingTop: "20px" }}
            ></div>
            {dbuser.getUserByUid.role === "admin" && (
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <li>
                  <a
                    href="#/"
                    onClick={toggleDashboard}
                    className={clsx("sidebar-item-label", {
                      active: dashboardOpen,
                    })}
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/dashboard.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>

                    <span
                      className="sidebar-item-label"
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "700",
                        lineHeight: "19.36px",
                        color: "#291c33",
                      }}
                    >
                      Dashboards
                    </span>
                    <span className="sidebar-icon-indicator">
                      <ChevronRightTwoToneIcon />
                    </span>
                  </a>
                  <Collapse in={dashboardOpen}>
                    <ul>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/DashboardUsage"
                        >
                          Uso
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/DashboardEnvironment"
                        >
                          Clima
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/DashboardPulse"
                        >
                          Pulso
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/DashboardRecognitions"
                        >
                          Feedback
                        </NavLink>
                      </li>
                      <li>
                        <NavLink onClick={toggleSidebarMobile} to="/Challenges">
                          Retos
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/DashboardComments"
                        >
                          Comentarios
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/DashboardOkrs"
                        >
                          OKR's
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>

                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Users"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/usuarios.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Usuarios
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Teams"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/departamentos-y-grupos.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Departamentos y grupos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Ranking"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/rating.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Ranking
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/General"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/general.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    General
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link"
                    to="/HomeUser"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/panel-inicio.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Panel inicial usuario
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/ChallengesList"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/retos.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Retos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Okrs"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/okr.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    OKR's
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Portfolio"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/propuesta-de-mejora.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Propuestas de mejora
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Recognitions"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/reconocimiento.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Reconocimientos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Congratulations"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/felicitaciones.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Felicitaciones
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Gratitudes"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/agradecimientos.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Agradecimientos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/MainCurriculum"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/cv.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Curriculum de soft skills
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    style={{
                      color: "#291c33",
                      fontFamily: "Inter",
                      fontWeight: "700",
                      lineHeight: "19.36px",
                    }}
                    to="/Shop"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/bienestar.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Centro de Bienestar
                  </NavLink>
                </li>
              </ul>
            )}
            {dbuser.getUserByUid.role === "user" && (
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <li>
                  {dbuser.getIfIsManager && (
                    <>
                      <a
                        href="#/"
                        onClick={toggleDashboard}
                        className={clsx("sidebar-item-label", {
                          active: dashboardOpen,
                        })}
                      >
                        <span className="sidebar-icon">
                          <img
                            alt="..."
                            src={require("../../assets/images/climind/dashboard.png")}
                            style={{ width: 20, height: 20 }}
                          />
                        </span>

                        <span
                          className="sidebar-item-label"
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "700",
                            lineHeight: "19.36px",
                            color: "#291c33",
                          }}
                        >
                          Dashboards
                        </span>
                        <span className="sidebar-icon-indicator">
                          <ChevronRightTwoToneIcon />
                        </span>
                      </a>
                      <Collapse in={dashboardOpen}>
                        <ul>
                          <li>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to="/DashboardUsage"
                            >
                              Uso
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to="/DashboardEnvironment"
                            >
                              Clima
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to="/DashboardPulse"
                            >
                              Pulso
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to="/DashboardRecognitions"
                            >
                              Feedback
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to="/Challenges"
                            >
                              Retos
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              onClick={toggleSidebarMobile}
                              to="/DashboardOkrs"
                            >
                              OKR's
                            </NavLink>
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  )}
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/HomeUser"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/panel-inicio.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Principal
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Recognitions"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/reconocimiento.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Reconocimientos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Congratulations"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/felicitaciones.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Felicitaciones
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Gratitudes"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/agradecimientos.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Agradecimientos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Portfolio"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/propuesta-de-mejora.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Propuestas de mejora
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/ChallengesList"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/retos.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Retos
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Okrs"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/okr.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    OKR's
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Ranking"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/rating.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Ranking
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/MainCurriculum"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/cv.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Curriculum de soft skills
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Shop"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/bienestar.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    Centro de Bienestar
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/General"
                  >
                    <span className="sidebar-icon">
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/general.png")}
                        style={{ width: 20, height: 20 }}
                      />
                    </span>
                    General
                  </NavLink>
                </li>
              </ul>
            )}
            {dbuser.getUserByUid.role === "superadmin" && (
              <ul>
                <li>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/Companies"
                  >
                    <span className="sidebar-icon">
                      <ExtensionIcon />
                    </span>
                    Compañia
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/LoadCompetences"
                  >
                    <span className="sidebar-icon">
                      <ExtensionIcon />
                    </span>
                    Cargar competencias
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    onClick={toggleSidebarMobile}
                    className="nav-link-simple"
                    to="/LoadQuestions"
                  >
                    <span className="sidebar-icon">
                      <ExtensionIcon />
                    </span>
                    Cargar preguntas
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </PerfectScrollbar>
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,

  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
