import React, { useState, useCallback, useEffect, useContext } from "react";

import {
  Grid,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";

import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { CHANGE_STATE } from "data/mutations";
import CountUp from "react-countup";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";
import {
  GET_USER_BY_UID,
  GETS_FOR_GENERAL,
  GET_IMPROVEMENT_PROPOSALS_SENDED_BY_USER_ID_2,
} from "data/queries";
var moment = require("moment-timezone");

export default function SentProposals() {
  const [dataSelected, setDataSelected] = useState(false);
  const [modalViewEmployee, setModalViewEmployee] = useState(false);
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee);
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const limit = 10; // Cantidad de usuarios por página
  const [offset, setOffset] = React.useState(0); // Offset para la paginación
  const { loading, error, data, fetchMore } = useQuery(
    GET_IMPROVEMENT_PROPOSALS_SENDED_BY_USER_ID_2,
    {
      variables: {
        id: dbuser.getUserByUid.id,
        limit: 10, // Especifica el límite de usuarios por página
        offset: offset, // Especifica el desplazamiento inicial
      },
    }
  );
  const isMobile = useMediaQuery("(max-width:600px)");
  const handlePageChange = (_, newPage) => {
    // Calcular el nuevo offset al cambiar de página
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    console.log(newOffset);
  };

  if (data) {
    return (
      <>
        <Dialog
          open={modalViewEmployee}
          onClose={toggleViewEmployee}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <Grid item>
            <Card className="card-box p-4">
              <div className="d-flex align-items-center mb-3 mt-1">
                <div className="avatar-icon-wrapper rounded-circle mr-3">
                  <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                    <div className="rounded-circle overflow-hidden">
                      <img
                        alt="..."
                        className="img-fluid"
                        src={
                          dataSelected && dataSelected.receiver.urlImage
                            ? dataSelected.receiver.urlImage
                            : require("../../../assets/images/climind/default_avatar.png")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold "
                    title="..."
                    style={{
                      fontFamily: "Inter",
                      color: "#291c33",
                      fontSize: isMobile ? "14px" : "",
                    }}
                  >
                    {dataSelected ? dataSelected.receiver.name : null}&nbsp;
                    {dataSelected ? dataSelected.receiver.surname : null}
                    {/* {dataSelected.getRecognitionsSendedByUserIdLast30.receiver.name} */}
                  </a>
                  <span className=" d-block">
                    {dataSelected ? dataSelected.receiver.email : null}
                  </span>
                </div>
              </div>
              <h5 className="text-center mt-5 mb-3">
                Has querido que{" "}
                {dataSelected
                  ? dataSelected.actionCheck === "INCLUDE"
                    ? "INCLUYA"
                    : dataSelected.actionCheck === "EXCLUDE"
                    ? "EXCLUYA"
                    : dataSelected.actionCheck === "IMPROVE"
                    ? "MEJORE"
                    : null
                  : null}{" "}
                este comportamiento
              </h5>
              <h5 className="text-center mt-5 mb-3">
                Has enviado el siguiente comentario
              </h5>
              <h4 className="mb-20 text-center">
                {dataSelected && dataSelected.commentary
                  ? dataSelected.commentary
                  : null}
              </h4>
              {dataSelected &&
              dataSelected.competenceId &&
              dataSelected.competenceId.name ? (
                <h5 className="text-center mt-5 mb-3">
                  Has entregado la siguiente competencia
                </h5>
              ) : null}
              <h3 className="mb-0 text-center">
                {dataSelected &&
                dataSelected.competenceId &&
                dataSelected.competenceId.name
                  ? dataSelected.competenceId.name
                  : null}
              </h3>
              <div className="text-center">
                <img
                  src={
                    dataSelected &&
                    dataSelected.competenceId &&
                    dataSelected.competenceId.urlImage
                      ? dataSelected.competenceId.urlImage
                      : null
                  }
                  className="card-img-top rounded "
                  // alt="..."
                  style={{ width: 100 }}
                />
              </div>
              <h4 className=" text-center mt-5 mb-3">
                {dataSelected &&
                dataSelected.competenceId &&
                dataSelected.competenceId.description
                  ? dataSelected.competenceId.description
                  : null}
              </h4>
              <Button
                fullWidth
                className="btn-neutral-success text-uppercase"
                onClick={() => {
                  toggleViewEmployee();
                }}
              >
                <span className="font-weight-bold">Ok</span>
              </Button>
            </Card>
          </Grid>
        </Dialog>
        <Grid container spacing={6}>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header bg-light">
                <div className="card-header--title">
                  <big className="d-block text-uppercase mt-1 font-weight-bold">
                    Propuestas de mejora enviadas
                  </big>
                </div>
              </div>
              <List component="div" className="list-group-flush">
                {data.getImprovementProposalsSendedByUserId2
                  .improvementProposalsObtained.length != 0 ? (
                  data.getImprovementProposalsSendedByUserId2.improvementProposalsObtained.map(
                    (element, index) => {
                      console.log(element);
                      return (
                        <ListItem className="py-3">
                          <Grid container spacing={0} alignItems="center">
                            <div className="flex-column flex-grow-1 text-right d-md-none d-md-none">
                              <p
                                className="mb-0"
                                style={{ fontSize: isMobile ? "14px" : "" }}
                              >
                                {moment(element.date).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            <Grid
                              item
                              xl={4}
                              md={12}
                              className="d-flex align-items-center"
                            >
                              <Button
                                size="small"
                                className="btn-neutral-success mr-50"
                                onClick={() => {
                                  setDataSelected(element);
                                  toggleViewEmployee();
                                  console.log(element.receiver.name);
                                }}
                              >
                                Ver
                              </Button>
                              <div className="d-flex align-items-center">
                                <div className="avatar-icon-wrapper mr-2">
                                  <div className="avatar-icon">
                                    <img
                                      alt="..."
                                      src={
                                        element.receiver.urlImage
                                          ? element.receiver.urlImage
                                          : require("../../../assets/images/climind/default_avatar.png")
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <a
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="font-weight-bold text-black"
                                    style={{ fontSize: isMobile ? "14px" : "" }}
                                    title="..."
                                  >
                                    {element.receiver.name
                                      ? element.receiver.name
                                      : "sin nombre"}{" "}
                                    {element.receiver.surname
                                      ? element.receiver.surname
                                      : "sin nombre"}
                                  </a>
                                  <span className="text-black-50 d-none d-md-block">
                                    {element.receiver.email
                                      ? element.receiver.email
                                      : "sin puesto"}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={8}
                              md={12}
                              className="d-flex align-items-center justify-content-center"
                            >
                              <div className="flex-column flex-grow-1 text-center d-none d-md-block">
                                <p className="mb-0">
                                  {moment(element.date).format("DD/MM/YYYY")}
                                </p>
                              </div>
                              <Grid
                                item
                                xl={4}
                                md={12}
                                className="d-flex align-items-center justify-content-end"
                              >
                                {!element.state && (
                                  <div>
                                    <div className="badge badge-second">
                                      Sin aceptar
                                    </div>
                                  </div>
                                )}
                                {element.state === "ACCEPTED" && (
                                  <div className="badge badge-info">
                                    Aceptada
                                  </div>
                                )}
                                {element.state === "MOREINFO" && (
                                  <div className="badge badge-warning">
                                    Ha pedido más información
                                  </div>
                                )}
                                {element.state === "REJECTED" && (
                                  <div className="badge badge-danger">
                                    Rechazada
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    }
                  )
                ) : (
                  <h5 className="my-5 ml-4">
                    Aún no has enviado propuestas de mejora
                  </h5>
                )}
              </List>
              <div className="divider" />
              <div className="divider" />
              <div className="p-3 d-flex justify-content-center">
                {/* <Pagination className="pagination-primary" count={10} onClick={handleLoadMore}/> */}
                <Pagination
                  count={Math.ceil(
                    data.getImprovementProposalsSendedByUserId2
                      .totalImprovementProposals / limit
                  )}
                  page={offset / limit + 1}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      component="a"
                      // href="#"
                      onClick={(e) => e.preventDefault()}
                      {...item}
                    />
                  )}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
