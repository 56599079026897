import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  IconButton,
  Box,
  Typography,
  Tabs,
  Tab,
  Popover,
  LinearProgress,
  Badge,
  Button,
  List,
  ListItem,
  Divider,
  Card,
  Grid,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { UserContext } from "../../context/UserContext";
import { GETS_FOR_GENERAL, GET_USER_BY_UID } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import avatar1 from "../../assets/images/avatars/avatar1.jpg";
import avatar2 from "../../assets/images/avatars/avatar2.jpg";
import avatar6 from "../../assets/images/avatars/avatar6.jpg";
import avatar7 from "../../assets/images/avatars/avatar7.jpg";
import people1 from "../../assets/images/stock-photos/people-1.jpg";
import people3 from "../../assets/images/stock-photos/people-3.jpg";

import NotificationsActiveTwoToneIcon from "@material-ui/icons/NotificationsActiveTwoTone";
import PollTwoToneIcon from "@material-ui/icons/PollTwoTone";
import PersonIcon from "@material-ui/icons/Person";

import Chart from "react-apexcharts";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useHistory } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const HeaderDots = () => {
  const chartHeaderDotsOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      stacked: true,
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
      },
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["transparent"],
    },
    colors: ["#7a7b97", "rgba(122, 123, 151, 0.15)"],
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
      "Last week",
      "Last month",
      "Last year",
      "Last quarter",
    ],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    yaxis: {
      min: 0,
    },
  };
  const chartHeaderDotsData = [
    {
      name: "Net Profit",
      data: [2.3, 3.1, 4.0, 3.8, 5.1, 3.6, 4.0, 3.8, 5.1, 3.6, 3.2],
    },
    {
      name: "Net Loss",
      data: [2.1, 2.1, 3.0, 2.8, 4.0, 3.8, 5.1, 3.6, 4.1, 2.6, 1.2],
    },
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });
  if (data) {
    let cie =
      ((data.getAnswerEmotionsSendedByUserIdLast30.length <= 20
        ? data.getAnswerEmotionsSendedByUserIdLast30.length
        : 20) /
        20) *
        100 *
        (30 / 100) +
      ((data.getAnswersSendedByUserIdLast30.length <= 20
        ? data.getAnswersSendedByUserIdLast30.length
        : 20) /
        20) *
        100 *
        (30 / 100) +
      ((data.getRecognitionsSendedByUserIdLast30.length <= 20
        ? data.getRecognitionsSendedByUserIdLast30.length
        : 20) /
        20) *
        100 *
        (15 / 100) +
      ((data.getImprovementProposalsSendedByUserIdLast30.length <= 20
        ? data.getImprovementProposalsSendedByUserIdLast30.length
        : 20) /
        20) *
        100 *
        (15 / 100) +
      ((data.getCongratulationsSendedByUserIdLast30.length <= 20
        ? data.getCongratulationsSendedByUserIdLast30.length
        : 20) /
        20) *
        100 *
        (5 / 100) +
      ((data.getGratitudesSendedByUserIdLast30.length <= 20
        ? data.getGratitudesSendedByUserIdLast30.length
        : 20) /
        20) *
        100 *
        (5 / 100);
    console.log(dbuser);

    return (
      <>
        <div
          className="d-flex align-items-center popover-header-wrapper"
          style={{ width: 800 }}
        >
          <Grid container spacing={1}>
            {!isMobile && (
              <Grid
                item
                xl={4}
                md={4}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <div className="d-flex mb-1 font-weight-bold justify-content-between font-size-sm">
                    {/* <div className="text-uppercase ">Tu uso de Climind (Últimos 30 días) &nbsp;</div> */}
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="bottom"
                      title="Este es tu CIE (Climind Index Engagement). Representa tu uso de Climind en los últimos 30 días"
                    >
                      <div
                        className="text-uppercase mr-5"
                        style={{
                          color: "#291C33",
                          fontFamily: "Inter",
                          fontWeight: "700",
                          fontSize: "12px",
                          lineHeight: "15px",
                        }}
                      >
                        Mi CIE &nbsp;
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        color: "#291C33",
                        fontFamily: "Inter",
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {cie}%
                    </div>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-sm"
                    value={cie}
                  />
                </div>
              </Grid>
            )}
            {!isMobile && (
              <Grid
                item
                xl={4}
                md={4}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <div className="d-flex mb-1 font-weight-bold justify-content-between font-size-sm">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="bottom"
                      title="Este es tu CIE (Climind Index Engagement). Representa tu uso de Climind desde el inicio de tu actividad"
                    >
                      <div
                        className="text-uppercase mr-5"
                        style={{
                          color: "#291C33",
                          fontFamily: "Inter",
                          fontWeight: "700",
                          fontSize: "12px",
                          lineHeight: "15px",
                        }}
                      >
                        Mi CIE HISTÓRICO &nbsp;
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        color: "#291C33",
                        fontFamily: "Inter",
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {dbuser.getUserByUid.cieHistory
                        ? dbuser.getUserByUid.cieHistory.toFixed(2)
                        : 0}
                      %
                    </div>
                  </div>
                  <LinearProgress
                    variant="determinate"
                    className="progress-sm"
                    value={
                      dbuser.getUserByUid.cieHistory
                        ? dbuser.getUserByUid.cieHistory.toFixed(2)
                        : 0
                    }
                  />
                </div>
              </Grid>
            )}
            {!isMobile && (
              <Grid
                item
                xl={2}
                md={2}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="align-box-row userbox-details">
                  <div className=" avatar-icon rounded-circle">
                    <Tooltip
                      classes={{
                        tooltip: "text-center p-3 tooltip-secondary",
                      }}
                      arrow
                      placement="bottom"
                      title="Tu nivel en Climind"
                    >
                      <img
                        alt="..."
                        src={require("../../assets/images/climind/cruzando-espadas.svg")}
                        width={32.16}
                        height={42}
                      />
                    </Tooltip>
                  </div>
                  <h6
                    className="d-flex align-items-center justify-content-center p-2 mb-0"
                    style={{
                      color: "#291C33",
                      fontFamily: "Inter",
                      fontWeight: "700",
                      fontSize: "16px",
                      lineHeight: "19px",
                    }}
                  >
                    {/* Nivel {Math.floor(dbuser.getUserByUid.score/100)} */}
                    {Math.floor(dbuser.getUserByUid.score / 100)}
                  </h6>
                </div>
              </Grid>
            )}
            <Grid
              item
              xl={2}
              md={2}
              xs={3}
              className="d-flex justify-content-center"
            >
              <div className="align-box-row userbox-details">
                <div className=" avatar-icon rounded-circle">
                  <Tooltip
                    classes={{
                      tooltip: "text-center p-3 tooltip-secondary",
                    }}
                    arrow
                    placement="bottom"
                    title="Tus coins en Climind. Puedes canjearlos por premios en el centro de bienestar"
                  >
                    <img
                      alt="..."
                      src={require("../../assets/images/climind/coin-final.png")}
                      width={42}
                      height={42}
                    />
                  </Tooltip>
                </div>

                <h6
                  className="d-flex align-items-center justify-content-center p-2 mb-0"
                  style={{
                    color: "#291C33",
                    fontFamily: "Inter",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "19px",
                  }}
                >
                  {/* {dbuser.getUserByUid.points} Coins */}
                  {dbuser.getUserByUid.points}
                </h6>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default HeaderDots;
