import React, { Fragment, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Card,
  Button,
  List,
  Table,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { POSSIBLE_ANSWER_EMOTION_TODAY } from "data/queries";
import { UserContext } from "../../../context/UserContext";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";

import feliz from "../../../assets/images/climind/feliz.svg";
import neutro from "../../../assets/images/climind/neutro.svg";
import triste from "../../../assets/images/climind/triste.svg";
import thanks from "../../../assets/images/climind/thank-you.svg";
export default function Emotions({ toggle1, toggle2, toggle3, dataEmotion }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  const headerStyle = {
    paddingTop: 22,
    paddingBottom: 10,
    fontSize: isMobile ? "16px" : "18px",
  };
  const coinStyle = {
    width: isMobile ? 25 : 29,
    height: isMobile ? 25 : 29,
    cursor: "pointer",
  };
  const iconStyle = {
    width: isMobile ? 22 : 25,
    height: isMobile ? 22 : 25,
  };

  if (dataEmotion) {
    if (dataEmotion.possibleToAnswerEmotion === true) {
      // if (true) {
      return (
        <Card className="text-center" style={{ height: 175 }}>
          <div className="card-tr-left">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="bottom"
                title="Gana 30 puntos por contestar"
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/coin-final.png")}
                  className=" avatar-icon rounded-circle "
                  style={coinStyle}
                />
              </Tooltip>

              <p
                style={{
                  color: "#937af8",
                  fontSize: "13px",
                  fontFamily: "Inter",
                  fontWeight: "700",
                }}
              >
                30
              </p>
            </div>
          </div>
          <div className="card-tr-actions">
            <div
              style={{
                display: "flex",
                alignItems: "start",
                gap: isMobile ? "3px" : "8px",
              }}
            >
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div>
                    <img
                      style={iconStyle}
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Estamos interesados en mejorar tu experiencia laboral. 
                Necesitamos tu opinión y colaboración. Cada día tendrás que contestar 
                una sola vez sobre qué te ha parecido tu jornada laboral. 
                Pincha el emoticono que te identifique. Podrás ver cómo evoluciona"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle"
                    style={{
                      ...iconStyle,
                      transform: isMobile
                        ? "translateY(3px)"
                        : "translateY(1px)",
                    }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="card-header-alt d-flex flex-column justify-content-center p-10">
            <h3 style={headerStyle}>¿Cómo ha ido tu dia?</h3>
          </div>

          <div className="py-2 d-flex align-items-center justify-content-center">
            <Card
              className="card-transparent mx-3 mb-3 hover-scale-sm"
              style={{ cursor: "pointer" }}
            >
              <a
                onClick={toggle2}
                className="card-img-wrapper card-box-hover rounded"
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={feliz}
                  style={{ width: 75 }}
                />
              </a>
            </Card>
            <Card
              className="card-transparent mx-3 mb-3 hover-scale-sm"
              style={{ cursor: "pointer" }}
            >
              <a
                onClick={toggle3}
                className="card-img-wrapper card-box-hover rounded"
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={neutro}
                  style={{ width: 75 }}
                />
              </a>
            </Card>
            <Card
              className="card-transparent mx-3 mb-3 hover-scale-sm"
              style={{ cursor: "pointer" }}
            >
              <a
                onClick={toggle1}
                className="card-img-wrapper card-box-hover rounded"
              >
                <img
                  alt="..."
                  className="card-img-top rounded-sm"
                  src={triste}
                  style={{ width: 75 }}
                />
              </a>
            </Card>
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="text-center" style={{ height: 220 }}>
          <div className="card-tr-actions">
            <div className="d-flex flex-row align-items-center">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="LAS RESPUESTAS SON SIEMPRE ANÓNIMAS. (Puedes cambiar la privacidad en tu perfil)"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 30, height: 30 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/candado.svg")}
                    />
                  </div>
                </a>
              </Tooltip>
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="top"
                title="Estamos interesados en mejorar tu experiencia laboral. 
                Necesitamos tu opinión y colaboración. Cada día tendrás que contestar 
                una sola vez sobre qué te ha parecido tu jornada laboral. 
                Pincha el emoticono que te identifique. Podrás ver cómo evoluciona"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={{ width: 26, height: 26 }}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
          </div>
          {/* <div className="card-tr-footer">
          <div class="d-flex justify-content-end">
            <div className="d-flex flex-row align-items-center">
            <h5 className="font-weight-bold my-0 mr-1">20</h5>
              <div
                className=" avatar-icon rounded-circle "
                style={{ width: 30, height: 30 }}
              >
                <img
                  alt="..."
                  src={require("../../../assets/images/climind/vectorpaint.svg")}
                />
              </div>
              
            </div>
          </div>
          </div> */}

          <div
            className="py-2 d-flex align-items-center justify-content-center"
            style={{ marginTop: 50 }}
          >
            <h4 className="font-weight-bold font-size-lg text-black">
              ¡Ya has contestado hoy!
            </h4>
          </div>
          <img
            alt="..."
            className="card-img-top rounded-sm"
            src={thanks}
            style={{ width: 70 }}
          />
        </Card>
      );
    }
  } else {
    return null;
  }
}
