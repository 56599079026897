import React, { useState, useContext, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  GET_TIMELINE,
  POSSIBLE_ANSWER_CLIMA_TODAY,
  POSSIBLE_ANSWER_EMOTION_TODAY,
} from "data/queries";
import { UserContext } from "../../context/UserContext";
import { PageTitle } from "layout-components";
import { Grid, useMediaQuery } from "@material-ui/core";
import Timeline from "./components/Timeline";
import Emotions from "./components/Emotions";
import ActionQuestion from "./components/ActionQuestion";
import ActionRecognition from "./components/ActionRecognition";
import Information from "./components/Information";
import ModalEmotionSad from "./components/ModalEmotionSad";
import ModalEmotionHappy from "./components/ModalEmotionHappy";
import ModalEmotionMiddle from "./components/ModalEmotionMiddle";
import ModalQuestion from "./components/ModalQuestion";
import ModalCongratulation from "./components/ModalCongratulation";
import ModalGratitude from "./components/ModalGratitude";
import ModalRecognition from "./components/ModalRecognition";
import ModalProposal from "./components/ModalProposal";
import { color } from "d3";

export default function HomeUser() {
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);

  const [modalQuestion, setModalQuestion] = useState(false);
  const toggleQuestion = () => setModalQuestion(!modalQuestion);

  const [modalCongratulation, setModalCongratulation] = useState(false);
  const toggleCongratulation = () =>
    setModalCongratulation(!modalCongratulation);

  const [modalGratitude, setModalGratitude] = useState(false);
  const toggleGratitude = () => setModalGratitude(!modalGratitude);

  const [modalRecognition, setModalRecognition] = useState(false);
  const toggleRecognition = () => setModalRecognition(!modalRecognition);

  const [modalProposal, setModalProposal] = useState(false);
  const toggleProposal = () => setModalProposal(!modalProposal);

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  const [timeline, setTimeline] = useState([]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { loading, error, data, refetch, networkStatus, fetchMore } = useQuery(
    GET_TIMELINE,
    {
      variables: { id: dbuser.getUserByUid.companyId, first: 20 },
      skip: !dbuser.getUserByUid.companyId,
      notifyOnNetworkStatusChange: true,
    }
  );

  const {
    loading: loadingClima,
    error: errorClima,
    data: dataClima,
    refetch: refetchClima,
    networkStatus: networkStatusClima,
  } = useQuery(POSSIBLE_ANSWER_CLIMA_TODAY, {
    variables: { id: dbuser.getUserByUid.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingEmotion,
    error: errorEmotion,
    data: dataEmotion,
    refetch: refetchEmotion,
    networkStatus: networkStatusEmotion,
  } = useQuery(POSSIBLE_ANSWER_EMOTION_TODAY, {
    variables: { id: dbuser.getUserByUid.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    refetchClima();
    refetchEmotion();
    // refetchEmotion({ id: dbuser.getUserByUid.id })
  }, [modalQuestion, modal1, modal2, modal3]);

  const borderStyle = {
    marginTop: isMobile ? 0 : "-2.10rem",
    marginBottom: isMobile ? 0 : "-2.10rem",
  };

  if (data && dataClima && dataEmotion) {
    return (
      <>
        <PageTitle
          titleHeading="Panel inicial de usuario"
          titleDescription="¡Descubre todo lo que puedes hacer en Climind!"
        />
        <Grid container spacing={4} style={borderStyle}>
          <Grid item md={12} lg={12} xl={8}>
            <div className="mb-3">
              <Grid container spacing={4}>
                <Grid item lg={6} md={6}>
                  <Emotions
                    toggle1={toggle1}
                    toggle2={toggle2}
                    toggle3={toggle3}
                    dataEmotion={dataEmotion}
                  />
                </Grid>
                <Grid item lg={6} md={6}>
                  <ActionQuestion
                    toggleQuestion={toggleQuestion}
                    dataClima={dataClima}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="mb-3">
              <Grid container spacing={4}>
                <Grid item lg={12} md={12}>
                  <ActionRecognition
                    toggleCongratulation={toggleCongratulation}
                    toggleGratitude={toggleGratitude}
                    toggleRecognition={toggleRecognition}
                    toggleProposal={toggleProposal}
                    refetch={refetch}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="mb-3">
              <Grid container spacing={4}>
                <Grid item lg={12} md={12}>
                  <Information />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={12} lg={12} xl={4}>
            <Timeline
              loading={loading}
              error={error}
              data={data}
              fetchMore={fetchMore}
              refetch={refetch}
              networkStatus={networkStatus}
            />
          </Grid>
        </Grid>
        <ModalEmotionSad
          modal1={modal1}
          toggle1={toggle1}
          refetch={refetch}
          refetchEmotion={refetchEmotion}
        />
        <ModalEmotionHappy
          modal2={modal2}
          toggle2={toggle2}
          refetch={refetch}
          refetchEmotion={refetchEmotion}
        />
        <ModalEmotionMiddle
          modal3={modal3}
          toggle3={toggle3}
          refetch={refetch}
          refetchEmotion={refetchEmotion}
        />
        <ModalQuestion
          modalQuestion={modalQuestion}
          toggleQuestion={toggleQuestion}
          refetch={refetch}
          refetchClima={refetchClima}
        />
        <ModalCongratulation
          modalCongratulation={modalCongratulation}
          toggleCongratulation={toggleCongratulation}
          refetch={refetch}
        />
        <ModalGratitude
          modalGratitude={modalGratitude}
          toggleGratitude={toggleGratitude}
          refetch={refetch}
        />
        <ModalRecognition
          modalRecognition={modalRecognition}
          toggleRecognition={toggleRecognition}
          refetch={refetch}
        />
        <ModalProposal
          modalProposal={modalProposal}
          toggleProposal={toggleProposal}
          refetch={refetch}
        />
      </>
    );
  } else {
    return null;
  }
}
