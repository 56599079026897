import React, { Fragment, useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Table, Tooltip, Link, useMediaQuery } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { UserContext } from "../../../context/UserContext";
import emotion from "../../../assets/images/climind/reaction.png";
import recognition from "../../../assets/images/climind/thumbs-up.png";
import pregunta from "../../../assets/images/climind/faq.svg";
import felicitacion from "../../../assets/images/climind/regalo.png";
import agradecimiento from "../../../assets/images/climind/pray.png";
import avatar from "../../../assets/images/climind/default_avatar.png";
import { HTML5_FMT } from "moment-timezone";
import ModalViewEmployee from "./ModalViewEmployee";
import { Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { he } from "date-fns/locale";

var moment = require("moment");
require("moment/locale/es");
moment.locale("es");

export default function Timeline({
  loading,
  error,
  data,
  refetch,
  networkStatus,
  fetchMore,
}) {
  const [employeeSelected, setEmployeeSelected] = useState("");
  const [modalViewEmployee, setModalViewEmployee] = useState(false);
  const [hoverNameEmotion, setHoverNameEmotion] = useState(false);
  const [hoverNameRecognition, setHoverNameRecognition] = useState(false);
  const [hoverNameGratitude, setHoverNameGratitude] = useState(false);
  const [hoverNameCongratulation, setHoverNameCongratulation] = useState(false);
  const [hoverNameQuestion, setHoverNameQuestion] = useState(false);
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  // if (networkStatus === 4) return "Refetching!";
  // if (loading) return "Esperando";
  if (error) return `Error! ${error}`;

  if (data) {
    const { edges, pageInfo } = data.getTimeline;

    const handleLoadMore = () => {
      fetchMore({
        variables: { after: pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.getTimeline.edges;
          const { pageInfo: newPageInfo } = fetchMoreResult.getTimeline;
          return newEdges.length
            ? {
                getTimeline: {
                  __typename: previousResult.getTimeline.__typename,
                  edges: [...previousResult.getTimeline.edges, ...newEdges],
                  pageInfo: newPageInfo,
                },
              }
            : previousResult;
        },
      });
    };

    const imgStyle = {
      width: 40,
      height: 40,
    };
    const iconStyle = {
      width: isMobile ? 22 : 25,
      height: isMobile ? 22 : 25,
    };

    return (
      <>
        <Card style={{ borderRadius: "10px" }}>
          <div style={{ paddingBottom: "20px" }}>
            <div className="card-tr-actions">
              <Tooltip
                classes={{
                  tooltip: "text-center p-3 tooltip-secondary",
                }}
                arrow
                placement="bottom"
                title="En el timeline podrás ver los comentarios que de forma pública se están enviando en tu empresa en tiempo real"
              >
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="text-first font-weight-bold"
                >
                  <div
                    className=" avatar-icon rounded-circle mr-2"
                    style={iconStyle}
                  >
                    <img
                      alt="..."
                      src={require("../../../assets/images/climind/info.png")}
                    />
                  </div>
                </a>
              </Tooltip>
            </div>
            <div className="card-header">
              <div
                className="card-header--title"
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "21.78px",
                  color: "#291C33",
                }}
              >
                Timeline
              </div>
            </div>
          </div>
          <PerfectScrollbar
            className="scroll-area-xl mb-2"
            style={{
              scrollbarColor: "#e9e4fe",
              maxHeight: "657px",
              overflow: "auto",
            }}
          >
            <div className="table-responsive-md">
              <InfiniteScroll
                dataLength={edges.length} //This is important field to render the next data
                next={handleLoadMore}
                hasMore={data.getTimeline.pageInfo.hasNextPage}
                loader={
                  <div style={{ textAlign: "center" }}>
                    <h4>Cargando más elementos...</h4>
                  </div>
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Los has visto todos</b>
                  </p>
                }
                scrollableTarget="scrollable-div"
              >
                <Table className="table table-borderless table-hover mb-0">
                  <tbody>
                    {edges && edges.length > 0 ? (
                      edges.map((element, index) => {
                        let hour = moment(element.node.date).add(-1, "hour");
                        if (element.node.type === "EMOTION") {
                          return (
                            <Fragment key={index}>
                              <tr>
                                <td colSpan="5" className="">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-left mb-3">
                                      <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                        <div className="avatar-icon rounded-circle">
                                          {element.node.userId.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.node.userId.urlImage}
                                            />
                                          ) : (
                                            <img alt="..." src={avatar} />
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <span
                                          className="bg-white font-weight-bold"
                                          style={{
                                            fontFamily: "Inter",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                            lineHeight: "19.36px",
                                            color: "#291C33",
                                          }}
                                        >
                                          {element.node.userId.name}&nbsp;
                                          {element.node.userId.surname}
                                        </span>
                                        <span
                                          className=" d-block"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            color: "#d4cafc",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["far", "calendar-alt"]}
                                            className="opacity-7 mr-1"
                                          />
                                          {moment(hour)
                                            .startOf("minute")
                                            .fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      alt="..."
                                      className="card-img-top rounded-sm mb-2 mr-2"
                                      src={emotion}
                                      style={imgStyle}
                                    />
                                  </div>
                                  <div className="align-items-left">
                                    <h6
                                      className="font-weight-bold mb-3"
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        lineHeight: "19.36px",
                                        color: "#291C33",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {`Ha expresado su emoción`}
                                      {`Propone que se puede mejorar`}
                                    </h6>
                                    <h4
                                      className=" mb-3"
                                      style={{
                                        fontFamily: "Inter",
                                        fontSize: "18px",
                                        lineHeight: "21.78px",
                                        color: "#7f7785",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {`${element.node.commentary}`}
                                    </h4>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="divider" />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        }
                        if (
                          element.node.type === "RECOGNITION" &&
                          element.node.commentaryInTimeline === true
                        ) {
                          let names = "";
                          let groups = "";
                          let departments = "";
                          let salto = "\n";
                          if (
                            element.node.receivers &&
                            element.node.receivers.length > 0
                          ) {
                            element.node.receivers.map((element2) => {
                              names = names + element2.name;
                              names = names + " ";
                              names = names + `${element2.surname}`;
                              names = names + ", ";
                            });
                          }
                          if (
                            element.node.groups &&
                            element.node.groups.length > 0
                          ) {
                            element.node.groups.map((element2) => {
                              groups = groups + element2.name;
                              groups = groups + ", ";
                            });
                          }
                          if (
                            element.node.departments &&
                            element.node.departments.length > 0
                          ) {
                            element.node.departments.map((element2) => {
                              departments = departments + element2.name;
                              departments = departments + ", ";
                            });
                          }

                          return (
                            <Fragment key={index}>
                              <tr>
                                <td colSpan="5" className="">
                                  {/* <div className="mb-3"> */}
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-left mb-3">
                                      <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                        <div className="avatar-icon rounded-circle">
                                          {element.node.userId.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.node.userId.urlImage}
                                            />
                                          ) : (
                                            <img alt="..." src={avatar} />
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <span
                                          className="bg-white font-weight-bold"
                                          style={{
                                            fontFamily: "Inter",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                            lineHeight: "19.36px",
                                            color: "#291C33",
                                          }}
                                          // {{hover ? {color:"blue"} : null}}
                                          // style={hoverNameCongratulation ? { color: "black", background: "white", outline: "none" } : { background: "white", outline: "none" }}
                                          // onClick={() => {
                                          //   setEmployeeSelected(element.node);
                                          //   toggleViewEmployee();
                                          // }}
                                          // className="font-weight-bold"
                                          // onMouseEnter={() => setHoverNameCongratulation(true)}
                                          // onMouseLeave={() => setHoverNameCongratulation(false)}
                                        >
                                          {element.node.userId.name}&nbsp;
                                          {element.node.userId.surname}
                                        </span>
                                        <span
                                          className="d-block"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            color: "#d4cafc",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["far", "calendar-alt"]}
                                            className="opacity-7 mr-1"
                                          />
                                          {moment(hour)
                                            .startOf("minute")
                                            .fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      alt="..."
                                      className="card-img-top rounded-sm mb-2 mr-2"
                                      src={recognition}
                                      style={imgStyle}
                                    />
                                  </div>
                                  <div className="align-items-left">
                                    <h6 className=" mb-3 font-weight-bold">
                                      {element.node.receiverId &&
                                        ` Ha entregado el reconocimiento ${
                                          element.node.competenceId.name
                                        } a ${element.node.receiver} ${
                                          element.node.receiverId
                                            ? element.node.receiverId.surname
                                            : ""
                                        }`}
                                      <div className="">
                                        <p style={{ whiteSpace: "normal" }}>
                                          {element.node.receivers &&
                                            element.node.receivers.length >
                                              0 && (
                                              <p>
                                                Ha entregado el reconocimiento{" "}
                                                {element.node.competenceId.name}{" "}
                                                a{" "}
                                              </p>
                                            )}
                                          {element.node.receivers &&
                                            element.node.receivers.length >
                                              0 && (
                                              <Tooltip
                                                classes={{
                                                  tooltip:
                                                    "text-center p-3 tooltip-secondary",
                                                }}
                                                arrow
                                                placement="bottom"
                                                title={<span>{names}</span>}
                                              >
                                                <a
                                                  href="#/"
                                                  cj
                                                  onClick={(e) =>
                                                    e.preventDefault()
                                                  }
                                                  className="text-first font-weight-bold"
                                                >
                                                  <div
                                                  // className=" avatar-icon rounded-circle mr-2"
                                                  // style={{ width: 26, height: 26 }}
                                                  >
                                                    {
                                                      element.node.receivers
                                                        .length
                                                    }{" "}
                                                    personas
                                                  </div>
                                                </a>
                                              </Tooltip>
                                            )}
                                        </p>
                                        {(element.node.groups?.length > 0 ||
                                          element.node.departments?.length >
                                            0) && (
                                          <p>
                                            Ha entregado el reconocimiento{" "}
                                            {element.node.competenceId.name} a{" "}
                                          </p>
                                        )}

                                        {element.node.groups &&
                                          element.node.groups.length > 0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{groups}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.groups.length
                                                  }{" "}
                                                  grupos
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                        {element.node.departments &&
                                          element.node.departments.length >
                                            0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{departments}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.departments
                                                      .length
                                                  }{" "}
                                                  departamentos
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                      </div>
                                    </h6>
                                    <h4 className=" mb-3">
                                      {`${element.node.commentary}`}
                                    </h4>
                                    {/* <div className="d-flex flex-row align-items-center">
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-up"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="blue"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">3</h4>
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-down"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="brown"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">5</h4>
                                    </div> */}
                                  </div>

                                  {/* </div> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="divider" />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        }

                        if (element.node.type === "QUESTION") {
                          return (
                            <Fragment key={index}>
                              <tr>
                                <td colSpan="5" className="">
                                  {/* <div className="mb-3"> */}
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-left mb-3">
                                      <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                        <div className="avatar-icon rounded-circle">
                                          {element.node.userId.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.node.userId.urlImage}
                                            />
                                          ) : (
                                            <img alt="..." src={avatar} />
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <span
                                          className="bg-white font-weight-bold"
                                          style={{
                                            fontFamily: "Inter",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                            lineHeight: "19.36px",
                                            color: "#291C33",
                                          }}
                                          // {{hover ? {color:"blue"} : null}}
                                          // style={hoverNameQuestion ? { color: "black", background: "white", outline: "none" } : { background: "white", outline: "none" }}
                                          // onClick={() => {
                                          //   setEmployeeSelected(element.node);
                                          //   toggleViewEmployee();
                                          // }}
                                          // className="font-weight-bold"
                                          // onMouseEnter={() => setHoverNameQuestion(true)}
                                          // onMouseLeave={() => setHoverNameQuestion(false)}
                                        >
                                          {element.node.userId.name}&nbsp;
                                          {element.node.userId.surname}
                                        </span>
                                        <span
                                          className="d-block"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            color: "#d4cafc",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["far", "calendar-alt"]}
                                            className="opacity-7 mr-1"
                                          />
                                          {moment(hour)
                                            .startOf("minute")
                                            .fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      alt="..."
                                      className="card-img-top rounded-sm mb-2 mr-2"
                                      src={pregunta}
                                      style={imgStyle}
                                    />
                                  </div>
                                  <div className="align-items-left">
                                    <h6 className="font-weight-bold mb-3">
                                      {`Ha comentado`}
                                    </h6>
                                    <h4 className=" mb-3">
                                      {/* {` Ha entregado el reconocimiento ${element.node.competenceId.name} a ${element.node.receiver.name}`} */}
                                      {`${element.node.commentary}`}
                                    </h4>
                                    {/* <div className="d-flex flex-row align-items-center">
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-up"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="blue"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">3</h4>
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-down"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="brown"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">5</h4>
                                    </div> */}
                                  </div>

                                  {/* </div> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="divider" />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        }
                        if (element.node.type === "CONGRATULATION") {
                          let names = "";
                          let groups = "";
                          let departments = "";
                          let salto = "\n";
                          if (
                            element.node.receivers &&
                            element.node.receivers.length > 0
                          ) {
                            element.node.receivers.map((element2) => {
                              names = names + element2.name;
                              names = names + " ";
                              names = names + `${element2.surname}`;
                              names = names + ", ";
                            });
                          }
                          if (
                            element.node.groups &&
                            element.node.groups.length > 0
                          ) {
                            element.node.groups.map((element2) => {
                              groups = groups + element2.name;
                              groups = groups + ", ";
                            });
                          }
                          if (
                            element.node.departments &&
                            element.node.departments.length > 0
                          ) {
                            element.node.departments.map((element2) => {
                              departments = departments + element2.name;
                              departments = departments + ", ";
                            });
                          }

                          return (
                            <Fragment key={index}>
                              <tr>
                                <td colSpan="5" className="">
                                  {/* <div className="mb-3"> */}
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-left mb-3">
                                      <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                        <div className="avatar-icon rounded-circle">
                                          {element.node.userId.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.node.userId.urlImage}
                                            />
                                          ) : (
                                            <img alt="..." src={avatar} />
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <span
                                          className="bg-white font-weight-bold"
                                          style={{
                                            fontFamily: "Inter",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                            lineHeight: "19.36px",
                                            color: "#291C33",
                                          }}
                                          // {{hover ? {color:"blue"} : null}}
                                          // style={hoverNameCongratulation ? { color: "black", background: "white", outline: "none" } : { background: "white", outline: "none" }}
                                          // onClick={() => {
                                          //   setEmployeeSelected(element.node);
                                          //   toggleViewEmployee();
                                          // }}
                                          // className="font-weight-bold"
                                          // onMouseEnter={() => setHoverNameCongratulation(true)}
                                          // onMouseLeave={() => setHoverNameCongratulation(false)}
                                        >
                                          {element.node.userId.name}&nbsp;
                                          {element.node.userId.surname}
                                        </span>
                                        <span
                                          className="d-block"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            color: "#d4cafc",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["far", "calendar-alt"]}
                                            className="opacity-7 mr-1"
                                          />
                                          {moment(hour)
                                            .startOf("minute")
                                            .fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      alt="..."
                                      className="card-img-top rounded-sm mb-2 mr-2"
                                      src={felicitacion}
                                      style={imgStyle}
                                    />
                                  </div>
                                  <div className="align-items-left">
                                    <h6 className=" mb-3 font-weight-bold">
                                      {element.node.receiverId &&
                                        ` Ha felicitado a ${
                                          element.node.receiver
                                        } ${
                                          element.node.receiverId
                                            ? element.node.receiverId.surname
                                            : ""
                                        }`}
                                      <div className="d-flex flex-row">
                                        {element.node.receivers &&
                                          element.node.receivers.length > 0 && (
                                            <p>Ha felicitado a</p>
                                          )}
                                        {element.node.receivers &&
                                          element.node.receivers.length > 0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{names}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.receivers
                                                      .length
                                                  }{" "}
                                                  personas
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                        {(element.node.groups?.length > 0 ||
                                          element.node.departments?.length >
                                            0) && <p>Ha felicitado a</p>}
                                        {element.node.groups &&
                                          element.node.groups.length > 0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{groups}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.groups.length
                                                  }{" "}
                                                  grupos
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                        {element.node.departments &&
                                          element.node.departments.length >
                                            0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{departments}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.departments
                                                      .length
                                                  }{" "}
                                                  departamentos
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                      </div>
                                    </h6>
                                    <h4 className=" mb-3">
                                      {`${element.node.commentary}`}
                                    </h4>
                                    {/* <div className="d-flex flex-row align-items-center">
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-up"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="blue"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">3</h4>
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-down"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="brown"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">5</h4>
                                    </div> */}
                                  </div>

                                  {/* </div> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="divider" />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        }
                        if (element.node.type === "GRATITUDE") {
                          let names = "";
                          let groups = "";
                          let departments = "";
                          let salto = "\n";
                          if (
                            element.node.receivers &&
                            element.node.receivers.length > 0
                          ) {
                            element.node.receivers.map((element2) => {
                              names = names + element2.name;
                              names = names + " ";
                              names = names + `${element2.surname}`;
                              names = names + ", ";
                            });
                          }
                          if (
                            element.node.groups &&
                            element.node.groups.length > 0
                          ) {
                            element.node.groups.map((element2) => {
                              groups = groups + element2.name;
                              groups = groups + ", ";
                            });
                          }
                          if (
                            element.node.departments &&
                            element.node.departments.length > 0
                          ) {
                            element.node.departments.map((element2) => {
                              departments = departments + element2.name;
                              departments = departments + ", ";
                            });
                          }

                          return (
                            <Fragment key={index}>
                              <tr>
                                <td colSpan="5" className="">
                                  {/* <div className="mb-3"> */}
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-left mb-3">
                                      <div className="avatar-icon-wrapper avatar-icon-lg mr-3">
                                        <div className="avatar-icon rounded-circle">
                                          {element.node.userId.urlImage ? (
                                            <img
                                              alt="..."
                                              src={element.node.userId.urlImage}
                                            />
                                          ) : (
                                            <img alt="..." src={avatar} />
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <span
                                          className="bg-white font-weight-bold"
                                          style={{
                                            fontFamily: "Inter",
                                            fontWeight: "700",
                                            fontSize: "16px",
                                            lineHeight: "19.36px",
                                            color: "#291C33",
                                          }}
                                          // {{hover ? {color:"blue"} : null}}
                                          // style={hoverNameGratitude ? { color: "black", background: "white", outline: "none" } : { background: "white", outline: "none" }}
                                          // onClick={() => {
                                          //   setEmployeeSelected(element.node);
                                          //   toggleViewEmployee();
                                          // }}
                                          // className="font-weight-bold"
                                          // onMouseEnter={() => setHoverNameGratitude(true)}
                                          // onMouseLeave={() => setHoverNameGratitude(false)}
                                        >
                                          {element.node.userId.name}&nbsp;
                                          {element.node.userId.surname}
                                        </span>
                                        <span
                                          className="d-block"
                                          style={{
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            lineHeight: "17px",
                                            color: "#d4cafc",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["far", "calendar-alt"]}
                                            className="opacity-7 mr-1"
                                          />
                                          {moment(hour)
                                            .startOf("minute")
                                            .fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                    <img
                                      alt="..."
                                      className="card-img-top rounded-sm mb-2 mr-2"
                                      src={agradecimiento}
                                      style={imgStyle}
                                    />
                                  </div>
                                  <div className="align-items-left">
                                    <h6 className=" mb-3 font-weight-bold">
                                      {element.node.receiverId &&
                                        ` Ha agradecido a ${
                                          element.node.receiver
                                        } ${
                                          element.node.receiverId
                                            ? element.node.receiverId.surname
                                            : ""
                                        }`}
                                      <div className="d-flex flex-row">
                                        {element.node.receivers &&
                                          element.node.receivers.length > 0 && (
                                            <p>Ha agradecido a</p>
                                          )}
                                        {element.node.receivers &&
                                          element.node.receivers.length > 0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{names}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.receivers
                                                      .length
                                                  }{" "}
                                                  personas
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                        {(element.node.groups?.length > 0 ||
                                          element.node.departments?.length >
                                            0) && <p>Ha agradecido a</p>}
                                        {element.node.groups &&
                                          element.node.groups.length > 0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{groups}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.groups.length
                                                  }{" "}
                                                  grupos
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                        {element.node.departments &&
                                          element.node.departments.length >
                                            0 && (
                                            <Tooltip
                                              classes={{
                                                tooltip:
                                                  "text-center p-3 tooltip-secondary",
                                              }}
                                              arrow
                                              placement="bottom"
                                              title={<span>{departments}</span>}
                                            >
                                              <a
                                                href="#/"
                                                onClick={(e) =>
                                                  e.preventDefault()
                                                }
                                                className="text-first font-weight-bold"
                                              >
                                                <div
                                                // className=" avatar-icon rounded-circle mr-2"
                                                // style={{ width: 26, height: 26 }}
                                                >
                                                  &nbsp;
                                                  {
                                                    element.node.departments
                                                      .length
                                                  }{" "}
                                                  departamentos
                                                </div>
                                              </a>
                                            </Tooltip>
                                          )}
                                      </div>
                                    </h6>
                                    <h4 className=" mb-3">
                                      {`${element.node.commentary}`}
                                    </h4>
                                    {/* <div className="d-flex flex-row align-items-center">
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-up"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="blue"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">3</h4>
                                    <Card className="card-transparent hover-scale-xl">
                                    <a
                                      href="#/"
                                      onClick={(e) => e.preventDefault()}
                                      className="text-first font-weight-bold">
                                      
                                      <FontAwesomeIcon
                                        icon={["far", "thumbs-down"]}
                                        className="font-size-xl mr-2 card-box-hover"
                                        color="brown"
                                      />
                                    </a>
                                    </Card>
                                    <h4 className="font-size-lg mr-3 mb-0">5</h4>
                                    </div> */}
                                  </div>

                                  {/* </div> */}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="divider" />
                                </td>
                              </tr>
                            </Fragment>
                          );
                        }
                      })
                    ) : (
                      <Fragment>
                        <h4 className="mt-10 text-center">
                          Aún o hay elementos para mostrar
                        </h4>
                      </Fragment>
                    )}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </div>
          </PerfectScrollbar>
        </Card>
        <ModalViewEmployee
          openViewEmployee={modalViewEmployee}
          toggleViewEmployee={toggleViewEmployee}
          dataEmployee={employeeSelected}
        />
      </>
    );
  }
}
