import React, { useState, useCallback, useEffect, useContext } from "react";

import {
  Grid,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { CHANGE_STATE } from "data/mutations";
import CountUp from "react-countup";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";
import {
  GET_USER_BY_UID,
  GETS_FOR_GENERAL,
  GET_IMPROVEMENT_PROPOSALS_RECEIVED_BY_USER_ID_2,
} from "data/queries";
import avatar1 from "../../../assets/images/avatars/avatar1.jpg";
import avatar2 from "../../../assets/images/avatars/avatar2.jpg";
import avatar3 from "../../../assets/images/avatars/avatar3.jpg";
import avatar4 from "../../../assets/images/avatars/avatar4.jpg";
import avatar5 from "../../../assets/images/avatars/avatar5.jpg";

import logo1 from "../../../assets/images/stock-logos/discord-icon.svg";
import logo2 from "../../../assets/images/stock-logos/google-icon.svg";
import logo3 from "../../../assets/images/stock-logos/spotify-icon.svg";
import logo4 from "../../../assets/images/stock-logos/slack-icon.svg";
import logo5 from "../../../assets/images/stock-logos/pinterest-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var moment = require("moment-timezone");
export default function ReceivedProposals() {
  const [dataSelected, setDataSelected] = useState(null);
  const [proposalSelected, setProposalSelected] = useState(null);
  const [modalViewEmployee, setModalViewEmployee] = useState(false);
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee);

  const [modalAccept, setModalAccept] = useState(false);
  const toggleAccept = () => setModalAccept(!modalAccept);
  const [modalMoreInfo, setModalMoreInfo] = useState(false);
  const toggleMoreInfo = () => setModalMoreInfo(!modalMoreInfo);
  const [modalReject, setModalReject] = useState(false);
  const toggleReject = () => setModalReject(!modalReject);

  const isMobile = useMediaQuery("(max-width:600px)");

  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [
    changeState,
    { data: dataChange, error: errorChange, loading: loadingChange },
  ] = useMutation(CHANGE_STATE);

  const limit = 10; // Cantidad de usuarios por página
  const [offset, setOffset] = React.useState(0); // Offset para la paginación
  const { loading, error, data, fetchMore } = useQuery(
    GET_IMPROVEMENT_PROPOSALS_RECEIVED_BY_USER_ID_2,
    {
      variables: {
        id: dbuser.getUserByUid.id,
        limit: 10, // Especifica el límite de usuarios por página
        offset: offset, // Especifica el desplazamiento inicial
      },
    }
  );

  const handlePageChange = (_, newPage) => {
    // Calcular el nuevo offset al cambiar de página
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    console.log(newOffset);
  };

  if (data) {
    return (
      <>
        <Dialog
          open={modalAccept}
          onClose={toggleAccept}
          classes={{ paper: "shadow-lg rounded" }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              ¿Quieres aceptar la propuesta de mejora?
            </h4>
            <h5 className="mb-0 font-size-lg">
              No podrás deshacer esta operación.
            </h5>
            <div className="pt-4">
              <Button
                onClick={toggleAccept}
                className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={async () => {
                  let uid = dbuser.getUserByUid.uid;
                  let id = dbuser.getUserByUid.id;
                  let response = await changeState({
                    variables: {
                      id: proposalSelected,
                      input: { state: "ACCEPTED" },
                    },
                    refetchQueries: [
                      { query: GET_USER_BY_UID, variables: { uid, uid } },
                      { query: GETS_FOR_GENERAL, variables: { id, id } },
                      {
                        query: GET_IMPROVEMENT_PROPOSALS_RECEIVED_BY_USER_ID_2,
                        variables: { id: id, limit: 10, offset: 0 },
                      },
                    ],
                  });
                  console.log(response);
                  setProposalSelected(null);
                  toggleAccept();
                }}
                className="btn-success btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Continuar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={modalMoreInfo}
          onClose={toggleMoreInfo}
          classes={{ paper: "shadow-lg rounded" }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-primary text-primary m-0 d-130">
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              ¿Quieres pedir más información sobre la propuesta de mejora?
            </h4>
            <h5 className="mb-0 font-size-lg">
              No podrás deshacer esta operación.
            </h5>
            <div className="pt-4">
              <Button
                onClick={toggleMoreInfo}
                className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={async () => {
                  let uid = dbuser.getUserByUid.uid;
                  let id = dbuser.getUserByUid.id;
                  let response = await changeState({
                    variables: {
                      id: proposalSelected,
                      input: { state: "MOREINFO" },
                    },
                    refetchQueries: [
                      { query: GET_USER_BY_UID, variables: { uid, uid } },
                      { query: GETS_FOR_GENERAL, variables: { id, id } },
                      {
                        query: GET_IMPROVEMENT_PROPOSALS_RECEIVED_BY_USER_ID_2,
                        variables: { id: id, limit: 10, offset: 0 },
                      },
                    ],
                  });
                  setProposalSelected(null);
                  toggleMoreInfo();
                }}
                className="btn-primary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Continuar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={modalReject}
          onClose={toggleReject}
          classes={{ paper: "shadow-lg rounded" }}
        >
          <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  className="d-flex align-self-center display-3"
                />
              </div>
            </div>
            <h4 className="font-weight-bold mt-4">
              ¿Quieres rechazar la propuesta de mejora?
            </h4>
            <h5 className="mb-0 font-size-lg">
              No podrás deshacer esta operación.
            </h5>
            <div className="pt-4">
              <Button
                onClick={toggleReject}
                className="btn-neutral-secondary btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Cancelar</span>
              </Button>
              <Button
                onClick={async () => {
                  let uid = dbuser.getUserByUid.uid;
                  let id = dbuser.getUserByUid.id;
                  let response = await changeState({
                    variables: {
                      id: proposalSelected,
                      input: { state: "REJECTED" },
                    },
                    refetchQueries: [
                      { query: GET_USER_BY_UID, variables: { uid, uid } },
                      { query: GETS_FOR_GENERAL, variables: { id, id } },
                      {
                        query: GET_IMPROVEMENT_PROPOSALS_RECEIVED_BY_USER_ID_2,
                        variables: { id: id, limit: 10, offset: 0 },
                      },
                    ],
                  });
                  setProposalSelected(null);
                  toggleReject();
                }}
                className="btn-danger btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Continuar</span>
              </Button>
            </div>
          </div>
        </Dialog>
        <Dialog
          open={modalViewEmployee}
          onClose={toggleViewEmployee}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <Grid item>
            <Card className="card-box p-4">
              <div className="d-flex align-items-center mb-3 mt-1">
                <div className="avatar-icon-wrapper rounded-circle mr-3">
                  <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                    <div className="rounded-circle overflow-hidden">
                      <img
                        alt="..."
                        className="img-fluid"
                        src={
                          dataSelected && dataSelected.sender.urlImage
                            ? dataSelected.sender.urlImage
                            : require("../../../assets/images/climind/default_avatar.png")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold"
                    title="..."
                    style={{
                      fontFamily: "Inter",
                      color: "#291c33",
                      fontSize: isMobile ? "14px" : "",
                    }}
                  >
                    {dataSelected ? dataSelected.sender.name : null}&nbsp;
                    {dataSelected ? dataSelected.sender.surname : null}
                    {/* {dataSelected.getRecognitionsSendedByUserIdLast30.datareceiver.name} */}
                  </a>
                  <span className=" d-block">
                    {dataSelected ? dataSelected.sender.email : null}
                  </span>
                </div>
              </div>
              <h5 className="text-center mt-5 mb-3">
                {dataSelected ? dataSelected.sender.name : null} Quiere que{" "}
                {dataSelected
                  ? dataSelected.actionCheck === "INCLUDE"
                    ? "INCLUYAS"
                    : dataSelected.actionCheck === "EXCLUDE"
                    ? "EXCLUYAS"
                    : dataSelected.actionCheck === "IMPROVE"
                    ? "MEJORES"
                    : null
                  : null}{" "}
                este comportamiento
              </h5>
              <h5 className="text-center mt-5 mb-3">
                Has recibido el siguiente comentario
              </h5>
              <h4 className=" mb-20 text-center">
                {dataSelected && dataSelected.commentary
                  ? dataSelected.commentary
                  : null}
              </h4>
              <h5 className=" text-center mt-5 mb-3">
                Has recibido la siguiente competencia
              </h5>
              <h3 className=" mb-0 text-center">
                {dataSelected &&
                dataSelected.competenceId &&
                dataSelected.competenceId.name
                  ? dataSelected.competenceId.name
                  : null}
              </h3>
              <div className="text-center">
                <img
                  src={
                    dataSelected && dataSelected.competenceId.urlImage
                      ? dataSelected.competenceId.urlImage
                      : require("../../../assets/images/climind/default_avatar.png")
                  }
                  className="card-img-top rounded "
                  alt="..."
                  style={{ width: 100 }}
                />
              </div>
              <h4 className=" text-center mt-5 mb-3">
                {dataSelected && dataSelected.competenceId.description
                  ? dataSelected.competenceId.description
                  : null}
              </h4>
              <Button
                fullWidth
                className="btn-neutral-success text-uppercase"
                onClick={() => {
                  toggleViewEmployee();
                }}
              >
                <span className="font-weight-bold">Ok</span>
              </Button>
            </Card>
          </Grid>
        </Dialog>
        <Grid container spacing={6}>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header bg-light">
                <div className="card-header--title">
                  <big className="d-block text-uppercase mt-1 font-weight-bold">
                    Propuestas de mejora recibidas
                  </big>
                </div>
              </div>
              <List component="div" className="list-group-flush">
                {data.getImprovementProposalsReceivedByUserId2
                  .improvementProposalsObtained.length != 0 ? (
                  data.getImprovementProposalsReceivedByUserId2.improvementProposalsObtained.map(
                    (element, index) => {
                      console.log(element);
                      return (
                        <ListItem className="py-3">
                          <Grid container spacing={0}>
                            <div className="flex-column flex-grow-1 text-right d-md-none d-md-none">
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: isMobile ? "14px" : "16px",
                                }}
                              >
                                {moment(element.date).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            <Grid
                              item
                              xl={4}
                              md={12}
                              className="d-flex align-items-center"
                            >
                              <Button
                                size="small"
                                className="btn-neutral-success mr-50"
                                onClick={() => {
                                  setDataSelected(element);
                                  toggleViewEmployee();
                                  console.log(element);
                                }}
                              >
                                Ver
                              </Button>
                              <div className="d-flex align-items-center">
                                <div className="avatar-icon-wrapper mr-2">
                                  <div className="avatar-icon">
                                    <img
                                      alt="..."
                                      src={
                                        element.sender.urlImage
                                          ? element.sender.urlImage
                                          : require("../../../assets/images/climind/default_avatar.png")
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <a
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="font-weight-bold"
                                    style={{
                                      fontSize: isMobile ? "14px" : "16px",
                                    }}
                                    title="..."
                                  >
                                    {element.sender.name
                                      ? element.sender.name
                                      : "sin nombre"}{" "}
                                    {element.sender.surname
                                      ? element.sender.surname
                                      : "sin nombre"}
                                  </a>
                                  <span className="text-black-50 d-none d-md-block">
                                    {element.sender.email
                                      ? element.sender.email
                                      : "sin email"}
                                  </span>
                                </div>
                              </div>
                            </Grid>

                            <Grid
                              item
                              xl={8}
                              md={12}
                              className="pt-3 pt-xl-0 d-flex align-items-center"
                            >
                              <div
                                className="text-center d-none d-md-block"
                                style={{ transform: "translateX(40px)" }}
                              >
                                <p className="mb-0">
                                  {moment(element.date).format("DD/MM/YYYY")}
                                </p>
                              </div>
                              <div
                                className="align-box-row flex-grow-1"
                                style={{
                                  transform: isMobile
                                    ? "translateX(-28px)"
                                    : "",
                                  justifyContent: "end",
                                }}
                              >
                                {!element.state && (
                                  <>
                                    <Button
                                      size="small"
                                      className="btn-neutral-success ml-4"
                                      style={{
                                        fontSize: isMobile ? "13px" : "14px",
                                      }}
                                      onClick={() => {
                                        setProposalSelected(element.id);
                                        toggleAccept();
                                      }}
                                    >
                                      Aceptar
                                    </Button>
                                    <Button
                                      size="small"
                                      className="btn-neutral-primary ml-4"
                                      style={{
                                        fontSize: isMobile ? "13px" : "14px",
                                      }}
                                      onClick={() => {
                                        setProposalSelected(element.id);
                                        toggleMoreInfo();
                                      }}
                                    >
                                      {isMobile
                                        ? "+info"
                                        : "Quiero más información"}
                                    </Button>
                                    <Button
                                      size="small"
                                      className="btn-neutral-danger ml-4"
                                      style={{
                                        fontSize: isMobile ? "13px" : "14px",
                                      }}
                                      onClick={() => {
                                        setProposalSelected(element.id);
                                        toggleReject();
                                      }}
                                    >
                                      {isMobile
                                        ? "Rechazar"
                                        : " No lo comparto"}
                                    </Button>
                                  </>
                                )}
                                {element.state === "ACCEPTED" && (
                                  <div
                                    className="badge badge-info"
                                    style={{
                                      transform: isMobile
                                        ? "translateX(28px)"
                                        : "",
                                    }}
                                  >
                                    Aceptada
                                  </div>
                                )}
                                {element.state === "MOREINFO" && (
                                  <div
                                    className="badge badge-warning"
                                    style={{
                                      transform: isMobile
                                        ? "translateX(28px)"
                                        : "",
                                    }}
                                  >
                                    Mas información solicitada
                                  </div>
                                )}
                                {element.state === "REJECTED" && (
                                  <div
                                    className="badge badge-danger"
                                    style={{
                                      transform: isMobile
                                        ? "translateX(28px)"
                                        : "",
                                    }}
                                  >
                                    Rechazada
                                  </div>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    }
                  )
                ) : (
                  <h5 className="my-5 ml-4">
                    Aún no has recibido propuestas de mejora
                  </h5>
                )}
              </List>
              <div className="divider" />
              <div className="divider" />
              <div className="p-3 d-flex justify-content-center">
                {/* <Pagination className="pagination-primary" count={10} onClick={handleLoadMore}/> */}
                <Pagination
                  count={Math.ceil(
                    data.getImprovementProposalsReceivedByUserId2
                      .totalImprovementProposals / limit
                  )}
                  page={offset / limit + 1}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      component="a"
                      // href="#"
                      onClick={(e) => e.preventDefault()}
                      {...item}
                    />
                  )}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
