import React, { useEffect, useState, useContext } from "react";
import { PageTitle } from "layout-components";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  Container,
  InputAdornment,
  ButtonGroup,
  Card,
  Button,
  List,
  ListItem,
  TextField,
} from "@material-ui/core";

import Main from "./Main";
import Bought from "./Bought";
// import SentCongratulations from "./components/SentCongratulations"
import { UserContext } from "../../context/UserContext";
import { GETS_FOR_GENERAL } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import Gamification from "./Gamification";
import GiveAwards from "./GiveAwards";

export default function Shop() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [inputBg, setInputBg] = useState(false);
  const toggleInputBg = () => setInputBg(!inputBg);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTab2, setActiveTab2] = useState("1");

  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };

  const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
    variables: { id: dbuser.getUserByUid.id },
    skip: !dbuser.getUserByUid.id,
    notifyOnNetworkStatusChange: true,
  });

  if (data) {
    return (
      <>
        <PageTitle
          titleHeading="Centro de Bienestar"
          titleDescription="Canjea tus puntos por premios que ofrece tu empresa."
        />
        <div className="app-inner-content-layout">
          <div className="app-inner-content-layout--main bg-white p-0">
            <div className="hero-wrapper rounded-bottom shadow-xxl bg-composed-wrapper bg-second">
              <div
                className="flex-grow-1 w-100 d-flex align-items-center"
                style={{ background: "#937af8" }}
              >
                <div className="bg-composed-wrapper--bg rounded-bottom " />
                <div className="bg-composed-wrapper--content px-3">
                  <Container className="pt-4" style={{ width: "80%" }}>
                    <div className="d-block d-md-flex align-items-start"></div>

                    <List className="d-flex flex-md-nowrap flex-wrap nav-tabs justify-content-center nav-tabs-warning tabs-animated tabs-animated-shadow my-5">
                      <ListItem
                        button
                        selected={activeTab2 === "1"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("1");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1 text-center">
                          Premios disponibles
                        </span>
                        <div className="divider" />
                      </ListItem>

                      <ListItem
                        button
                        selected={activeTab2 === "2"}
                        className="bg-white-10 mx-3 rounded-lg"
                        onClick={() => {
                          toggle2("2");
                        }}
                      >
                        <span className="font-size-lg text-white px-2 py-1 text-center">
                          Premios canjeados
                        </span>
                        <div className="divider" />
                      </ListItem>

                      {dbuser.getUserByUid.role === "admin" && (
                        <ListItem
                          button
                          selected={activeTab2 === "3"}
                          className="bg-white-10 mx-3 rounded-lg"
                          onClick={() => {
                            toggle2("3");
                          }}
                        >
                          <span className="font-size-lg text-white px-2 py-1 text-center">
                            Métricas de la gamificación
                          </span>
                          <div className="divider" />
                        </ListItem>
                      )}
                      {dbuser.getUserByUid.role === "admin" && (
                        <ListItem
                          button
                          selected={activeTab2 === "4"}
                          className="bg-white-10 mx-3 rounded-lg"
                          onClick={() => {
                            toggle2("4");
                          }}
                        >
                          <span className="font-size-lg text-white px-2 py-1 text-center">
                            Entregas de premios
                          </span>
                          <div className="divider" />
                        </ListItem>
                      )}
                    </List>
                  </Container>
                </div>
              </div>
            </div>
            <Container className="z-over py-5">
              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "1",
                })}
                index={1}
              >
                <Main />
              </div>

              <div
                className={clsx("tab-item-wrapper overflow-visible d-none", {
                  "d-block active": activeTab2 === "2",
                })}
                index={2}
              >
                <Bought />
              </div>
              {dbuser.getUserByUid.role === "admin" && (
                <div
                  className={clsx("tab-item-wrapper overflow-visible d-none", {
                    "d-block active": activeTab2 === "3",
                  })}
                  index={3}
                >
                  <Gamification />
                </div>
              )}
              {dbuser.getUserByUid.role === "admin" && (
                <div
                  className={clsx("tab-item-wrapper overflow-visible d-none", {
                    "d-block active": activeTab2 === "4",
                  })}
                  index={4}
                >
                  <GiveAwards />
                </div>
              )}
            </Container>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}
