import React from "react"
import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from "apollo-link-error";
import { setContext } from 'apollo-link-context';
import "./firebase/firebase";
import firebase from 'firebase'
import history from './history';
import { v4 } from "uuid"

var user = firebase.auth().currentUser;
// console.log(user)
var node_env = process.env.NODE_ENV || 'development';


if (node_env === "development") {
  console.log("-------------------------------------")
  console.log("|          DEVELOPMENT ENV          |")
  console.log("-------------------------------------")
} if (node_env === "production") {
  console.log("--------------------------------------")
  console.log("|           PRODUCTION ENV           |")
  console.log("--------------------------------------")
}

let uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URI
});

const error = onError(({ graphQLErrors, networkError }) => {
  console.log('graphQLErrors', graphQLErrors);
  console.log('networkLErrors', networkError);
  console.log("dentrooooooooooo")
  if (node_env === "development") {
    const errId = v4()
    console.log(errId)

    if (graphQLErrors && graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
      console.log('UNAUTHENTICATED')
      window.location.reload()
      history.push("/login");
      return null

    } else if (graphQLErrors && graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      console.log('INTERNAL_SERVER_ERROR')
      console.log(graphQLErrors[0].message)
      history.push("/PageError500");
      return null

    } else if (graphQLErrors && graphQLErrors[0].extensions.code === 'MY_ERROR_CODE') {
      console.log('NORMAL_ERROR')
      console.log(graphQLErrors[0].message)
      return null

    }
    else {

      history.push("/PageError404");
      return null
    }
  }
  if (node_env === "production") {
    const errId = v4()
    console.log(errId)

    if (graphQLErrors && graphQLErrors[0].extensions.code === 'UNAUTHENTICATED') {
      console.log('UNAUTHENTICATED')
      //enviar al logger el error
      window.location.reload()
      history.push("/login");
      return null

    } else if (graphQLErrors && graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR') {
      console.log('INTERNAL_SERVER_ERROR')
      console.log(errId)
      //enviar al logger el error
      history.push("/PageError500");
      return null

    } else {
      console.log(errId)
      //enviar al logger el error
      history.push("/PageError404");
      return null
    }
  }
});

const cache = new InMemoryCache({
  addTypename: false
});

const authLink = setContext(async (_, { headers }) => {

  // const user = firebase.auth().currentUser;
  const token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `${token}` : "",
      }
    }
  }
});

export const client = new ApolloClient({
  link: error.concat(authLink).concat(uploadLink), //si concatenamos primero uploadlink no funciona
  cache
});


