import React, { useState } from "react";

import {
  Grid,
  Card,
  List,
  ListItem,
  Button,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";

import GaugeChart from "react-gauge-chart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DashboardEnvironment2({
  KpiClimaGlobal,
  KpiIndividualSubsytem,
  KpiInterpersonalSubsytem,
  KpiOrganizativeSubsytem,
}) {
  const [value, setValue] = useState(2);

  const kpiClimaGlobal_tmp =
    (KpiIndividualSubsytem +
      KpiInterpersonalSubsytem +
      KpiOrganizativeSubsytem) /
    3;

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <Grid container>
          <Grid
            item
            xl={4}
            md={4}
            className="d-flex z-over align-items-center justify-content-center"
          >
            <div className="text-center w-100 pt-4">
              <h2 className="display-4 mt-1 mb-1 font-weight-bold">
                Índice Clima
              </h2>
              <div className="card-tr-actions">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="Índice de Clima. 
                  Indicador general de Clima (valor comprendido entre 1 y 10, siendo 10 el mejor nivel de clima laboral). Media de las percepciones individuales dinámicas sobre tres ámbitos: individual, interpersonal, y organizacional."
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold"
                  >
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={{ width: isMobile ? 22 : 26, height: "auto" }}
                    >
                      <img
                        alt="..."
                        src={require("../../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
              <div className="py-2 mt-2">
                <GaugeChart
                  hideText
                  id="chartsGauges2A"
                  nrOfLevels={6}
                  colors={["#f83245", "#f4772e", "#1bc943"]}
                  arcWidth={0.3}
                  percent={kpiClimaGlobal_tmp / 10}
                  textColor="#000000"
                />
              </div>
              <div className="d-60 rounded-sm border-0 mb-4 mx-auto card-icon-wrapper bg-success text-white btn-icon text-center shadow-success mt-2">
                <h3 className="mb-0">{kpiClimaGlobal_tmp.toFixed(2)}</h3>
              </div>
            </div>
          </Grid>
          <Grid item xl={8} md={8}>
            <div className="hero-wrapper bg-composed-wrapper h-100 rounded br-xl-left-0">
              <div className="flex-grow-1 w-100 d-flex align-items-end">
                <div className="bg-composed-wrapper--content align-self-center p-4 p-xl-5">
                  <Grid container>
                    {/* <Grid item md={4} style={{ border: "1px solid #FF3146", borderRadius: "5px", padding: "2px"}}> */}
                    <Grid item md={4}>
                      <div className="card-tr-actions">
                        <Tooltip
                          classes={{
                            tooltip: "text-center p-3 tooltip-secondary",
                          }}
                          arrow
                          placement="top"
                          title="Indice de clima individual
                  Valoración de los usuarios sobre los atributos individuales que condicionan el clima."
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="text-first font-weight-bold"
                          >
                            <div
                              className=" avatar-icon rounded-circle mr-2"
                              style={{
                                transform: "translateY(-20px)",
                                width: isMobile ? 22 : 26,
                                height: "auto",
                              }}
                            >
                              <img
                                alt="..."
                                src={require("../../../../../../assets/images/climind/info.png")}
                              />
                            </div>
                          </a>
                        </Tooltip>
                      </div>
                      <div className="p-2 text-center">
                        <h3
                          style={{ fontSize: "16px" }}
                          className="text-second mt-2"
                        >
                          Subsistema Individual
                        </h3>
                      </div>
                      <div className="rounded-bottom overflow-hidden">
                        <GaugeChart
                          hideText
                          id="chartsGauges1B"
                          nrOfLevels={6}
                          colors={["#f83245", "#f4772e", "#1bc943"]}
                          arcWidth={0.3}
                          percent={KpiIndividualSubsytem / 10}
                          textColor="#000000"
                        />
                        <p className=" font-size-xl text-center">
                          {KpiIndividualSubsytem
                            ? KpiIndividualSubsytem.toFixed(2)
                            : 0}
                        </p>
                      </div>
                      {/* </Card> */}
                    </Grid>
                    {/* <Grid item md={4} style={{ border: "1px solid #E6D600", borderRadius: "5px", padding: "2px"}}> */}
                    <Grid item md={4}>
                      <div className="card-tr-actions">
                        <Tooltip
                          classes={{
                            tooltip: "text-center p-3 tooltip-secondary",
                          }}
                          arrow
                          placement="top"
                          title="Indice de clima interpersonal
                  Valoración de los usuarios sobre los atributos relacionales que condicionan el clima."
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="text-first font-weight-bold"
                          >
                            <div
                              className=" avatar-icon rounded-circle mr-2"
                              style={{
                                transform: "translateY(-20px)",
                                width: isMobile ? 22 : 26,
                                height: "auto",
                              }}
                            >
                              <img
                                alt="..."
                                src={require("../../../../../../assets/images/climind/info.png")}
                              />
                            </div>
                          </a>
                        </Tooltip>
                      </div>
                      <div className="p-2 text-center">
                        <h3
                          style={{ fontSize: "16px" }}
                          className="text-second mt-2"
                        >
                          Subsistema Interpersonal
                        </h3>
                      </div>
                      <div className="rounded-bottom overflow-hidden">
                        <GaugeChart
                          hideText
                          id="chartsGauges2B"
                          nrOfLevels={6}
                          colors={["#f83245", "#f4772e", "#1bc943"]}
                          arcWidth={0.3}
                          percent={KpiInterpersonalSubsytem / 10}
                          textColor="#000000"
                        />
                        <p className=" font-size-xl text-center">
                          {KpiInterpersonalSubsytem
                            ? KpiInterpersonalSubsytem.toFixed(2)
                            : 0}
                        </p>
                      </div>
                    </Grid>
                    {/* <Grid item md={4} style={{ border: "1px solid #00029E", borderRadius: "5px", padding: "2px" }}> */}
                    <Grid item md={4}>
                      <div className="card-tr-actions">
                        <Tooltip
                          classes={{
                            tooltip: "text-center p-3 tooltip-secondary",
                          }}
                          arrow
                          placement="top"
                          title="Indice de clima organizacional
                  Valoración de los usuarios sobre los atributos organizacionales que condicionan el clima."
                        >
                          <a
                            href="#/"
                            onClick={(e) => e.preventDefault()}
                            className="text-first font-weight-bold"
                          >
                            <div
                              className=" avatar-icon rounded-circle mr-2"
                              style={{
                                transform: "translateY(-20px)",
                                width: isMobile ? 22 : 26,
                                height: "auto",
                              }}
                            >
                              <img
                                alt="..."
                                src={require("../../../../../../assets/images/climind/info.png")}
                              />
                            </div>
                          </a>
                        </Tooltip>
                      </div>
                      <div className="p-2 text-center">
                        <h3
                          style={{ fontSize: "16px" }}
                          className=" text-second mt-2"
                        >
                          Subsistema Organizacional
                        </h3>
                      </div>
                      <div className="rounded-bottom overflow-hidden">
                        <GaugeChart
                          hideText
                          id="chartsGauges3B"
                          nrOfLevels={6}
                          colors={["#f83245", "#f4772e", "#1bc943"]}
                          arcWidth={0.3}
                          percent={KpiOrganizativeSubsytem / 10}
                          textColor="#000000"
                        />
                        <p className=" font-size-xl text-center">
                          {KpiOrganizativeSubsytem
                            ? KpiOrganizativeSubsytem.toFixed(2)
                            : 0}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
