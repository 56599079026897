import React, { useState, useCallback, useContext } from "react";

import clsx from "clsx";
import {
  GET_USERS_BY_COMPANY_ID,
  GET_USERS_BY_COMPANY_ID_WITHOUT_ME,
  GETS_FOR_GENERAL,
  GET_USER_CIE,
} from "data/queries";
import { PageTitle } from "layout-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../../context/UserContext";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { LinearProgress, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import avatar from "../../../assets/images/climind/default_avatar.png";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default function RowTableRanking({ element, index }) {
  // const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  // const { loading, error, data, networkStatus } = useQuery(GETS_FOR_GENERAL, {
  //   variables: { id: element.id },
  //   skip: !element.id,
  //   notifyOnNetworkStatusChange: false,
  // });
  // const { loading, error, data, networkStatus } = useQuery(GET_USER_CIE, {
  //   variables: { id: element.id },
  //   skip: !element.id,
  //   notifyOnNetworkStatusChange: true,
  // });
  const [searchOpen, setSearchOpen] = useState(false);
  const [filterTable, setFilterTable] = useState(null);
  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  if (true) {
    // let cie = (data.getAnswerEmotionsSendedByUserIdLast30.length <= 20 ? data.getAnswerEmotionsSendedByUserIdLast30.length : 20)/20*100 * (30/100) +
    // (data.getAnswersSendedByUserIdLast30.length <= 20 ? data.getAnswersSendedByUserIdLast30.length : 20)/20*100 * (30/100)+
    // (data.getRecognitionsSendedByUserIdLast30.length <= 20 ? data.getRecognitionsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
    // (data.getImprovementProposalsSendedByUserIdLast30.length <= 20 ? data.getImprovementProposalsSendedByUserIdLast30.length : 20)/20*100 * (15/100)+
    // (data.getCongratulationsSendedByUserIdLast30.length <= 20 ? data.getCongratulationsSendedByUserIdLast30.length : 20)/20*100 * (5/100)+
    // (data.getGratitudesSendedByUserIdLast30.length <= 20 ? data.getGratitudesSendedByUserIdLast30.length : 20)/20*100 * (5/100)

    return (
      <>
        {isMobile ? (
          <tr>
            <td className="text-center">
              <span style={{ marginLeft: "-10px" }}>{index + 1}</span>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <div>
                  <Link
                    to={{
                      pathname: `/user/${element.id}`,
                    }}
                  >
                    <h6 style={{ fontSize: "14px", marginLeft: "-20px" }}>
                      {element.name}&nbsp;{element.surname}
                    </h6>
                  </Link>
                </div>
              </div>
            </td>

            <td className="text-center">
              <h6 style={{ fontSize: "12px" }}>{element.points}</h6>
            </td>
          </tr>
        ) : (
          <tr>
            <td className="text-center">
              <span>{index + 1}</span>
            </td>
            <td className="px-4">
              <div className="d-flex align-items-center">
                <div className="avatar-icon-wrapper avatar-icon-lg mr-3 d-none d-md-block">
                  <div className="avatar-icon rounded-circle">
                    {element.urlImage ? (
                      <img alt="..." src={element.urlImage} />
                    ) : (
                      <img alt="..." src={avatar} />
                    )}
                  </div>
                </div>
                <div>
                  <Link
                    to={{
                      pathname: `/user/${element.id}`,
                    }}
                  >
                    <h6 style={{ fontSize: "16px" }}>
                      {element.name}&nbsp;{element.surname}
                    </h6>
                  </Link>

                  <h4
                    className="d-none d-md-block"
                    style={{ fontSize: "14px" }}
                  >
                    {element.username ? (
                      <CheckCircleIcon color="secondary" />
                    ) : null}{" "}
                    {element.email}
                  </h4>
                </div>
              </div>
            </td>

            <td className="text-center">
              <h6>{element.points}</h6>
            </td>
            <td className="text-center">
              <h6>{element.score}</h6>
            </td>
            <td className="text-center">
              <h6>{Math.floor(element.score / 100)}</h6>
            </td>
            <td className="text-center">
              <div>
                <LinearProgress
                  variant="determinate"
                  value={element.cie}
                  className="progress-bar-rounded progress-constrained mx-auto"
                  style={{ width: "100%" }}
                />
                <div className="font-weight-bold mt-2">
                  {element.cie.toFixed(2)}%
                </div>
              </div>
            </td>
            <td className="text-center">
              <div>
                <LinearProgress
                  variant="determinate"
                  value={element.cieHistory}
                  className="progress-bar-rounded progress-constrained mx-auto"
                  style={{ width: "100%" }}
                />
                <div className="font-weight-bold mt-2">
                  {element.cieHistory != null
                    ? element.cieHistory.toFixed(2)
                    : 0}
                  %
                </div>
              </div>
            </td>
          </tr>
        )}
        <tr className="divider"></tr>
      </>
    );
  } else {
    return null;
  }
}
