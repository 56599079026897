import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Grid,
  LinearProgress,
  Card,
  Tooltip,
  Table,
  Button,
  Switch,
  useMediaQuery,
} from "@material-ui/core";

import Chart from "react-apexcharts";
import CountUp from "react-countup";

import "../../../../../assets/climind.scss";

export default function DashboardPulse5({
  TotalActivePulseUsers,
  dataMatrix,
  dataEngagementMatrix,
  countedAspectNegative,
  countedAspectPositive,
  countedAspectNeutro,
  dataNegative,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [state, setState] = useState({
    checkedA: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  let dataScatter = [];
  dataEngagementMatrix.map((el) => {
    let tmp = {
      x: 0,
      y: 0,
    };
    if (el.promHappy > 0) {
      tmp.x = el.promHappy.toFixed(2) * -1;
      tmp.y = (el.percentHappy * 10).toFixed(2);
      dataScatter.push(tmp);
    }
  });
  let dataScatterwNeutro = [];
  dataEngagementMatrix.map((el) => {
    let tmp = {
      x: 0,
      y: 0,
    };
    if (el.promHappy >= 0) {
      tmp.x = el.promHappy.toFixed(2) * -1;
      tmp.y = (el.percentHappy * 10).toFixed(2);
      dataScatterwNeutro.push(tmp);
    }
  });
  let dataTableEngagement = [
    {
      label: "Características de mis tareas",
      description:
        "Tarea que realizas: cómo está diseñada, el sentido que tiene para ti, las habilidades que te requiere, la autonomía que te permite, y lo que te aporta.",
      type: "typeContenidoTrabajo",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Trabajo con personas",
      description:
        "Trabajo en equipo y cómo colaboráis en el logro de objetivos entre todas las personas.",
      type: "typeTrabajoGrupo",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Nivel de exigencia",
      description:
        "Nivel de exigencia física y mental que te requiere tu trabajo.",
      type: "typeTiempo",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Esfuerzo y resultados",
      type: "typeParticipacion",
      description:
        "Valoración que hacen tus superiores de tu trabajo (esfuerzo realizado y resultados obtenidos).",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Sensación de Bienestar",
      description:
        "Cómo te afectan las condiciones laborales en tu sensación de bienestar.",
      type: "typeRol",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
  ];
  let dataTableEngagementwNeutro = [
    {
      label: "Características de mis tareas",
      description:
        "Tarea que realizas: cómo está diseñada, el sentido que tiene para ti, las habilidades que te requiere, la autonomía que te permite, y lo que te aporta.",
      type: "typeContenidoTrabajo",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Trabajo con personas",
      description:
        "Trabajo en equipo y cómo colaboráis en el logro de objetivos entre todas las personas.",
      type: "typeTrabajoGrupo",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Nivel de exigencia",
      description:
        "Nivel de exigencia física y mental que te requiere tu trabajo.",
      type: "typeTiempo",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Esfuerzo y resultados",
      type: "typeParticipacion",
      description:
        "Valoración que hacen tus superiores de tu trabajo (esfuerzo realizado y resultados obtenidos).",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
    {
      label: "Sensación de Bienestar",
      description:
        "Cómo te afectan las condiciones laborales en tu sensación de bienestar.",
      type: "typeRol",
      value: 0,
      responses: 0,
      users: 0,
      percent: 0,
    },
  ];
  let totalResponses = 0;
  dataTableEngagement.map((el) => {
    countedAspectPositive.map((n) => {
      if (n[el.type] > 0) {
        totalResponses += n.frecuencyHappy;
      }
    });
  });
  let totalResponseswNeutro = 0;
  dataTableEngagementwNeutro.map((el) => {
    countedAspectNeutro.map((n) => {
      if (n[el.type] > 0) {
        totalResponseswNeutro += n.frecuencyNeutro;
      }
    });
  });
  // console.log(countedAspectPositive);
  totalResponseswNeutro += totalResponses;

  let usersTotal = [];

  dataTableEngagement.map((el) => {
    let valueTmp = 0;
    let responsesTmp = 0;
    let users = [];
    countedAspectPositive.map((n) => {
      if (n[el.type] > 0) {
        if (users.includes(n.userId)) {
          valueTmp += n.valueHappy;
          responsesTmp += n.frecuencyHappy;
          // totalResponses += n.frecuencyHappy
        } else {
          users.push(n.userId);
          valueTmp += n.valueHappy;
          responsesTmp += n.frecuencyHappy;
          // totalResponses += n.frecuencyHappy
        }
        if (!usersTotal.includes(n.userId)) {
          usersTotal.push(n.userId);
        }
      }
    });
    el.value = responsesTmp > 0 ? valueTmp / responsesTmp : 0;
    el.responses = responsesTmp;
    el.users = users.length;
    el.percent = responsesTmp / totalResponses;
  });

  let usersTotalNeutros = [];

  dataTableEngagementwNeutro.map((el) => {
    let valueTmp = 0;
    let responsesTmp = 0;
    let users = [];
    countedAspectPositive.map((n) => {
      if (n[el.type] > 0) {
        if (users.includes(n.userId)) {
          valueTmp += n.valueHappy;
          responsesTmp += n.frecuencyHappy;
          // totalResponses += n.frecuencyHappy
        } else {
          users.push(n.userId);
          valueTmp += n.valueHappy;
          responsesTmp += n.frecuencyHappy;
          // totalResponses += n.frecuencyHappy
        }
        if (!usersTotalNeutros.includes(n.userId)) {
          usersTotalNeutros.push(n.userId);
        }
      }
    });
    countedAspectNeutro.map((n) => {
      if (n[el.type] > 0) {
        if (users.includes(n.userId)) {
          valueTmp += n.valueNeutro;
          responsesTmp += n.frecuencyNeutro;
          // totalResponses += n.frecuencyHappy
        } else {
          users.push(n.userId);
          valueTmp += n.valueNeutro;
          responsesTmp += n.frecuencyNeutro;
          // totalResponses += n.frecuencyHappy
        }
        if (!usersTotalNeutros.includes(n.userId)) {
          usersTotalNeutros.push(n.userId);
        }
      }
    });
    el.value = responsesTmp > 0 ? valueTmp / responsesTmp : 0;
    el.responses = responsesTmp;
    el.users = users.length;
    el.percent = responsesTmp / totalResponseswNeutro;
  });

  function compareVariables(a, b) {
    if (a.percent > b.percent) {
      return -1;
    }
    if (a.percent < b.percent) {
      return 1;
    }
    return 0;
  }
  dataTableEngagement.sort(compareVariables);
  dataTableEngagementwNeutro.sort(compareVariables);
  const kpiIndicator = (dataPercent) => {
    if (!dataPercent) {
      return <div className="font-size-lg text-center">Sin datos</div>;
    }
    if (dataPercent < 0.15 && dataPercent >= 0) {
      return (
        <div className="font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
      <div className="px-4 py-1 h-auto text-danger border-1 border-danger badge badge-neutral-danger">
        Critico
      </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon avatar-icon-green-medium">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    } else if (dataPercent >= 0.15 && dataPercent < 0.3) {
      return (
        <div className="font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
      <div className="px-4 py-1 h-auto text-warning border-1 border-warning badge badge-neutral-warning">
        Atender
      </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon avatar-icon-green-high text-white">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="font-size-lg text-center">
          {/* <small className="text-black-50 d-block">(>30%)</small>
      <div className="px-4 py-1 h-auto text-success border-1 border-success badge badge-neutral-success">
        Mantener
      </div> */}
          <div className="avatar-icon-wrapper avatar-initials avatar-icon-lg">
            <div className="avatar-icon avatar-icon-green-high text-white">
              {Math.round(dataPercent * 100) + "%"}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header">
          <div className="card-header--title">
            <small className="d-block text-uppercase mt-1">Engagement</small>
          </div>
          <div className="card-tr-actions">
            <div className="d-flex justify-content-center">
              <div className="p-1">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="El switch agregará los datos de emociones neutras a la tabla de Engagement"
                >
                  <Switch
                    onChange={handleChange}
                    checked={state.checkedA}
                    name="checkedA"
                    color="primary"
                    className="switch-small"
                  />
                </Tooltip>
              </div>
              <div className="">
                <Tooltip
                  classes={{
                    tooltip: "text-center p-3 tooltip-secondary",
                  }}
                  arrow
                  placement="top"
                  title="Los factores de satisfacción que aquí se detallan son áreas de mejora dado que los usuarios han puntuado su nivel de satisfacción por debajo de 7"
                >
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="text-first font-weight-bold"
                  >
                    <div
                      className=" avatar-icon rounded-circle mr-2"
                      style={{
                        transform: isMobile
                          ? "translateY(5px)"
                          : "translateY(3px)",
                        width: isMobile ? 22 : 26,
                        height: "auto",
                      }}
                    >
                      <img
                        alt="..."
                        src={require("../../../../../assets/images/climind/info.png")}
                      />
                    </div>
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {!state.checkedA ? (
          <div>
            <div className="px-5 pb-2">
              <div className=" pt-5 pb-1">
                <p className="display-2 font-weight-bold mb-4">
                  <h2
                    style={{ fontSize: isMobile ? "32px" : "46px" }}
                    className="pl-1"
                  >
                    Tabla de Engagement
                  </h2>
                </p>
              </div>
              <h4 style={{ fontSize: "16px" }}>
                Los datos corresponden a {usersTotal.length} usuarios que han
                marcado un factor de satisfacción con intensidad menor o igual a
                6 y mayor a 0 de un total de {TotalActivePulseUsers} usuarios
                activos en pulso.(Es decir un{" "}
                {((usersTotal.length / TotalActivePulseUsers) * 100).toFixed(2)}
                % del total de usuarios activos en pulso)
              </h4>
            </div>
            <div className="table-responsive-md">
              <Table className="table table-alternate-spaced mb-0">
                <thead>
                  <tr>
                    <th
                      // style={{ width: "300px" }}
                      className="font-weight-normal text-dark text-center"
                      style={{ fontSize: isMobile ? "16px" : "22px" }}
                      scope="col"
                    >
                      Factor de satisfacción
                    </th>
                    <th
                      className="font-weight-normal text-dark text-center"
                      style={{ fontSize: isMobile ? "16px" : "22px" }}
                      scope="col"
                    >
                      Num. de veces marcado
                    </th>
                    <th
                      className="font-weight-normal text-dark text-center"
                      style={{ fontSize: isMobile ? "16px" : "22px" }}
                      scope="col"
                    >
                      Num. usuarios
                    </th>
                    <th
                      className="font-weight-normal text-dark text-center"
                      style={{ fontSize: isMobile ? "16px" : "22px" }}
                      scope="col"
                    >
                      Valor positivo promedio
                    </th>
                    <th
                      className="font-weight-normal text-dark text-center"
                      style={{ fontSize: isMobile ? "16px" : "22px" }}
                      scope="col"
                    >
                      Porcentaje de representatividad
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataTableEngagement.map((el, index) => {
                    let percentRep = kpiIndicator(el.percent);
                    return (
                      <>
                        <tr key={index}>
                          <td className="text-center">
                            <span
                              style={{ fontFamily: "Inter", color: "#291c33" }}
                              className="font-weight-bold"
                            >
                              {el.label}
                            </span>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-primary",
                              }}
                              arrow
                              placement="bottom"
                              title={el.description}
                            >
                              <Button
                                variant="text"
                                className="p-0 d-30 border-0 btn-transition-none text-dark"
                                disableRipple
                              >
                                <FontAwesomeIcon
                                  icon={["far", "question-circle"]}
                                  className="font-size-lg"
                                />
                              </Button>
                            </Tooltip>
                          </td>
                          <td>
                            <div className="text-center font-size-lg">
                              {el.responses}
                            </div>
                          </td>
                          <td>
                            <div className="text-center font-size-lg">
                              {el.users}
                            </div>
                          </td>
                          <td>
                            <div className="text-center font-size-lg">
                              {el.value.toFixed(2)}
                            </div>
                          </td>
                          <td>{percentRep}</td>
                        </tr>
                        <tr className="divider"></tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <div>
            <div className="px-5 pb-2">
              <div className="px-5 pt-5 pb-1">
                <p className="display-2 font-weight-bold mb-4">
                  <h2 style={{ fontSize: "46px" }} className="pl-1">
                    Tabla de Engagement con Elementos Neutros
                  </h2>
                </p>
              </div>
              <h4 style={{ fontSize: "16px" }}>
                Los datos corresponden a {usersTotalNeutros.length} usuarios que
                han marcado un factor de satisfacción con intensidad menor o
                igual a 6, incluido 0 de un total de {TotalActivePulseUsers}{" "}
                usuarios activos en pulso.(Es decir un{" "}
                {(
                  (usersTotalNeutros.length / TotalActivePulseUsers) *
                  100
                ).toFixed(2)}
                % del total de usuarios activos en pulso)
              </h4>
            </div>
            <div className="table-responsive-md">
              <Table className="table table-alternate-spaced mb-0">
                <thead>
                  <tr>
                    <th
                      // style={{ width: "300px" }}
                      className="font-size-lg font-weight-normal text-dark text-center"
                      scope="col"
                    >
                      Factor de satisfacción
                    </th>
                    <th
                      className="font-size-lg font-weight-normal text-dark text-center"
                      scope="col"
                    >
                      Num. de veces marcado
                    </th>
                    <th
                      className="font-size-lg font-weight-normal text-dark text-center"
                      scope="col"
                    >
                      Num. usuarios
                    </th>
                    <th
                      className="font-size-lg font-weight-normal text-dark text-center"
                      scope="col"
                    >
                      Valor positivo promedio
                    </th>
                    <th
                      className="font-size-lg font-weight-normal text-dark text-center"
                      scope="col"
                    >
                      Porcentaje de representatividad
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataTableEngagementwNeutro.map((el, index) => {
                    let percentRep = kpiIndicator(el.percent);
                    return (
                      <>
                        <tr key={index}>
                          <td className="text-center">
                            <span
                              style={{ fontFamily: "Inter", color: "#291c33" }}
                              className="font-weight-bold"
                            >
                              {el.label}
                            </span>
                            <Tooltip
                              classes={{
                                tooltip: "text-center p-3 tooltip-primary",
                              }}
                              arrow
                              placement="bottom"
                              title={el.description}
                            >
                              <Button
                                variant="text"
                                className="p-0 d-30 border-0 btn-transition-none text-dark"
                                disableRipple
                              >
                                <FontAwesomeIcon
                                  icon={["far", "question-circle"]}
                                  className="font-size-lg"
                                />
                              </Button>
                            </Tooltip>
                          </td>
                          <td>
                            <div className="text-center font-size-lg">
                              {el.responses}
                            </div>
                          </td>
                          <td>
                            <div className="text-center font-size-lg">
                              {el.users}
                            </div>
                          </td>
                          <td>
                            <div className="text-center font-size-lg">
                              {el.value.toFixed(2)}
                            </div>
                          </td>
                          <td>{percentRep}</td>
                        </tr>
                        <tr className="divider"></tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </Card>
    </>
  );
}
