import React, { useState, useCallback, useEffect, useContext } from "react";

import {
  Grid,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
  CardContent,
  Dialog,
  Table,
  useMediaQuery,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { UserContext } from "../../../context/UserContext";
import { GET_CONGRATULATIONS_RECEIVED_BY_USER_ID } from "data/queries";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import CountUp from "react-countup";

import avatar1 from "../../../assets/images/avatars/avatar1.jpg";
import avatar2 from "../../../assets/images/avatars/avatar2.jpg";
import avatar3 from "../../../assets/images/avatars/avatar3.jpg";
import avatar4 from "../../../assets/images/avatars/avatar4.jpg";
import avatar5 from "../../../assets/images/avatars/avatar5.jpg";

import logo1 from "../../../assets/images/stock-logos/discord-icon.svg";
import logo2 from "../../../assets/images/stock-logos/google-icon.svg";
import logo3 from "../../../assets/images/stock-logos/spotify-icon.svg";
import logo4 from "../../../assets/images/stock-logos/slack-icon.svg";
import logo5 from "../../../assets/images/stock-logos/pinterest-icon.svg";
var moment = require("moment-timezone");
// export default function ReceivedCongratulations({data}) {
export default function ReceivedCongratulations() {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [dataSelected, setDataSelected] = useState(false);
  const [modalViewEmployee, setModalViewEmployee] = useState(false);
  const toggleViewEmployee = () => setModalViewEmployee(!modalViewEmployee);
  const limit = 10; // Cantidad de usuarios por página
  const [offset, setOffset] = React.useState(0); // Offset para la paginación
  const { loading, error, data, fetchMore } = useQuery(
    GET_CONGRATULATIONS_RECEIVED_BY_USER_ID,
    {
      variables: {
        id: dbuser.getUserByUid.id,
        limit: 10, // Especifica el límite de usuarios por página
        offset: offset, // Especifica el desplazamiento inicial
      },
    }
  );
  const isMobile = useMediaQuery("(max-width:600px)");

  const handlePageChange = (_, newPage) => {
    // Calcular el nuevo offset al cambiar de página
    const newOffset = (newPage - 1) * limit;
    setOffset(newOffset);
    console.log(newOffset);
  };

  console.log(data);
  if (data) {
    return (
      <>
        <Dialog
          open={modalViewEmployee}
          onClose={toggleViewEmployee}
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <Grid item>
            <Card className="card-box p-4">
              <div className="d-flex align-items-center mb-3 mt-1">
                <div className="avatar-icon-wrapper rounded-circle mr-3">
                  <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                    <div className="rounded-circle overflow-hidden">
                      <img
                        alt="..."
                        className="img-fluid"
                        src={
                          dataSelected && dataSelected.sender.urlImage
                            ? dataSelected.sender.urlImage
                            : require("../../../assets/images/climind/default_avatar.png")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="font-weight-bold"
                    title="..."
                    style={{
                      fontFamily: "Inter",
                      color: "#291c33",
                      fontSize: isMobile ? "14px" : "",
                    }}
                  >
                    {dataSelected ? dataSelected.sender.name : null}&nbsp;
                    {dataSelected ? dataSelected.sender.surname : null}
                    {/* {dataSelected.getRecognitionsSendedByUserIdLast30.datareceiver.name} */}
                  </a>
                  <h4 style={{ fontSize: "14px" }} className=" d-block">
                    {dataSelected ? dataSelected.sender.email : null}
                  </h4>
                </div>
              </div>
              <h5 className="text-center mt-5 mb-3">
                Has recibido el siguiente comentario
              </h5>
              <h4 className="text-center">
                {dataSelected && dataSelected.commentary
                  ? dataSelected.commentary
                  : null}
              </h4>

              <Button
                fullWidth
                className="btn-neutral-success text-uppercase"
                onClick={() => {
                  toggleViewEmployee();
                }}
              >
                <span className="font-weight-bold">Ok</span>
              </Button>
            </Card>
          </Grid>
        </Dialog>
        <Grid container spacing={6}>
          <Grid item xl={12}>
            <Card className="card-box">
              <div className="card-header bg-light">
                <div className="card-header--title">
                  <big className="d-block text-uppercase mt-1 font-weight-bold">
                    Felicitaciones Recibidas
                  </big>
                </div>
              </div>
              <List component="div" className="list-group-flush">
                {data.getCongratulationsReceivedByUserId.congratulationsObtained
                  .length != 0 ? (
                  data.getCongratulationsReceivedByUserId.congratulationsObtained.map(
                    (element, index) => {
                      console.log(element);
                      return (
                        <ListItem className="py-3">
                          <Grid container spacing={0}>
                            <div className="align-box-row flex-grow-1 text-left d-block d-md-none">
                              <div className="d-flex flex-column flex-grow-1 ">
                                {!element.departmentReceiver &&
                                !element.groupReceiver ? (
                                  <p className="mb-0">individual</p>
                                ) : (
                                  <p className="mb-0">colectivo</p>
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 text-right d-block d-md-none">
                              <p className="mb-0" style={{ fontSize: "14px" }}>
                                {moment(element.date).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            <Grid
                              item
                              xl={6}
                              md={6}
                              sm={12}
                              className="d-flex align-items-center"
                            >
                              <Button
                                size="small"
                                className="btn-neutral-success mr-50"
                                onClick={() => {
                                  setDataSelected(element);
                                  toggleViewEmployee();
                                  // console.log(element.datasender.name)
                                }}
                              >
                                Ver
                              </Button>
                              <div className="d-flex align-items-center">
                                <div className="avatar-icon-wrapper mr-2">
                                  <div className="avatar-icon">
                                    <img
                                      alt="..."
                                      src={
                                        element.sender.urlImage
                                          ? element.sender.urlImage
                                          : require("../../../assets/images/climind/default_avatar.png")
                                      }
                                    />
                                  </div>
                                </div>
                                <div>
                                  <a
                                    href="#/"
                                    onClick={(e) => e.preventDefault()}
                                    className="font-weight-bold text-black"
                                    title="..."
                                    style={{
                                      fontFamily: "Inter",
                                      color: "#291c33",
                                      fontSize: isMobile ? "14px" : "",
                                    }}
                                  >
                                    {element.sender.name
                                      ? element.sender.name
                                      : "sin nombre"}
                                    &nbsp;
                                    {element.sender.surname
                                      ? element.sender.surname
                                      : null}
                                  </a>
                                  <span className="text-black-50 d-none d-md-block">
                                    {element.sender.email
                                      ? element.sender.email
                                      : "sin email"}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={6}
                              md={3}
                              sm={12}
                              className="pt-3 pt-xl-0 d-flex align-items-center"
                            >
                              <div className="flex-column flex-grow-1 text-center d-none d-md-block">
                                <p className="mb-0">
                                  {moment(element.date).format("DD/MM/YYYY")}
                                </p>
                              </div>

                              <div className="align-box-row flex-grow-1 d-none d-md-block">
                                <div className="d-flex flex-column flex-grow-1 text-center">
                                  {!element.departmentReceiver &&
                                  !element.groupReceiver ? (
                                    <p className="mb-0">individual</p>
                                  ) : (
                                    <p className="mb-0">colectivo</p>
                                  )}
                                </div>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={3}
                              md={3}
                              sm={12}
                              className="pt-xl-0 d-flex align-items-center"
                            ></Grid>
                          </Grid>
                        </ListItem>
                      );
                    }
                  )
                ) : (
                  <h5 className="my-5 ml-4">
                    Aún no has recibido felicitaciones
                  </h5>
                )}
              </List>
              <div className="divider" />
              <div className="divider" />
              <div className="p-3 d-flex justify-content-center">
                {/* <Pagination className="pagination-primary" count={10} onClick={handleLoadMore}/> */}
                <Pagination
                  count={Math.ceil(
                    data.getCongratulationsReceivedByUserId
                      .totalCongratulations / limit
                  )}
                  page={offset / limit + 1}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      component="a"
                      // href="#"
                      onClick={(e) => e.preventDefault()}
                      {...item}
                    />
                  )}
                />
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return null;
  }
}
