import React, { useState, useCallback, useContext } from "react";
// import ModalSelectUser from "./ModalSelectUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import defaultAvatar from "../../../assets/images/climind/default_avatar.png";
import {
  Dialog,
  Button,
  Card,
  TextField,
  Menu,
  Snackbar,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Chip,
  Collapse,
  List,
  ListItem,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UserContext } from "../../../context/UserContext";
import { GET_USER_BY_UID, GETS_FOR_GENERAL } from "data/queries";
import { NEW_METRIC } from "data/mutations";

import challenge from "../../../assets/images/climind/target.png";

import MuiAlert from "@material-ui/lab/Alert";
import {
  GET_DEPARTMENTS,
  GET_GROUPS,
  GET_CHALLENGES_BY_COMPANYID,
} from "data/queries";

import SnackBarMessage from "../../../utils/SnackBarMessage";
import firebase from "firebase";

import clsx from "clsx";

var moment = require("moment-timezone");
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function valuetext(value) {
  return <span>{{ value }}°C</span>;
}

export default function ModalKeyResults({
  okrSelected,
  setOkrSelected,
  modalKeyResults,
  toggleKeyResults,
  refetch,
}) {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);
  const [
    newMetric,
    { data: dataOkr, error: errorOkr, loading: loadingOkr },
  ] = useMutation(NEW_METRIC);
  const [objective, setObjective] = useState("");
  const [description, setDescription] = useState("");
  const [keyResults, setKeyResults] = useState("");
  const [accordion1, setAccordion1] = useState(false);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState(false);
  const [initDate, setInitDate] = useState(null);
  const [checkExclude, setCheckExclude] = useState(false);
  const [checkInclude, setCheckInclude] = useState(false);
  const [checkImprove, setCheckImprove] = useState(false);
  const [actionProposal, setActionProposal] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [number, setNumber] = useState(0);
  const [boolean, setBoolean] = useState(true);
  const [date, setDate] = useState(null);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    data: dataDepartments,
    networkStatus: netDepartments,
  } = useQuery(GET_DEPARTMENTS, {
    variables: { id: dbuser.getUserByUid.companyId },
    skip: !dbuser.getUserByUid.companyId,
    notifyOnNetworkStatusChange: true,
  });

  const handleCancel = useCallback((e) => {
    setObjective("");
    setDescription("");
    setKeyResults("");
    toggleKeyResults();
  });

  const handleOkTest = async (e) => {
    // if (!objective || objective === "") {
    //   setSeverity("error");
    //   setMessageAlert("Debes de agregar el campo del nombre");
    //   setOpenSnackbar(true, () => {
    //     setTimeout(function () {
    //       setOpenSnackbar(false);
    //     }, 5000);
    //   });
    // } else if (!description || description === "") {
    //   setSeverity("error");
    //   setMessageAlert("Debes de agregar el campo de la descripción");
    //   setOpenSnackbar(true, () => {
    //     setTimeout(function () {
    //       setOpenSnackbar(false);
    //     }, 5000);
    //   });
    // } else if (!keyResults || keyResults === "") {
    //   setSeverity("error");
    //   setMessageAlert("Debes de agregar el campo del criterio");
    //   setOpenSnackbar(true, () => {
    //     setTimeout(function () {
    //       setOpenSnackbar(false);
    //     }, 5000);
    //   });
    // } else {
    let dataInput = {
      userId: dbuser.getUserByUid.id,
      boolean: boolean,
      number: parseInt(number),
      percentage: parseInt(percentage),
      date: initDate,
    };
    let krnumber = 0;
    if (accordion1) {
      krnumber = 1;
    }
    if (accordion2) {
      krnumber = 2;
    }
    if (accordion3) {
      krnumber = 3;
    }
    let response = await newMetric({
      variables: {
        keyResultNumber: krnumber,
        okrId: okrSelected.id,
        input: dataInput,
      },
    });
    if (response.data) {
      setSeverity("success");
      setMessageAlert("Reto creado correctamente");
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
      setObjective("");
      setDescription("");
      setKeyResults("");
      // toggleKeyResults();
      refetch();
    } else {
      console.log(response);
      setSeverity("error");
      setMessageAlert("Error al crear el reto");
      setOpenSnackbar(true, () => {
        setTimeout(function () {
          setOpenSnackbar(false);
        }, 5000);
      });
      setObjective("");
      setDescription("");
      setKeyResults("");
      toggleKeyResults();
    }
  };
  const handleBack = async (e) => {
    setObjective("");
    setDescription("");
    setKeyResults("");
    toggleKeyResults();
  };

  if (true) {
    return (
      <>
        <SnackBarMessage
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          message={messageAlert}
          severity={severity}
        />
        <Dialog
          open={modalKeyResults}
          onClose={handleBack}
          fullWidth
          maxWidth="lg"
          width="80%"
          classes={{ paper: "modal-content rounded-lg" }}
        >
          <div className="text-center pt-5">
            <div className="avatar-icon-wrapper rounded-circle mb-4">
              <img
                alt="..."
                className="card-img-top rounded-sm"
                src={challenge}
                style={{ width: 70 }}
              />
              {/* </div> */}
            </div>
            <h5 className="text-center">Gestión de objetivos clave</h5>
          </div>
          <div className=" text-center d-flex flex-column justify-content-center align-items-center px-2">
            <h5 className="font-weight-bold mt-2">Este es objetivo del OKR:</h5>
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              {okrSelected.objective}
            </h5>
            <h5 className="font-weight-bold mt-2">
              Esta es la descripción del OKR:
            </h5>
            <h5 className="font-weight-bold mt-2" style={{ color: "#5a318e" }}>
              {okrSelected.description}
            </h5>
            <h5 className="font-weight-bold mt-2">
              Duración del OKR:{" "}
              {moment(okrSelected.initDate).format("DD/MM/YYYY")} -{" "}
              {moment(okrSelected.endDate).format("DD/MM/YYYY")}
            </h5>

            <h5 className="font-weight-bold mt-2">Resultados clave del OKR:</h5>

            <div className="accordion mb-spacing-6-x2" style={{ width: "90%" }}>
              <Card
                className={clsx("card-box", {
                  "panel-open": accordion1,
                })}
              >
                <Card
                  style={{
                    height: "auto",
                    maxHeight: "fit-content",
                    overflow: "hidden",
                  }}
                >
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => {
                            setAccordion1(!accordion1);
                            setAccordion2(false);
                            setAccordion3(false);
                            setBoolean(true);
                            setPercentage(0);
                            setNumber(0);
                            setInitDate(null);
                          }}
                          aria-expanded={accordion1}
                        >
                          <span>Resultado clave 1</span>
                          <FontAwesomeIcon
                            icon={["fas", "angle-up"]}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {okrSelected.keyResultOne.initDate != null && (
                    <Collapse in={accordion1} unmountOnExit>
                      <h5 className="font-weight-bold mt-4">
                        Descripción: {okrSelected.keyResultOne.description}
                      </h5>
                      {okrSelected.keyResultOne.unit === "PERCENTAGE" && (
                        <h5 className="font-weight-bold mt-4">
                          Obejtivo de la métrica:{" "}
                          {okrSelected.keyResultOne.percentage}
                        </h5>
                      )}
                      {okrSelected.keyResultOne.unit === "NUMBER" && (
                        <h5 className="font-weight-bold mt-4">
                          Obejtivo de la métrica:{" "}
                          {okrSelected.keyResultOne.number}
                        </h5>
                      )}

                      {okrSelected.keyResultOne.unit === "PERCENTAGE" && (
                        <>
                          <h5
                            className="font-weight-bold mt-4"
                            style={{ color: "#fe9b71" }}
                          >
                            Registros anteriores de este resultado clave
                          </h5>
                          <div
                            className="scroll-area shadow-overflow"
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            <PerfectScrollbar
                              options={{ wheelPropagation: false }}
                            >
                              <List
                                component="div"
                                className="list-group-flush"
                              >
                                {okrSelected.keyResultOne.metric.map(
                                  (element) => {
                                    return (
                                      <ListItem
                                        className="d-flex justify-content-between align-items-center py-2"
                                        style={{ margin: 0, padding: "5px 0" }}
                                      >
                                        <div className="d-flex align-items-center">
                                          <span className="d-block">
                                            {element.percentage}%
                                          </span>
                                        </div>
                                        <span className="d-block">
                                          {moment(element.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                      {okrSelected.keyResultOne.unit === "NUMBER" && (
                        <>
                          <h5
                            className="font-weight-bold mt-4"
                            style={{ color: "#fe9b71" }}
                          >
                            Registros anteriores de este resultado clave
                          </h5>
                          <div
                            className="scroll-area shadow-overflow"
                            style={{
                              maxHeight: "fit-content",
                              overflowY: "auto",
                            }}
                          >
                            <PerfectScrollbar
                              options={{ wheelPropagation: false }}
                              style={{ maxHeight: "fit-content" }}
                            >
                              <List
                                component="div"
                                className="list-group-flush"
                              >
                                {okrSelected.keyResultOne.metric.map(
                                  (element) => {
                                    return (
                                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                                        <div className="d-flex align-items-center">
                                          <span className="d-block">
                                            {element.number}
                                          </span>
                                        </div>
                                        <span className="d-block">
                                          {moment(element.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                    </Collapse>
                  )}
                </Card>
              </Card>
              <Card
                className={clsx("card-box", {
                  "panel-open": accordion2,
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => {
                            setAccordion2(!accordion2);
                            setAccordion1(false);
                            setAccordion3(false);
                            setBoolean(true);
                            setPercentage(0);
                            setNumber(0);
                            setInitDate(null);
                          }}
                          aria-expanded={accordion2}
                        >
                          <span>Resultado clave 2</span>
                          <FontAwesomeIcon
                            icon={["fas", "angle-up"]}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {okrSelected.keyResultTwo.initDate != null && (
                    <Collapse in={accordion2}>
                      <h5 className="font-weight-bold mt-4">
                        Descripción: {okrSelected.keyResultTwo.description}
                      </h5>
                      {okrSelected.keyResultTwo.unit === "PERCENTAGE" && (
                        <h5 className="font-weight-bold mt-4">
                          Obejtivo de la métrica:{" "}
                          {okrSelected.keyResultTwo.percentage}
                        </h5>
                      )}
                      {okrSelected.keyResultTwo.unit === "NUMBER" && (
                        <h5 className="font-weight-bold mt-4">
                          Obejtivo de la métrica:{" "}
                          {okrSelected.keyResultTwo.number}
                        </h5>
                      )}

                      {okrSelected.keyResultTwo.unit === "PERCENTAGE" && (
                        <>
                          <h5
                            className="font-weight-bold mt-4"
                            style={{ color: "#fe9b71" }}
                          >
                            Registros anteriores de este resultado clave
                          </h5>
                          <div className="scroll-area shadow-overflow">
                            <PerfectScrollbar
                              options={{ wheelPropagation: false }}
                            >
                              <List
                                component="div"
                                className="list-group-flush"
                              >
                                {okrSelected.keyResultTwo.metric.map(
                                  (element) => {
                                    return (
                                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                                        <div className="d-flex align-items-center">
                                          <span className="d-block">
                                            {element.percentage}%
                                          </span>
                                        </div>
                                        <span className="d-block">
                                          {moment(element.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                      {okrSelected.keyResultTwo.unit === "NUMBER" && (
                        <>
                          <h5
                            className="font-weight-bold mt-4"
                            style={{ color: "#fe9b71" }}
                          >
                            Registros anteriores de este resultado clave
                          </h5>
                          <div className="scroll-area shadow-overflow">
                            <PerfectScrollbar
                              options={{ wheelPropagation: false }}
                            >
                              <List
                                component="div"
                                className="list-group-flush"
                              >
                                {okrSelected.keyResultTwo.metric.map(
                                  (element) => {
                                    return (
                                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                                        <div className="d-flex align-items-center">
                                          <span className="d-block">
                                            {element.number}
                                          </span>
                                        </div>
                                        <span className="d-block">
                                          {moment(element.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                    </Collapse>
                  )}
                </Card>
              </Card>
              <Card
                className={clsx("card-box", {
                  "panel-open": accordion3,
                })}
              >
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link font-weight-bold d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={() => {
                            setAccordion3(!accordion3);
                            setAccordion1(false);
                            setAccordion2(false);
                            setBoolean(true);
                            setPercentage(0);
                            setNumber(0);
                            setInitDate(null);
                          }}
                          aria-expanded={accordion3}
                        >
                          <span>Resultado clave 3</span>
                          <FontAwesomeIcon
                            icon={["fas", "angle-up"]}
                            className="font-size-xl accordion-icon"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {okrSelected.keyResultThree.initDate != null && (
                    <Collapse in={accordion3}>
                      <h5 className="font-weight-bold mt-4">
                        Descripción: {okrSelected.keyResultThree.description}
                      </h5>
                      {okrSelected.keyResultThree.unit === "PERCENTAGE" && (
                        <h5 className="font-weight-bold mt-4">
                          Obejtivo de la métrica:{" "}
                          {okrSelected.keyResultThree.percentage}
                        </h5>
                      )}
                      {okrSelected.keyResultThree.unit === "NUMBER" && (
                        <h5 className="font-weight-bold mt-4">
                          Obejtivo de la métrica:{" "}
                          {okrSelected.keyResultThree.number}
                        </h5>
                      )}

                      {okrSelected.keyResultThree.unit === "PERCENTAGE" && (
                        <>
                          <h5
                            className="font-weight-bold mt-4"
                            style={{ color: "#fe9b71" }}
                          >
                            Registros anteriores de este resultado clave
                          </h5>
                          <div className="scroll-area shadow-overflow">
                            <PerfectScrollbar
                              options={{ wheelPropagation: false }}
                            >
                              <List
                                component="div"
                                className="list-group-flush"
                              >
                                {okrSelected.keyResultThree.metric.map(
                                  (element) => {
                                    return (
                                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                                        <div className="d-flex align-items-center">
                                          <span className="d-block">
                                            {element.percentage}%
                                          </span>
                                        </div>
                                        <span className="d-block">
                                          {moment(element.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                      {okrSelected.keyResultThree.unit === "NUMBER" && (
                        <>
                          <h5
                            className="font-weight-bold mt-4"
                            style={{ color: "#fe9b71" }}
                          >
                            Registros anteriores de este resultado clave
                          </h5>
                          <div className="scroll-area shadow-overflow">
                            <PerfectScrollbar
                              options={{ wheelPropagation: false }}
                            >
                              <List
                                component="div"
                                className="list-group-flush"
                              >
                                {okrSelected.keyResultThree.metric.map(
                                  (element) => {
                                    return (
                                      <ListItem className="d-flex justify-content-between align-items-center py-3">
                                        <div className="d-flex align-items-center">
                                          <span className="d-block">
                                            {element.number}
                                          </span>
                                        </div>
                                        <span className="d-block">
                                          {moment(element.date).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </List>
                            </PerfectScrollbar>
                          </div>
                        </>
                      )}
                    </Collapse>
                  )}
                </Card>
              </Card>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center mb-spacing-6-x2">
            <div className="pt-4" style={{ marginBottom: 20 }}>
              <Button
                onClick={handleBack}
                className="btn-neutral-dark btn-pill mx-1"
              >
                <span className="btn-wrapper--label">Atrás</span>
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  } else {
    return null;
  }
}
