import React, { useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Badge, Menu, Button, Tooltip, Divider } from "@material-ui/core";

import avatar2 from "../../assets/images/avatars/avatar2.jpg";
import { NavLink, useHistory } from "react-router-dom";
import { Height } from "@material-ui/icons";
import firebase from "firebase";
import Login from "../../Pages/Login";

import { UserContext } from "../../context/UserContext";
import { HTML5_FMT } from "moment";
import { useApolloClient } from "@apollo/client";
const SidebarUserbox = (props) => {
  const { currentUser, dbuser, restoreUser, getUserWithFirebase } = useContext(
    UserContext
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const apolloClient = useApolloClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(async (event) => {
    await firebase
      .auth()
      .signOut()
      .then(async function () {
        // localStorage.removeItem("token");
        // await setReloadUserContext(true)
        await getUserWithFirebase();
        await restoreUser();
        // history.push("/");
        apolloClient.clearStore();
        history.push("/login");
      })
      .catch(function (error) {
        console.log("no se ha posido cerrar sesión");
      });
  });

  if (dbuser && currentUser) {
    return (
      <>
        <div className="app-sidebar--userbox" style={{ marginTop: "90px" }}>
          <div
            className="avatar-icon-wrapper avatar-icon-md"
            style={{ position: "absolute", top: "-40px" }}
          >
            <div
              className="avatar-icon rounded-circle"
              style={{ width: 70, height: 70 }}
            >
              <img
                alt="..."
                src={
                  dbuser.getUserByUid.urlImage
                    ? dbuser.getUserByUid.urlImage
                    : require("../../assets/images/climind/default_avatar.png")
                }
                // style={{borderRadius:"50%" , border: "solid 6px gold"}}
              />
            </div>
          </div>
          <div className="my-3 userbox-details">
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "19.36px",
                color: "#291c33",
              }}
            >
              {dbuser.getUserByUid.name}
            </span>
            <span className="d-block text-white-50">
              {dbuser.getUserByUid.email}
            </span>
            <NavLink
              to="/"
              title="Climind - Monitorización del clima laboral"
              className="app-sidebar-logo"
            >
              <div className="app-sidebar-logo--text">
                {/* <span>Panel administrador</span>

              <b>Panel administrador</b> */}
                {dbuser.getUserByUid.role === "admin" && (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      lineHeight: "14.52px",
                      color: "#937af8",
                    }}
                  >
                    Panel administrador
                  </span>
                )}
                {dbuser.getUserByUid.role === "superadmin" && (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      lineHeight: "14.52px",
                      color: "#937af8",
                    }}
                  >
                    Panel superadmin
                  </span>
                )}
                {dbuser.getUserByUid.role === "user" && (
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      fontFamily: "Inter",
                      lineHeight: "14.52px",
                      color: "#937af8",
                    }}
                  >
                    Panel de usuario
                  </span>
                )}
              </div>
            </NavLink>
          </div>
          {/* <h2 className="text-center mb-4">
               Tu nivel y puntos en Climind
              </h2> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              component={NavLink}
              to="/Profile"
              size="small"
              style={{
                backgroundColor: "#291c33",
                color: "white",
                borderRadius: "20px",
                height: "40px",
                width: "133px",
              }}
              className="btn-userbox m-1"
            >
              Ver perfil
            </Button>
            <Button
              onClick={handleLogout}
              size="small"
              className="btn-userbox m-1"
              style={{
                color: "#291c33",
                backgroundColor: "transparent",
              }}
            >
              <img
                alt="..."
                src={require("../../assets/images/climind/cerrar-sesion.png")}
                style={{ width: 125, height: 21 }}
              />
            </Button>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <img
                alt="..."
                src={require("../../assets/images/climind/user-collapsed.png")}
                style={{ width: 19.38, height: 20, background: "transparent" }}
                className="btn-userbox-collapsed"
              />

              <img
                alt="..."
                src={require("../../assets/images/climind/cerrar-sesion-collapsed.png")}
                style={{ width: 19.38, height: 20, background: "transparent" }}
                className="btn-userbox-collapsed"
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default SidebarUserbox;
