import firebase from "firebase";

// Initialize Firebase
const config_dev = {
  apiKey: "AIzaSyCL69nrpH4q8lSNrKEK-bdndZgjGfrQ7Og",
  authDomain: "climind-dev-31799.firebaseapp.com",
  projectId: "climind-dev-31799",
  storageBucket: "climind-dev-31799.firebasestorage.app",
  messagingSenderId: "720528694645",
  appId: "1:720528694645:web:b5841b59201d392449873c"
};

const config_prod = {
  apiKey: "AIzaSyA6wRzMnGCKE4ZU6kvUnkKAktZ0Rhc1TRc",
  authDomain: "climind-365db.firebaseapp.com",
  databaseURL: "https://climind-365db.firebaseio.com",
  projectId: "climind-365db",
  storageBucket: "climind-365db.appspot.com",
  messagingSenderId: "136005799707",
  appId: "1:136005799707:web:af8ec8d07629d18b"
};

const config = process.env.REACT_APP_ENV === 'production' ? config_prod : config_dev;

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
