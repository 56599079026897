import React, { useContext } from "react";

import clsx from "clsx";
import { UserContext } from "../../context/UserContext";
import { Button, Tooltip } from "@material-ui/core";

import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "../../reducers/ThemeOptions";

const SidebarHeader = (props) => {
  const { currentUser, dbuser, setReloadUserContext } = useContext(UserContext);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle,
  } = props;

  return (
    <>
      <div className="app-sidebar--header">
        <Tooltip title="Collapse sidebar" placement="right" arrow>
          <Button
            onClick={toggleSidebar}
            className="btn btn-sm collapse-sidebar-btn"
          >
            <img
              alt="..."
              src={require("../../assets/images/climind/collapse-sidebar.png")}
            />
          </Button>
        </Tooltip>
        <Button
          className={clsx(
            "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
            { "is-active": sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip title="Expand sidebar" placement="right" arrow>
          <Button
            onClick={toggleSidebar}
            className="expand-sidebar-btn btn btn-sm"
          >
            <img
              alt="..."
              src={require("../../assets/images/climind/expand-sidebar.png")}
            />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
